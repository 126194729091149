import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllLocations } from "../../../../services/locationApp/locationService";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { getCookie } from "../../../../utils/cookies";
import LocationDetails from "./LocationDetails";

export interface HomeProps {}

/* Get all location information using API call at initial load. */
const Location: React.FunctionComponent<HomeProps> = () => {
  const [locationNodeList, setLocationNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const idToken = getCookie("idToken")
  /* Get all location information using API call and response data set to locationList state. */
  const getLocationInfo = async () => {
    try {
      const res = await fetchAllLocations(idToken);
      setIsLoading(false);
      setLocationNodeList(res.data.data);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getLocationInfo();
  }, []);

  return (
    <>
      <Box>
        <LocationDetails
          isLoading={isLoading}
          locationNodeList={locationNodeList}
          getLocationInfo={getLocationInfo}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default Location;

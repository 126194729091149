import { Badge, IconButton, Typography, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import FilterCard from "./FilterCard/FilterCard";
import _ from "lodash";
import DefaultAlert from "../../alerts/DefaultAlert";
import { useRouteMatch, useLocation, useHistory } from "react-router";
import moment from "moment";
import {
  channelSelectorList,
  defaultModeSelectorList,
  partySizeSelectorList,
  payInOutSelectorList,
  paymentTypeSelectorList,
  platformSelectorList,
  typeSelectorList,
  vatPercentSelectorList,
} from "../../../utils/consts/list";
import {
  getFilterListFromArrayObjectAndArray,
  getLabelListFromArrayObjectAndArray,
} from "../../../utils/commonArrayMap";
import { handleFilterDataDetails } from "../../../utils/FilterDetails";
import { CustomTheme } from "../../../types/customTheme";
import { Icon } from "@iconify/react";
import { handleFilterData } from "../../../utils/FilterData";
import TuneIcon from "@material-ui/icons/Tune";
import {
  convertDateToMoment,
  getEndDateDateMonthYear,
  getStartDateDateMonthYear,
} from "../../../utils/ConvertDateTimeFormat";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    iconButton: {
      "&:hover": {
        background: "none",
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.background.entity_border}`,
      },
      backgroundColor: theme.palette.background.entity_background,
      marginBottom: "8px",
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      border: `2px solid ${theme.palette.background.entity_border}`,
      textAlign: "center",
      width: "120px",
      height: "44px",
    },
    badgeFontColor: {
      "& .MuiBadge-badge": {
        color: "white",
      },
    },
  }),
);

export interface Props {
  getFilterData: any;
  locationSelectorList: any;
  getLocationSelectorTypingList: any;
  isGetLocationInfo: any;
  getUserInfo?: any;
  userList?: any;
  setFilterDetails?: any;
  availableFilter: any;
  getPreviousFilterInfo?: any;
  setCurrentStartDate?: any;
  setCurrentEndDate?: any;
  setPreviousStartDate?: any;
  setPreviousEndDate?: any;
  previousStartDate?: any;
  previousEndDate?: any;
  isChart: any;
  payInOut?: any;
  isUserGet?: any;
  isSale?: any;
  isSaleItem?: any;
  isShift?: any;
  isOrder?: any;
  locationSelectedList: any;
  setLocationSelectedList: any;
  setOpenFilterCard: any;
  openFilterCard: any;
  isDailySale?: any
}

const Filter: React.FunctionComponent<Props> = ({
  getFilterData,
  locationSelectorList,
  getLocationSelectorTypingList,
  isGetLocationInfo,
  getUserInfo,
  userList,
  setFilterDetails,
  availableFilter,
  getPreviousFilterInfo,
  setCurrentStartDate,
  setCurrentEndDate,
  setPreviousStartDate,
  setPreviousEndDate,
  previousStartDate,
  previousEndDate,
  isChart,
  payInOut,
  isUserGet,
  isSale,
  isSaleItem,
  isShift,
  isOrder,
  locationSelectedList,
  setLocationSelectedList,
  setOpenFilterCard,
  openFilterCard,
  isDailySale
}) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [typeFilterList, setTypeFilterList] = useState([]);
  const [channelFilterList, setChannelFilterList] = useState([]);
  const [platformFilterList, setPlatformFilterList] = useState([]);
  const [paymentTypeFilterList, setPaymentTypeFilterList] = useState([]);
  const [orderStatusFilterList, setOrderStatusFilterList] = useState([]);
  const [deliveryTypeFilterList, setDeliveryTypeFilterList] = useState([]);
  const [userInitialList, setUserInitialList] = useState<any>([]);
  const [userFilterList, setUseFilterList] = useState([]);
  const [payInOrOutFilterList, setPayInOrOutFilterList] = useState([]);
  const [alTypeFilterList, setAlTypeFilterList] = useState([]);
  const [partySizeFilterList, setPartySizeFilterList] = useState([]);
  const [vatPercentFilterList, setVatPercentFilterList] = useState([]);
  const [discountValueMin, setDiscountValueMin] = useState("");
  const [totalAmountMin, setTotalAmountMin] = useState("");
  const [totalAmountMax, setTotalAmountMax] = useState("");
  const [filterTotalAmountMin, setFilterTotalAmountMin] = useState("");
  const [filterTotalAmountMax, setFilterTotalAmountMax] = useState("");
  const [discountValueMax, setDiscountValueMax] = useState("");
  const [qtyMin, setQtyMin] = useState("");
  const [qtyMax, setQtyMax] = useState("");
  const [amountMin, setAmountMin] = useState("");
  const [amountMax, setAmountMax] = useState("");
  const [orderTotalMin, setOrderTotalMin] = useState("");
  const [orderTotalMax, setOrderTotalMax] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [queOrderNo, setQueOrderNo] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [filterDiscountValueMin, setFilterDiscountValueMin] = useState("");
  const [filterDiscountValueMax, setFilterDiscountValueMax] = useState("");
  const [filterQtyMin, setFilterQtyMin] = useState("");
  const [filterQtyMax, setFilterQtyMax] = useState("");
  const [filterAmountMin, setFilterAmountMin] = useState("");
  const [filterAmountMax, setFilterAmountMax] = useState("");
  const [filterOrderTotalMin, setFilterOrderTotalMin] = useState("");
  const [filterOrderTotalMax, setFilterOrderTotalMax] = useState("");
  const [filterReceiptNo, setFilterReceiptNo] = useState("");
  const [filterQueOrderNo, setFilterQueOrderNo] = useState("");
  const [filterOrderNo, setFilterOrderNo] = useState("");
  const [error, setError] = useState("");
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [typeSelectedList, setTypeSelectedList] = useState([]);
  const [partySizeSelectedList, setPartySizeSelectedList] = useState([]);
  const [vatPercentSelectedList, setVatPercentSelectedList] = useState([]);
  const [paymentTypeSelectedList, setPaymentTypeSelectedList] = useState([]);
  const [orderStatusSelectedList, setOrderStatusSelectedList] = useState([]);
  const [deliveryTpeSelectedList, setDeliveryTpeSelectedList] = useState([]);
  const [userSelectedList, setUserSelectedList] = useState([]);
  const [payInOutSelectedList, setPayInOutSelectedList] = useState([]);
  const [allTypeSelectedList, setAllTypeSelectedList] = useState([]);
  const [channelSelectedList, setChannelSelectedList] = useState([]);
  const [platformSelectedList, setPlatformChannelSelectedList] = useState([]);
  const [userSelectorList, setUserSelectorList] = useState<any>([]);
  const [isBasedOnShift, setIsBasedOnShift] = useState(true);
  const [isPreviousBasedOnShift, setPreviousIsBasedOnShift] = useState(true);
  const [orderAndDueTimeSwitch, setOrderAndDueTimeSwitch] = useState(true);
  const [isBasedOnShiftButtonDisabled, setIsBasedOnShiftButtonDisabled] =
    useState(false);
  const [searchLocationName, setSearchLocationName] = useState([]);
  const [countOfFilters, setCountOfFilters] = useState(0);
  const [startDate, setStartDate] = useState<any>(
    moment().subtract(30, "d").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState<any>(moment().format("YYYY-MM-DD"));
  const [dateSelectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(moment().subtract(30, "d").format("YYYY-MM-DD")),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [previousDateSelectionRange, setPreviousDateSelectionRange] = useState([
    {
      startDate: new Date(moment().subtract(61, "d").format("YYYY-MM-DD")),
      endDate: new Date(moment().subtract(31, "d").format("YYYY-MM-DD")),
      key: "selection",
    },
  ]);

  const history = useHistory();
  const location = useLocation();

  /* Only if user details related to locations are not empty and only one location is selected, 
    user data will be entered in the user select dropdown. */
  useEffect(() => {
    if (!_.isEmpty(userList)) {
      if (locationFilterList.length === 1) {
        setUserSelectorList(userList);
      }
    } else {
      setUserSelectorList([]);
    }
  }, [userList]);

  /* According to the change in the drop-down, changing the state related to that drop-down. */
  const handleAllSelectedList = (selectorName: any, selectedList: any) => {
    // Create an array by entering the data in the object.
    const selectedListConvertArray = selectedList.map(
      (selectObject: any) => selectObject.id,
    );
    if (selectorName === "location" && !_.isEmpty(selectedList)) {
      setLocationSelectedList(selectedList);
      setLocationFilterList(selectedListConvertArray);

      // Obtaining user data related to locations.
      if (isUserGet && selectedListConvertArray.length === 1) {
        selectedListConvertArray.map((data: any) => {
          getUserInfo(data);
        });
      }

      if (selectedListConvertArray.length !== 1) {
        setUserSelectedList([]);
        setUseFilterList([]);
      }
    } else if (selectorName === "location" && _.isEmpty(selectedList)) {
      // Get current location.
      const filteredCurrentLocation = locationSelectorList.filter(
        (itm: any) => itm.id === match.params.locationId,
      );
      // Get current location id.
      const currentLocationId = filteredCurrentLocation.map(
        (selectObject: any) => selectObject.id,
      );
      setLocationSelectedList(filteredCurrentLocation);
      setLocationFilterList(currentLocationId);
      // Obtaining user data using current locations.
      if (isUserGet && selectedListConvertArray.length === 1) {
        getUserInfo(currentLocationId[0]);
      }
    } else if (selectorName === "mode") {
      setTypeSelectedList(selectedList);
      setTypeFilterList(selectedListConvertArray);
    } else if (selectorName === "platform") {
      setPlatformChannelSelectedList(selectedList);
      setPlatformFilterList(selectedListConvertArray);
      if (!selectedListConvertArray.includes("online")) {
        setChannelSelectedList([]);
        setChannelFilterList([]);
      }
    } else if (selectorName === "channel") {
      setChannelSelectedList(selectedList);
      setChannelFilterList(selectedListConvertArray);
    } else if (selectorName === "partySize") {
      setPartySizeSelectedList(selectedList);
      setPartySizeFilterList(selectedListConvertArray);
    } else if (selectorName === "vatPercent") {
      selectedListConvertArray.map((data: any) => {
        if (data === "5") {
          selectedListConvertArray.push("5000");
        }
        if (data === "12.5") {
          selectedListConvertArray.push("12500");
        }
        if (data === "20") {
          selectedListConvertArray.push("20000");
        }
      });
      setVatPercentSelectedList(selectedList);
      setVatPercentFilterList(selectedListConvertArray);
    } else if (selectorName === "paymentType") {
      setPaymentTypeSelectedList(selectedList);
      setPaymentTypeFilterList(selectedListConvertArray);
    } else if (selectorName === "user") {
      setUserSelectedList(selectedList);
      setUseFilterList(selectedListConvertArray);
      setUserInitialList([]);
    } else if (selectorName === "payInOut") {
      setPayInOutSelectedList(selectedList);
      setPayInOrOutFilterList(selectedListConvertArray);
    } else if (selectorName === "type") {
      setAllTypeSelectedList(selectedList);
      setAlTypeFilterList(selectedListConvertArray);
    } else if (selectorName === "orderStatus") {
      setOrderStatusSelectedList(selectedList);
      setOrderStatusFilterList(selectedListConvertArray);
    } else if (selectorName === "deliveryType") {
      setDeliveryTpeSelectedList(selectedList);
      setDeliveryTypeFilterList(selectedListConvertArray);
    }
  };

  /* This function is worked only for charts. Then the current date and the previous date are retrieved. */
  const handelChartDateRange = (range: any) => {
    setCurrentStartDate(convertDateToMoment(range.selection.startDate));
    setCurrentEndDate(convertDateToMoment(range.selection.endDate));

    /* This will work if the selected start date and end date are the same as this month. */
    if (
      convertDateToMoment(range.selection.startDate) ===
        getStartDateDateMonthYear(new Date(), "month") &&
      convertDateToMoment(range.selection.endDate) ===
        getEndDateDateMonthYear(new Date(), "month")
    ) {
      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment(range.selection.endDate)
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
          ),
        },
      });
    } else if (
      convertDateToMoment(range.selection.startDate) ===
        getStartDateDateMonthYear(
          new Date(
            moment(range.selection.startDate)
              .startOf("month")
              .format("YYYY-MM-DD"),
          ),
          "month",
        ) &&
      convertDateToMoment(range.selection.endDate) ===
        getEndDateDateMonthYear(
          new Date(
            moment(range.selection.startDate)
              .startOf("month")
              .format("YYYY-MM-DD"),
          ),
          "month",
        )
    ) {
      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment(range.selection.endDate)
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
          ),
        },
      });
    } else if (
      /* This will work if the selected start date and end date are the same as this year. */
      convertDateToMoment(range.selection.startDate) ===
        getStartDateDateMonthYear(new Date(), "year") &&
      convertDateToMoment(range.selection.endDate) ===
        getEndDateDateMonthYear(new Date(), "year")
    ) {
      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment().subtract(1, "y").startOf("year").format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment().subtract(1, "y").endOf("year").format("YYYY-MM-DD"),
          ),
        },
      });
    } else if (
      /* This works when the current start date and current end date are equal and the previous start date and 
      previous end date are unequal. Here, The previous date is changed according to the current date. */
      convertDateToMoment(range.selection.startDate) ===
        convertDateToMoment(range.selection.endDate) &&
      convertDateToMoment(previousStartDate) !==
        convertDateToMoment(previousEndDate)
    ) {
      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "d")
              .format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "d")
              .format("YYYY-MM-DD"),
          ),
        },
      });
    } else {
      /* Changing the previous start date and end date according to the difference between the current start date and end date. */
      const diff: number =
        moment(range.selection.endDate).diff(
          moment(range.selection.startDate),
          "days",
        ) + 1;

      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment(range.selection.startDate)
              .subtract(diff, "d")
              .format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "d")
              .format("YYYY-MM-DD"),
          ),
        },
      });
    }
  };

  /* set the startDate and endDate using date range. */
  const handleChangeDateRange = (range: any) => {
    setStartDate(moment(range.selection.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(range.selection.endDate).format("YYYY-MM-DD"));
    setSelectionRange([range.selection]);

    const diff: number = moment(range.selection.endDate).diff(
      moment(range.selection.startDate),
      "days",
    );
    if (diff === 0 && isChart && !isDailySale) {
      setPreviousIsBasedOnShift(isBasedOnShift);
      setIsBasedOnShift(false);
      setIsBasedOnShiftButtonDisabled(true);
    } else {
      if (isBasedOnShiftButtonDisabled) {
        setIsBasedOnShiftButtonDisabled(false);
        setIsBasedOnShift(isPreviousBasedOnShift);
      }
    }

    if (isChart) {
      handelChartDateRange(range);
    }
  };

  /* set the previous startDate and endDate using date range. */
  const handleChangePreviousDateRangeDefault = (range: any) => {
    setPreviousStartDate(convertDateToMoment(range.selection.startDate));
    setPreviousEndDate(convertDateToMoment(range.selection.endDate));
    setPreviousDateSelectionRange([range.selection]);
  };

  /* The previous date is changed according to the current date. 
    This is used when there is a difference in length between the current date range and the previous date range. */
  const handleChangePreviousDateRange = (range: any) => {
    /* This works when the current start date and current end date are equal and the previous start date and 
      previous end date are unequal. Here, The previous date is changed according to the current date. */
    if (
      startDate === endDate &&
      convertDateToMoment(range.selection.startDate) !==
        convertDateToMoment(range.selection.endDate)
    ) {
      setPreviousStartDate(
        moment(startDate).subtract("1", "day").format("YYYY-MM-DD"),
      );
      setPreviousEndDate(
        moment(endDate).subtract("1", "day").format("YYYY-MM-DD"),
      );
      setPreviousDateSelectionRange([
        {
          startDate: new Date(
            moment(startDate).subtract("1", "day").format("YYYY-MM-DD"),
          ),
          endDate: new Date(
            moment(endDate).subtract("1", "day").format("YYYY-MM-DD"),
          ),
          key: "selection",
        },
      ]);
    } else if (
      /* This works when the current start date and current end date are unequal and the previous start date and 
      previous end date are equal. Here, The previous date range is changed according to the current date range. */
      startDate !== endDate &&
      convertDateToMoment(range.selection.startDate) ===
        convertDateToMoment(range.selection.endDate)
    ) {
      const diff: number = moment(endDate).diff(moment(startDate), "days") + 1;
      setPreviousStartDate(
        moment(startDate).subtract(diff, "day").format("YYYY-MM-DD"),
      );
      setPreviousEndDate(
        moment(startDate).subtract("1", "day").format("YYYY-MM-DD"),
      );
      setPreviousDateSelectionRange([
        {
          startDate: new Date(
            moment(startDate).subtract(diff, "day").format("YYYY-MM-DD"),
          ),
          endDate: new Date(
            moment(startDate).subtract("1", "day").format("YYYY-MM-DD"),
          ),
          key: "selection",
        },
      ]);
    } else {
      handleChangePreviousDateRangeDefault(range);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(userInitialList)) {
      const filteredUserArray = userSelectorList.filter((itm: any) =>
        userInitialList.includes(itm.id),
      );
      handleAllSelectedList("user", filteredUserArray);
    }
  }, [userSelectorList]);

  /* On initial load, modify the data in the filter according to the query parameters. */
  useEffect(() => {
    const discountValueMax: any = params.get("discountValueMax");
    const discountValueMin: any = params.get("discountValueMin");
    const qtyMax: any = params.get("qtyMax");
    const qtyMin: any = params.get("qtyMin");
    const party: any = params.get("partySize");
    const id: any = params.get("locationId");
    const startDate: any = params.get("startDate");
    const endDate: any = params.get("endDate");
    const mode: any = params.get("mode");
    const order: any = params.get("orderNo");
    const displayId: any = params.get("displayId");
    const receiptNo: any = params.get("receiptNo");
    const totalAmountMin: any = params.get("totalAmountMin");
    const totalAmountMax: any = params.get("totalAmountMax");
    const paymentType: any = params.get("paymentType");
    const channel: any = params.get("channel");
    const platform: any = params.get("platform");
    const user: any = params.get("user");
    const basedOnShift: any = params.get("basedOnShift");
    const amountMin: any = params.get("amountMin");
    const amountMax: any = params.get("amountMax");
    const vatPercent: any = params.get("vatPercent");
    const payInOrOut: any = params.get("payInOrOut");
    const type: any = params.get("type");
    const orderStatus: any = params.get("orderStatus");
    const orderTotalMin: any = params.get("orderTotalMin");
    const orderTotalMax: any = params.get("orderTotalMax");
    const deliveryType: any = params.get("deliveryType");
    const dueAndOrderTime: any = params.get("dueAndOrderTime");

    /* When there is no location id in the query parameters, 
    the corresponding location and this month are entered for the query parameters. */
    if (_.isEmpty(id) && isGetLocationInfo) {
      const filteredCurrentLocation = locationSelectorList.filter(
        (itm: any) => itm.id === match.params.locationId,
      );

      setLocationSelectedList(filteredCurrentLocation);
      handleAllSelectedList("location", filteredCurrentLocation);
      if (payInOut) {
        handleChangeDateRange({
          selection: {
            startDate: new Date(
              moment(new Date()).startOf("month").format("YYYY-MM-DD"),
            ),
            key: "selection",
            endDate: new Date(
              moment(new Date()).endOf("month").format("YYYY-MM-DD"),
            ),
          },
        });
      }
    } else if (!_.isEmpty(params.toString()) && isGetLocationInfo) {
      if (discountValueMin !== null) {
        setDiscountValueMin(discountValueMin);
        setFilterDiscountValueMin(discountValueMin);
      }

      if (discountValueMax !== null) {
        setDiscountValueMax(discountValueMax);
        setFilterDiscountValueMax(discountValueMax);
      }
      if (qtyMin !== null) {
        setQtyMin(qtyMin);
        setFilterQtyMin(qtyMin);
      }
      if (qtyMax !== null) {
        setQtyMax(qtyMax);
        setFilterQtyMax(qtyMax);
      }
      if (amountMin !== null) {
        setAmountMin(amountMin);
        setFilterAmountMin(amountMin);
      }
      if (orderTotalMin !== null) {
        setOrderTotalMin(orderTotalMin);
        setFilterOrderTotalMin(orderTotalMin);
      }
      if (amountMax !== null) {
        setAmountMax(amountMax);
        setFilterAmountMax(amountMax);
      }
      if (orderTotalMax !== null) {
        setOrderTotalMax(orderTotalMax);
        setFilterOrderTotalMax(orderTotalMax);
      }
      if (order !== null) {
        setOrderNo(order);
        setFilterOrderNo(order);
      }
      if (displayId !== null) {
        setQueOrderNo(displayId);
        setFilterQueOrderNo(displayId);
      }
      if (receiptNo !== null) {
        setReceiptNo(receiptNo);
        setFilterReceiptNo(receiptNo);
      }
      if (totalAmountMin !== null) {
        setTotalAmountMin(totalAmountMin);
        setFilterTotalAmountMin(totalAmountMin);
      }
      if (totalAmountMax !== null) {
        setTotalAmountMax(totalAmountMax);
        setFilterTotalAmountMax(totalAmountMax);
      }
      if (user !== null) {
        const userList: any = [...user.split(",")];
        setUserInitialList(userList);
      }
      if (id !== null) {
        const idList = [...id.split(",")];
        const filteredLocationArray = getFilterListFromArrayObjectAndArray(
          locationSelectorList,
          idList,
        );
        if (filteredLocationArray.length === 1) {
        }
        setLocationSelectedList(filteredLocationArray);
        handleAllSelectedList("location", filteredLocationArray);
      }
      if (payInOrOut !== null) {
        const payInOrOutList = [...payInOrOut.split(",")];
        const filteredPartySizeArray = getFilterListFromArrayObjectAndArray(
          payInOutSelectorList,
          payInOrOutList,
        );
        setPayInOutSelectedList(filteredPartySizeArray);
        handleAllSelectedList("payInOut", filteredPartySizeArray);
      }
      if (type !== null) {
        const typeList = [...type.split(",")];
        const filteredPartySizeArray = getFilterListFromArrayObjectAndArray(
          typeSelectorList,
          typeList,
        );
        setAllTypeSelectedList(filteredPartySizeArray);
        handleAllSelectedList("type", filteredPartySizeArray);
      }
      if (party !== null) {
        const partyList = [...party.split(",")];
        const filteredPartySizeArray = getFilterListFromArrayObjectAndArray(
          partySizeSelectorList,
          partyList,
        );
        setPartySizeSelectedList(filteredPartySizeArray);
        handleAllSelectedList("partySize", filteredPartySizeArray);
      }

      if (mode !== null) {
        const modeList = [...mode.split(",")];
        const filteredLocationArray = getFilterListFromArrayObjectAndArray(
          defaultModeSelectorList,
          modeList,
        );
        handleAllSelectedList("mode", filteredLocationArray);
      }
      if (vatPercent !== null) {
        const vatPercentList = [...vatPercent.split(",")];
        const filteredLocationArray = getFilterListFromArrayObjectAndArray(
          vatPercentSelectorList,
          vatPercentList,
        );
        handleAllSelectedList("vatPercent", filteredLocationArray);
      }

      if (paymentType !== null) {
        let paymentTypeList: any = [...paymentType.split(",")];
        const filteredTypeArray = getFilterListFromArrayObjectAndArray(
          paymentTypeSelectorList,
          paymentTypeList,
        );
        handleAllSelectedList("paymentType", filteredTypeArray);
      }
      if (orderStatus !== null) {
        let orderStatusList: any = [...orderStatus.split(",")];
        const filteredTypeArray = getFilterListFromArrayObjectAndArray(
          paymentTypeSelectorList,
          orderStatusList,
        );
        handleAllSelectedList("orderStatus", filteredTypeArray);
      }
      if (deliveryType !== null) {
        let orderStatusList: any = [...deliveryType.split(",")];
        const filteredTypeArray = getFilterListFromArrayObjectAndArray(
          deliveryTypeFilterList,
          orderStatusList,
        );
        handleAllSelectedList("deliveryType", filteredTypeArray);
      }
      if (channel !== null) {
        let channelList: any = [...channel.split(",")];
        const filteredChannelArray = getFilterListFromArrayObjectAndArray(
          channelSelectorList,
          channelList,
        );
        handleAllSelectedList("channel", filteredChannelArray);
      }
      if (platform !== null) {
        let channelList: any = [...platform.split(",")];
        const filteredChannelArray = getFilterListFromArrayObjectAndArray(
          platformSelectorList,
          channelList,
        );
        handleAllSelectedList("platform", filteredChannelArray);
      }
      if (startDate !== null) {
        handleChangeDateRange({
          selection: {
            startDate: new Date(startDate),
            key: "selection",
            endDate: new Date(endDate),
          },
        });

        if (isChart) {
          /* This will only work if the query parameters contain this month. */
          if (
            convertDateToMoment(startDate) ===
              getStartDateDateMonthYear(new Date(), "month") &&
            convertDateToMoment(endDate) ===
              getEndDateDateMonthYear(new Date(), "month")
          ) {
            handleChangePreviousDateRange({
              selection: {
                startDate: new Date(
                  moment(startDate)
                    .subtract(1, "months")
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment(startDate)
                    .subtract(1, "months")
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                ),
              },
            });
          } else if (
            startDate ===
              getStartDateDateMonthYear(
                new Date(
                  moment(startDate).startOf("month").format("YYYY-MM-DD"),
                ),
                "month",
              ) &&
            endDate ===
              getEndDateDateMonthYear(
                new Date(moment(startDate).endOf("month").format("YYYY-MM-DD")),
                "month",
              )
          ) {
            handleChangePreviousDateRange({
              selection: {
                startDate: new Date(
                  moment(startDate)
                    .subtract(1, "months")
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment(startDate)
                    .subtract(1, "months")
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                ),
              },
            });
          } else if (
            /* This will only work if the query parameters contain this year. */
            convertDateToMoment(startDate) ===
              getStartDateDateMonthYear(new Date(), "year") &&
            convertDateToMoment(endDate) ===
              getEndDateDateMonthYear(new Date(), "year")
          ) {
            handleChangePreviousDateRange({
              selection: {
                startDate: new Date(
                  moment()
                    .subtract(1, "y")
                    .startOf("year")
                    .format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment().subtract(1, "y").endOf("year").format("YYYY-MM-DD"),
                ),
              },
            });
          } else if (
            /* This will only work if the query parameters contain date. */
            convertDateToMoment(startDate) === convertDateToMoment(endDate)
          ) {
            handleChangePreviousDateRangeDefault({
              selection: {
                startDate: new Date(
                  moment(startDate).subtract(1, "d").format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment(startDate).subtract(1, "d").format("YYYY-MM-DD"),
                ),
              },
            });
          } else {
            /* This works when the current start date and current end date are unequal and the previous start date and 
              previous end date are equal. Here, The previous date range is changed according to the current date range. */
            const diff: number =
              moment(endDate).diff(moment(startDate), "days") + 1;
            handleChangePreviousDateRange({
              selection: {
                startDate: new Date(
                  moment(startDate).subtract(diff, "d").format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment(startDate).subtract(1, "d").format("YYYY-MM-DD"),
                ),
              },
            });
          }
        }
      }
      if (basedOnShift !== null) {
        const diff: number = moment(endDate).diff(moment(startDate), "days");
        if (diff === 0 && isChart && !isDailySale) {
          setIsBasedOnShift(false);
          setIsBasedOnShiftButtonDisabled(true);
        } else {
          setIsBasedOnShiftButtonDisabled(false);
          if (basedOnShift === "true") {
            setIsBasedOnShift(true);
          } else {
            setIsBasedOnShift(false);
          }
        }
      }
      if (dueAndOrderTime !== null) {
        if (dueAndOrderTime === "true") {
          setOrderAndDueTimeSwitch(true);
        } else {
          setOrderAndDueTimeSwitch(false);
        }
      }
    }
  }, [isGetLocationInfo]);

  /* Remove empty query params */
  const handleRemoveEmptyQuery = (queryParam: any) => {
    const params = new URLSearchParams(queryParam);
    let keysForDel: any = [];
    params.forEach((value, key) => {
      if (value === "" || value === null) {
        keysForDel.push(key);
      }
    });
    keysForDel.forEach((key: any) => {
      params.delete(key);
    });
    return params;
  };

  /* Update query params when change the state */
  const handleUpdateQueryParams = () => {
    const queryParam = {
      discountValueMin: filterDiscountValueMin,
      discountValueMax: filterDiscountValueMax,
      qtyMin: filterQtyMin,
      qtyMax: filterQtyMax,
      locationId: locationFilterList.toString(),
      startDate: startDate,
      endDate: endDate,
      mode: typeFilterList.toString(),
      channel: channelFilterList.toString(),
      platform: platformFilterList.toString(),
      partySize: partySizeFilterList.toString(),
      paymentType: paymentTypeFilterList.toString(),
      user: userFilterList.toString(),
      orderNo: orderNo,
      displayId: queOrderNo,
      receiptNo: receiptNo,
      totalAmountMin: filterTotalAmountMin,
      totalAmountMax: filterTotalAmountMax,
      basedOnShift: isBasedOnShift,
      amountMin: filterAmountMin,
      amountMax: filterAmountMax,
      vatPercent: vatPercentFilterList.toString(),
      payInOrOut: payInOrOutFilterList.toString(),
      type: alTypeFilterList.toString(),
      orderStatus: orderStatusFilterList.toString(),
      orderTotalMin: filterOrderTotalMin.toString(),
      orderTotalMax: filterOrderTotalMax.toString(),
      deliveryType: deliveryTypeFilterList.toString(),
      dueAndOrderTime: orderAndDueTimeSwitch,
    };
    const params = handleRemoveEmptyQuery(queryParam);
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  /* Change the state after changing a query params. */
  const handleQueryParm = () => {
    const locationId: any = params.get("locationId");
    const startDate: any = params.get("startDate");
    const endDate: any = params.get("endDate");

    let arrLocation: any = [];
    if (!_.isEmpty(locationSelectorList) && !_.isEmpty(locationId)) {
      let idList = [...locationId.split(",")];
      arrLocation = getLabelListFromArrayObjectAndArray(
        locationSelectorList,
        idList,
      );
    }

    let uniqueLocation = [...new Set(arrLocation)];

    const filterDetails = handleFilterDataDetails(
      uniqueLocation,
      startDate,
      endDate,
    );
      setFilterDetails(filterDetails);
  };

  useEffect(() => {
    handleQueryParm();
  }, [params]);

  /* The values ​​to be filtered are set as query parameters and provided that data to the API. */
  useEffect(() => {
    if (isGetLocationInfo) {
      handleUpdateQueryParams();
    }
    let { filterCount, filterQuery } = handleFilterData(
      filterDiscountValueMin,
      filterDiscountValueMax,
      filterQtyMin,
      filterQtyMax,
      locationFilterList,
      filterReceiptNo,
      queOrderNo,
      startDate,
      endDate,
      filterTotalAmountMin,
      filterTotalAmountMax,
      orderNo,
      typeFilterList,
      channelFilterList,
      partySizeFilterList,
      paymentTypeFilterList,
      userFilterList,
      platformFilterList,
      filterAmountMin,
      filterAmountMax,
      vatPercentFilterList,
      payInOrOutFilterList,
      alTypeFilterList,
      isSale,
      isSaleItem,
      isShift,
      isOrder,
      orderStatusFilterList,
      filterOrderTotalMin,
      filterOrderTotalMax,
      deliveryTypeFilterList,
      orderAndDueTimeSwitch,
    );

    let previousFilterQuery: any;
    if (isChart) {
      previousFilterQuery = handleFilterData(
        filterDiscountValueMin,
        filterDiscountValueMax,
        filterQtyMin,
        filterQtyMax,
        locationFilterList,
        filterReceiptNo,
        queOrderNo,
        previousStartDate,
        previousEndDate,
        filterTotalAmountMin,
        filterTotalAmountMax,
        orderNo,
        typeFilterList,
        channelFilterList,
        partySizeFilterList,
        paymentTypeFilterList,
        userFilterList,
        platformFilterList,
        filterAmountMin,
        filterAmountMax,
        vatPercentFilterList,
        payInOrOutFilterList,
        alTypeFilterList,
      );
    }
    if (isGetLocationInfo) {
      if (
        filterQuery.includes("totalAmount>") &&
        filterQuery.includes("totalAmount<")
      ) {
        filterCount = filterCount - 1;
      }
      if (
        filterQuery.includes("discountValue>") &&
        filterQuery.includes("discountValue<")
      ) {
        filterCount = filterCount - 1;
      }
      if (filterQuery.includes("amount>") && filterQuery.includes("amount<")) {
        filterCount = filterCount - 1;
      }
      if (filterQuery.includes("qty<=") && filterQuery.includes("qty>=")) {
        filterCount = filterCount - 1;
      }
      if (
        filterQuery.includes("discount>") &&
        filterQuery.includes("discount<")
      ) {
        filterCount = filterCount - 1;
      }
      if (
        filterQuery.includes("paymentAmount>=") &&
        filterQuery.includes("paymentAmount<=")
      ) {
        filterCount = filterCount - 1;
      }
      setCountOfFilters(filterCount - 1);

      getFilterData(filterQuery, isBasedOnShift);
      if (isChart) {
        let count = previousFilterQuery.filterCount;
        if (
          filterQuery.includes("totalAmount>") &&
          filterQuery.includes("totalAmount<")
        ) {
          count = count - 1;
        }
        if (
          filterQuery.includes("discountValue>") &&
          filterQuery.includes("discountValue<")
        ) {
          count = count - 1;
        }
        if (
          filterQuery.includes("amount>") &&
          filterQuery.includes("amount<")
        ) {
          filterCount = filterCount - 1;
        }
        if (filterQuery.includes("qty<=") && filterQuery.includes("qty>=")) {
          filterCount = filterCount - 1;
        }
        if (
          filterQuery.includes("discount>") &&
          filterQuery.includes("discount<")
        ) {
          filterCount = filterCount - 1;
        }
        if (
          filterQuery.includes("paymentAmount>=") &&
          filterQuery.includes("paymentAmount<=")
        ) {
          filterCount = filterCount - 1;
        }

        setCountOfFilters(count - 1);
        getPreviousFilterInfo(previousFilterQuery.filterQuery, isBasedOnShift);
      }
    }
  }, [
    filterDiscountValueMin,
    filterDiscountValueMax,
    filterQtyMin,
    filterQtyMax,
    locationFilterList,
    filterReceiptNo,
    filterQueOrderNo,
    startDate,
    endDate,
    filterTotalAmountMin,
    filterTotalAmountMax,
    filterOrderNo,
    typeFilterList,
    channelFilterList,
    platformFilterList,
    partySizeFilterList,
    paymentTypeFilterList,
    userFilterList,
    isBasedOnShift,
    filterAmountMin,
    filterAmountMax,
    vatPercentFilterList,
    previousStartDate,
    previousEndDate,
    payInOrOutFilterList,
    alTypeFilterList,
    orderStatusFilterList,
    filterOrderTotalMin,
    filterOrderTotalMax,
    deliveryTypeFilterList,
    orderAndDueTimeSwitch,
  ]);

  const match: any = useRouteMatch();

  /* This useEffect will be executed 2 minutes after typing the last letter in the text field. */
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!_.isEqual(amountMin, filterAmountMin)) {
        setFilterAmountMin(amountMin);
        if (parseFloat(amountMin) > parseFloat(amountMax)) {
          setFilterAmountMax("");
          setAmountMax("");
        }
      }
      if (!_.isEqual(amountMax, filterAmountMax)) {
        setFilterAmountMax(amountMax);
        if (parseFloat(amountMin) > parseFloat(amountMax)) {
          setAmountMin("");
          setFilterAmountMin("");
        }
      }

      if (!_.isEqual(orderTotalMin, filterOrderTotalMin)) {
        setFilterOrderTotalMin(orderTotalMin);
        if (parseFloat(orderTotalMin) > parseFloat(orderTotalMax)) {
          setFilterOrderTotalMax("");
          setAmountMax("");
        }
      }
      if (!_.isEqual(orderTotalMax, filterOrderTotalMax)) {
        setFilterOrderTotalMax(orderTotalMax);
        if (parseFloat(orderTotalMin) > parseFloat(orderTotalMax)) {
          setOrderTotalMin("");
          setFilterOrderTotalMin("");
        }
      }

      if (!_.isEqual(qtyMin, filterQtyMin)) {
        setFilterQtyMin(qtyMin);
        if (parseFloat(qtyMin) > parseFloat(qtyMax)) {
          setFilterQtyMax("");
          setQtyMax("");
        }
      }
      if (!_.isEqual(qtyMax, filterQtyMax)) {
        setFilterQtyMax(qtyMax);
        if (parseFloat(qtyMin) > parseFloat(qtyMax)) {
          setQtyMin("");
          setFilterQtyMin("");
        }
      }

      if (!_.isEqual(discountValueMin, filterDiscountValueMin)) {
        setFilterDiscountValueMin(discountValueMin);
        if (parseFloat(discountValueMin) > parseFloat(discountValueMax)) {
          setFilterDiscountValueMax("");
          setDiscountValueMax("");
        }
      }
      if (!_.isEqual(discountValueMax, filterDiscountValueMax)) {
        setFilterDiscountValueMax(discountValueMax);
        if (parseFloat(discountValueMin) > parseFloat(discountValueMax)) {
          setDiscountValueMin("");
          setFilterDiscountValueMin("");
        }
      }

      if (!_.isEqual(totalAmountMin, filterTotalAmountMin)) {
        setFilterTotalAmountMin(totalAmountMin);
        if (parseFloat(totalAmountMin) > parseFloat(totalAmountMax)) {
          setFilterTotalAmountMax("");
          setTotalAmountMax("");
        }
      }
      if (!_.isEqual(totalAmountMax, filterTotalAmountMax)) {
        setFilterTotalAmountMax(totalAmountMax);
        if (parseFloat(totalAmountMin) > parseFloat(totalAmountMax)) {
          setTotalAmountMin("");
          setFilterTotalAmountMin("");
        }
      }

      if (receiptNo !== filterReceiptNo) {
        setFilterReceiptNo(receiptNo);
      }
      if (orderNo !== filterOrderNo) {
        setFilterOrderNo(orderNo);
      }
      if (queOrderNo !== filterQueOrderNo) {
        setFilterQueOrderNo(queOrderNo);
      }
    }, 2000);
    return () => clearTimeout(timeOutId);
  }, [
    amountMin,
    amountMax,
    qtyMin,
    qtyMax,
    discountValueMin,
    discountValueMax,
    totalAmountMin,
    totalAmountMax,
    receiptNo,
    queOrderNo,
    orderNo,
    orderTotalMin,
    orderTotalMax,
  ]);

  /* Change the based on shift */
  const handleBasedOnShift = (e: any) => {
    const { checked } = e.target;
    setIsBasedOnShift(checked);
  };

  /* Open and close filter card */
  const handleChangeFilterCard = () => {
    setOpenFilterCard(!openFilterCard);
  };

  const handleChangeOrderAndDueTimeSwitch = (e: any) => {
    setOrderAndDueTimeSwitch(!orderAndDueTimeSwitch);
  };

  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "start", marginTop: "16px" }}
      >
        <Badge
          badgeContent={countOfFilters}
          color="secondary"
          className={classes.badgeFontColor}
        >
          <IconButton
            className={classes.iconButton}
            onClick={handleChangeFilterCard}
          >
            <Typography style={{ display: "flex", alignItems: "center" }}>
              <TuneIcon fontSize="small" />
            </Typography>
            <Typography style={{ marginLeft: "8px", letterSpacing: "1px" }}>
              Filters
            </Typography>
          </IconButton>
        </Badge>
      </div>
      {openFilterCard && (
        <FilterCard
          setDiscountValueMin={(e: any) => setDiscountValueMin(e)}
          discountValueMin={discountValueMin}
          setDiscountValueMax={(e: any) => setDiscountValueMax(e)}
          setReceiptNo={(e: any) => setReceiptNo(e)}
          receiptNo={receiptNo}
          discountValueMax={discountValueMax}
          locationSelectorList={locationSelectorList}
          handleAllSelectedList={handleAllSelectedList}
          getLocationSelectorTypingList={getLocationSelectorTypingList}
          searchLocationName={searchLocationName}
          setSearchLocationName={(e: any) => setSearchLocationName(e)}
          locationSelectedList={locationSelectedList}
          setQueOrderNo={setQueOrderNo}
          queOrderNo={queOrderNo}
          handleChangeDateRange={handleChangeDateRange}
          dateSelectionRange={dateSelectionRange}
          startDate={startDate}
          endDate={endDate}
          totalAmountMin={totalAmountMin}
          totalAmountMax={totalAmountMax}
          setTotalAmountMin={setTotalAmountMin}
          setTotalAmountMax={setTotalAmountMax}
          orderNo={orderNo}
          setOrderNo={setOrderNo}
          typeSelectedList={typeSelectedList}
          channelSelectedList={channelSelectedList}
          platformSelectedList={platformSelectedList}
          partySizeSelectedList={partySizeSelectedList}
          paymentTypeSelectedList={paymentTypeSelectedList}
          userSelectedList={userSelectedList}
          userSelectorList={userSelectorList}
          setFilterOrderNo={setFilterOrderNo}
          setFilterQueOrderNo={setFilterQueOrderNo}
          setFilterReceiptNo={setFilterReceiptNo}
          setFilterTotalAmountMin={setFilterTotalAmountMin}
          setFilterTotalAmountMax={setFilterTotalAmountMax}
          setFilterDiscountValueMin={setFilterDiscountValueMin}
          setFilterDiscountValueMax={setFilterDiscountValueMax}
          isBasedOnShift={isBasedOnShift}
          handleBasedOnShift={handleBasedOnShift}
          availableFilter={availableFilter}
          qtyMin={qtyMin}
          setQtyMin={setQtyMin}
          qtyMax={qtyMax}
          setQtyMax={setQtyMax}
          setFilterQtyMin={setFilterQtyMin}
          setFilterQtyMax={setFilterQtyMax}
          amountMin={amountMin}
          amountMax={amountMax}
          setAmountMin={setAmountMin}
          setAmountMax={setAmountMax}
          setFilterAmountMin={setFilterAmountMin}
          setFilterAmountMax={setFilterAmountMax}
          vatPercentSelectedList={vatPercentSelectedList}
          handleChangePreviousDateRange={handleChangePreviousDateRange}
          previousDateSelectionRange={previousDateSelectionRange}
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          isChart={isChart}
          payInOutSelectedList={payInOutSelectedList}
          allTypeSelectedList={allTypeSelectedList}
          isBasedOnShiftButtonDisabled={isBasedOnShiftButtonDisabled}
          orderStatusSelectedList={orderStatusSelectedList}
          orderTotalMin={orderTotalMin}
          setOrderTotalMin={setOrderTotalMin}
          orderTotalMax={orderTotalMax}
          setOrderTotalMax={setOrderTotalMax}
          setFilterOrderTotalMax={setFilterOrderTotalMax}
          setFilterOrderTotalMin={setFilterOrderTotalMin}
          isOrder={isOrder}
          deliveryTpeSelectedList={deliveryTpeSelectedList}
          handleChangeOrderAndDueTimeSwitch={handleChangeOrderAndDueTimeSwitch}
          orderAndDueTimeSwitch={orderAndDueTimeSwitch}
        />
      )}
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default Filter;

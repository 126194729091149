import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddStockItems from "./AddStockItems";
import DialogCommonDefault from "../dialogs/DialogCommonDefault";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import MenuIcon from "@material-ui/icons/Menu";
import _ from "lodash";
import {
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../utils/consts";
import AddMapProduct from "./AddMapProduct";
import TextfieldCommon from "../textField/TextfieldCommon";
import { CustomTheme } from "../../types/customTheme";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import {
  createMapProductsInfo,
  createStockDepartments,
  createStockGroups,
  createStockItemsInfo,
  createSupplier,
  createSupplierForStockItem,
  fetchAllStockGroups,
  fetchGetAllStockDepartments,
  supplierMap,
  updateStockItemsInfo,
} from "../../services/inventory/stockItems";
import AddSupplier from "./AddSupplier";
import ApiCallLoader from "../common/ApiCallLoader";
import AddSupplierModal from "./AddSupplierModal";
import { DeleteStockSupplierMap } from "../../services/inventory/supplier";
import IncrementDecrementTextboxDefault from "../common/IncrementDecrementTextbox/IncrementDecrementTextboxDefault";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    "& .MuiTabs-root": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 280px)",
      },
    },
  },
  rowOdd: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  arrow: {
    width: 22,
  },
  arrowIcon: {
    color: theme.palette.text.titleTextColor,
    width: 30,
  },
  autoComplete: {
    marginTop: "4px",
    "& .MuiAutocomplete-input": {
      color: theme.palette.custom.orange.contrastText,
    },
    "& .MuiInputBase-root": {
      color: "inherit",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: 10,
    },
    [`& fieldset`]: {
      borderRadius: 10,
      border: `1px solid ${theme.palette.background.entity_border}`,
      color: theme.palette.custom.orange.contrastText,
    },
  },
  titleColor: {
    color: theme.palette.custom.orange.contrastText,
  },
}));

export interface paymentReportProps {
  isOpenCreateStockItemsModal: any;
  setIsOpenCreateStockItemsModal: any;
  setError: any;
  setIsCreated: any;
  setSuccess: any;
  isCreated: any;
  menuGroups: any;
  menuDepartments: any;
  menuProducts: any;
  handleGetStockItemDetails: any;
  isOpenEditStockItemsModal: any;
  selectedNodeData: any;
  isOpenMapProductsEditModal: any;
  id: any;
  stockMappingDetailsNode: any;
  mapProductSkeletonLoading: any;
  setStockMappingDetailsNode: any;
  handleOpenEditMapProductsItemsModal: any;
  setSelectedNodeData: any;
  handleOpenAddSupplierToTheStockItems: any;
  supplierDetailsNode: any;
  isOpenSupplierAddModal: any;
  setIsOpenSupplierAddModal: any;
  initialSelectedSupplierList: any;
  isOpenSupplierEditModal: any;
  groupId: any;
  getAllStockDepartmentForGroupNotLoad: any;
  setGroupId: any;
  getAllStockGroup: any;
  selectedGroupObj: any;
  setSelectedGroupObj: any;
  stockGroupSelectedNode: any;
  setStockGroupSelectedNode: any;
  selectedDepartmentIdInMenu: any;
  setActiveGroupIds: any;
  handleGetSupplierInfo: any;
  handleGetSelectedSupplier?: any;
  setCreatedId?: any;
}

interface FormData {
  id: string;
  barcode: string;
  criticalThreshold: any;
  displaySuffix: string;
  inputMultiplier: any;
  inputSuffix: string;
  name: string;
  sku: string;
  warningThreshold: any;
  active: Boolean;
  primaryLocationId: string;
  version: number;
  stockDepartmentId: any;
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

/* Set the filter, sale title, sale sub title, and table using filter details */
const AddStockItemsModal: React.FunctionComponent<paymentReportProps> = ({
  isOpenCreateStockItemsModal,
  setIsOpenCreateStockItemsModal,
  setError,
  setIsCreated,
  setSuccess,
  isCreated,
  menuGroups,
  menuDepartments,
  menuProducts,
  handleGetStockItemDetails,
  isOpenEditStockItemsModal,
  selectedNodeData,
  isOpenMapProductsEditModal,
  id,
  stockMappingDetailsNode,
  mapProductSkeletonLoading,
  setStockMappingDetailsNode,
  handleOpenEditMapProductsItemsModal,
  setSelectedNodeData,
  handleOpenAddSupplierToTheStockItems,
  supplierDetailsNode,
  isOpenSupplierAddModal,
  setIsOpenSupplierAddModal,
  initialSelectedSupplierList,
  isOpenSupplierEditModal,
  groupId,
  getAllStockDepartmentForGroupNotLoad,
  setGroupId,
  getAllStockGroup,
  selectedGroupObj,
  setSelectedGroupObj,
  stockGroupSelectedNode,
  setStockGroupSelectedNode,
  selectedDepartmentIdInMenu,
  setActiveGroupIds,
  handleGetSupplierInfo,
  handleGetSelectedSupplier,
  setCreatedId,
}) => {
  const [isOpenMapProducts, setIsOpenMapProducts] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeGroupId, setActiveGroupId] = useState(0);
  const [filterDepartment, setFilterDepartment] = useState<any>({});
  const [menuNode, setMenuNode] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [menuNodeInitial, setMenuNodeInitial] = useState<any>({});
  const [initialOutputMultiplier, setInitialOutputMultiplier] = useState("10");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isCompleteLoad, setIsCompleteLoad] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [outputMultiplierObj, setOutputMultiplierObj] = useState<any>({});
  const [stockItemId, setStockItemId] = useState("");
  const [checkedProductIdList, setCheckedProductIdList] = useState<any>([]);
  const [checkedProductIdListInitial, setCheckedProductIdListInitial] =
    useState<any>([]);
  const [selectedSupplierList, setSelectedSupplierList] = useState<any>([]);
  const [selectedSupplierListInitial, setSelectedSupplierListInitial] =
    useState<any>([]);
  const [successErrorList, setSuccessErrorList] = useState<any>([]);
  const [stockDepartmentSelectedNode, setStockDepartmentSelectedNode] =
    useState<any>([]);
  const [selectedDepartmentObj, setSelectedDepartmentObj] = useState<any>({});
  const [selectedDepartmentObjInitial, setSelectedDepartmentObjInitial] =
    useState<any>({});
  const [isOpenDepartmentAddModal, setIsOpenDepartmentAddModal] =
    useState(false);
  const [isOpenSupplierAddModals, setIsOpenSupplierAddModals] = useState(false);
  const [name, setName] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [address, setAddress] = useState("");
  const [contactNo, setDContactNoName] = useState("");
  const [email, setEmail] = useState("");
  const [isOpenGroupAddModal, setIsOpenGroupAddModal] = useState(false);
  const [groupName, setGroupName] = useState("");

  // const [departmentId, setDepartmentId]
  const match: any = useRouteMatch();

  const elementsByTitle: any = {}; // Map to store elements by title

  const getAllStockDepartments = async (id?: any) => {
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await fetchGetAllStockDepartments(match.params.locationId);
      // const dept = res.data.data.filter((data: any) => data.id === id);
      res.data.data.map((data: any) => {
        if (data.id === id) {
          setSelectedDepartmentObj(data);
        }
      });
      if (res.data.data) {
        setStockDepartmentSelectedNode(res.data.data);
      } else {
        setStockDepartmentSelectedNode([]);
      }
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  useEffect(() => {
    getAllStockDepartments(selectedDepartmentIdInMenu);
  }, []);
  useEffect(() => {
    if (initialSelectedSupplierList) {
      const cloneSelectedSupplierList = _.cloneDeep(selectedSupplierList);
      initialSelectedSupplierList.map((data: any) => {
        if (data.stockItemId.id === id) {
          cloneSelectedSupplierList.push(data["supplierId"].id);
        }
      });
      setSelectedSupplierList(cloneSelectedSupplierList);
      setSelectedSupplierListInitial(cloneSelectedSupplierList);
    }
  }, [initialSelectedSupplierList]);

  const handleSupplierSkeleton = () => {
    return (
      <div style={{ margin: "20px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Skeleton
              variant="rect"
              width={"100%"}
              className={classes.rowOdd}
              height={120}
            />
          </Grid>
          <Grid item xs={4}>
            <Skeleton
              variant="rect"
              width={"100%"}
              className={classes.rowOdd}
              height={120}
            />
          </Grid>
          <Grid item xs={4}>
            <Skeleton
              variant="rect"
              width={"100%"}
              className={classes.rowOdd}
              height={120}
            />
          </Grid>
          <Grid item xs={4}>
            <Skeleton
              variant="rect"
              width={"100%"}
              className={classes.rowOdd}
              height={120}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const handleMapProductSkeleton = () => {
    return (
      <Grid container>
        <Grid item xs={6} style={{ padding: "0px 16px 4px 32px" }}>
          <Skeleton
            variant="rect"
            width={"100%"}
            className={classes.rowOdd}
            height={40}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 32px 4px 16px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 16px 4px 32px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 32px 4px 16px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 16px 4px 32px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 32px 4px 16px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 16px 4px 32px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0px 32px 4px 16px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    const mapProductObj: any = {};
    const idList: any = [];
    if (stockMappingDetailsNode.length > 0) {
      stockMappingDetailsNode.map((data: any) => {
        idList.push(data.productId);
        mapProductObj[data.productId] = {
          product_id: data.productId,
          output_multiplier: data.outputMultiplier,
        };
      });
    }
    setCheckedProductIdList(idList);
    setCheckedProductIdListInitial(idList);
    setOutputMultiplierObj(mapProductObj);
  }, [stockMappingDetailsNode]);

  useEffect(() => {
    if (
      menuDepartments &&
      Object.values(menuDepartments).length > 0 &&
      isOpenCreateStockItemsModal
    ) {
      const firstGroup: any = Object.values(menuDepartments)[0];
      setSelectedDepartmentId(firstGroup.id);
    }
  }, [menuDepartments, isOpenCreateStockItemsModal]);

  useEffect(() => {
    if (
      isOpenCreateStockItemsModal &&
      Object.values(menuProducts).length > 0 &&
      Object.values(menuDepartments).length > 0 &&
      Object.values(menuGroups).length > 0
    ) {
      const convertedData: any = {};
      for (const departmentId in menuDepartments) {
        const dept = menuDepartments[departmentId];
        const groupId = dept.group_id;
        if (!convertedData[groupId]) {
          convertedData[groupId] = { ...menuGroups[groupId], department: {} };
        }
        convertedData[groupId].department[departmentId] = {
          ...dept,
          products: {},
        };
      }

      for (const productId in menuProducts) {
        const prod = menuProducts[productId];
        const deptId = prod.department_id;
        const groupId = menuDepartments[deptId].group_id;
        convertedData[groupId].department[deptId].products[productId] = prod;
      }
      setMenuNode(convertedData);
      setMenuNodeInitial(convertedData);
      setIsLoading(false);
    }
  }, [menuProducts, menuDepartments, menuGroups, isOpenCreateStockItemsModal]);

  // Function to register elements with their titles in the map
  const registerElementWithTitle = (title: any, elementRef: any) => {
    elementsByTitle[title] = elementRef;
  };

  const handleButtonClick = (title: any) => () => {
    if (title.includes("group")) {
      const splitGroupId = title.split(" ");
      if (menuDepartments) {
        const filterMenuDepartment = Object.values(menuDepartments).filter(
          (department: any) =>
            department.group_id.toString() === splitGroupId[1],
        );
        setFilterDepartment(filterMenuDepartment);
      }
    } else {
      const splitDepartmentId = title.split(" ");
      setSelectedDepartmentId(splitDepartmentId[1]);
    }
    // Scroll to the target element when the button is clicked
    const targetElement = elementsByTitle[title];
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth", // You can use 'auto' for instant scrolling
        block: "start", // You can change this to 'end' if you want to scroll to the bottom
      });
    }
  };
  const handleButtonClickMobile = (title: any) => {
    if (title.includes("group")) {
      const splitGroupId = title.split(" ");
      if (menuDepartments) {
        const filterMenuDepartment = Object.values(menuDepartments).filter(
          (department: any) =>
            department.group_id.toString() === splitGroupId[1],
        );
        setFilterDepartment(filterMenuDepartment);
      }
    }

    // Scroll to the target element when the button is clicked
    const targetElement = elementsByTitle[title];
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth", // You can use 'auto' for instant scrolling
        block: "start", // You can change this to 'end' if you want to scroll to the bottom
      });
    }
    setTimeout(() => {
      setIsOpenDrawer(false);
    }, 500);
  };

  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    clearErrors,
    watch,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      barcode: "",
      criticalThreshold: "",
      displaySuffix: "",
      inputMultiplier: "",
      inputSuffix: "",
      name: "",
      sku: "",
      warningThreshold: "",
      stockDepartmentId: "",
    },
  });
  const handleCreateStockItems = async (updatedStockItem: any, data: any) => {
    setError("");
    try {
      const res = await createStockItemsInfo(
        match.params.locationId,
        updatedStockItem,
      );
      const cloneSuccessErrorList = _.cloneDeep(successErrorList);
      cloneSuccessErrorList.push({
        id: "success",
        status: "Successfully Created stock Item.",
        data: [],
      });
      setSuccessErrorList(cloneSuccessErrorList);
      setIsCreated(true);
      setSuccess(SUCCESSFULLY_CREATED);
      setName(res.data.data.name);
      handleCreateSuppliersForStockItem(res.data.data.id);
      setCreatedId(res.data.data);
      setStockItemId(res.data.data.id);
      setIsCompleteLoad(true);
      setGroupId(res.data.data.stockDepartmentId.stockGroupId.id);
      getAllStockDepartmentForGroupNotLoad(
        res.data.data.stockDepartmentId.stockGroupId.id,
        "",
        "",
      );
    } catch (error) {
      const cloneSuccessErrorList = _.cloneDeep(successErrorList);
      cloneSuccessErrorList.push({
        id: "error",
        status: "Stock Items Update Error.",
        errorFunction: "handleSubmitData",
        data: data,
      });
      setSuccessErrorList(cloneSuccessErrorList);
      setError(ERROR_MESSAGE_CREATING_ERROR);
    }
  };

  const deleteSupplierDetails = async (id: any, stockItemId: any) => {
    const deletedForm = {
      locationId: match.params.locationId,
      supplierId: id,
      stockItemId: stockItemId,
    };
    try {
      const res = await DeleteStockSupplierMap(
        match.params.locationId,
        deletedForm,
      );
    } catch (error) {
      setError(ERROR_MESSAGE_CREATING_ERROR);
    }
  };
  let isDelete = false;
  const handleDeleteSupplier = (stockItemId: any) => {
    selectedSupplierListInitial.map((data: any) => {
      if (!selectedSupplierList.includes(data)) {
        isDelete = true;
        deleteSupplierDetails(data, stockItemId);
      }
    });
    setIsOpenSupplierAddModal(false);
    handleCreateSuppliersForStockItem(stockItemId, isDelete);
  };

  const handleUpdateStockItems = async (updatedStockItem: any) => {
    setError("");
    try {
      const res = await updateStockItemsInfo(
        match.params.locationId,
        updatedStockItem,
      );

      // stockGroupSelectedNode.map((data: any, index: number) => {
      //   if (data.id === res.data.data.stockDepartmentId.stockGroupId.id) {
      //     setActiveGroupIds(index);
      //   }
      // });

      handleDeleteSupplier(updatedStockItem.id);
      setName(res.data.data.name);
      setStockItemId(res.data.data.id);
      setSuccess(SUCCESSFULLY_UPDATED);
      setSelectedDepartmentObj({});
      setSelectedGroupObj({});
      handleGetStockItemDetails();
    } catch (error) {
      setError(ERROR_MESSAGE_CREATING_ERROR);
    }
  };

  const handleReset = () => {
    // Reset the form to its default values
    reset();
    setIsCreated(false);
    setIsOpenCreateStockItemsModal(false);
    setIsOpenDrawer(false);
    setIsOpenMapProducts(false);
    setOutputMultiplierObj({});
    setSelectedDepartmentId("");
    setCheckedProductIdList([]);
    setOutputMultiplierObj({});
    setStockMappingDetailsNode([]);
    setCheckedProductIdListInitial([]);
  };

  const handleResetData = () => {
    // Reset the form to its default values
    setSelectedNodeData([]);
    setIsCreated(false);
    reset();
  };
  /* Check for a create or update and 
  call the handleCreateDiscountOption function if it is a create and 
  handleUpdateDiscountInfo function if it is a update. */
  const handleSubmitData = handleSubmit((data) => {
    setSuccessErrorList([]);
    setIsCompleteLoad(false);
    const updatedStockItem = _.cloneDeep(data);
    updatedStockItem.active = true;
    updatedStockItem.primaryLocationId = match.params.locationId;
    updatedStockItem.criticalThreshold = parseFloat(data.criticalThreshold);
    updatedStockItem.inputMultiplier = parseFloat(data.inputMultiplier);
    updatedStockItem.warningThreshold = parseFloat(data.warningThreshold);
    updatedStockItem.stockDepartmentId = selectedDepartmentObj.id;
    updatedStockItem.version = 0;
    const cloneSuccessErrorList = _.cloneDeep(successErrorList);
    cloneSuccessErrorList.push({
      id: "loading",
      status: "Loading1",
      data: data,
    });
    setSuccessErrorList(cloneSuccessErrorList);

    if (isOpenEditStockItemsModal) {
      const { id, version } = selectedNodeData;
      updatedStockItem.id = id;
      updatedStockItem.version = version;
      handleUpdateStockItems(updatedStockItem);
    } else {
      handleCreateStockItems(updatedStockItem, data);
    }
  });

  const handleClearSearch = () => {
    setSearchValue("");
    setMenuNode(menuNodeInitial);
  };

  const handleChangeSearch = (e: any) => {
    const cloneMenuNode = _.cloneDeep(menuNodeInitial);
    const filtered: any = {};
    for (const id in cloneMenuNode) {
      const group = cloneMenuNode[id];
      const filteredDepartments: any = {};
      for (const id in group.department) {
        const department = group.department[id];
        const filteredProducts: any = {};
        for (const id in department.products) {
          const product = department.products[id];
          if (
            product.name.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            filteredProducts[id] = product;
          }
        }
        if (Object.keys(filteredProducts).length > 0) {
          filteredDepartments[id] = {
            ...department,
            products: filteredProducts,
          };
        }
      }
      if (Object.keys(filteredDepartments).length > 0) {
        filtered[id] = { ...group, department: filteredDepartments };
      }
    }

    if (_.isEmpty(filtered)) {
      setMenuNode(menuNodeInitial);
    } else {
      setMenuNode(filtered);
    }
    setSearchValue(e.target.value);
  };
  const handleChange = (val: any, catMenuIdx: any) => {
    setActiveGroupId(catMenuIdx);
  };

  const handleSubmitOutputMultiplier = async () => {
    setIsCompleteLoad(false);
    const cloneSuccessErrorList = _.cloneDeep(successErrorList);
    cloneSuccessErrorList.push({
      id: "loading",
      status: "Loading1",
      data: [],
    });
    setSuccessErrorList(cloneSuccessErrorList);
    const cloneOutputMultiplierObj = _.cloneDeep(outputMultiplierObj);
    // Convert the abc object to the desired output format
    const output: any = {
      products: Object.values(cloneOutputMultiplierObj).map((item: any) => ({
        product_id: item.product_id,
        output_multiplier: item.output_multiplier,
      })),
    };
    output["stock_item_id"] = stockItemId;
    try {
      const res = await createMapProductsInfo(match.params.locationId, output);
      const cloneSuccessErrorList = _.cloneDeep(successErrorList);
      cloneSuccessErrorList.push({
        id: "success",
        status: "Successfully updated map products.",
        data: [],
      });
      setIsCompleteLoad(true);
      setSuccessErrorList(cloneSuccessErrorList);
      handleReset();
      setSuccess(SUCCESSFULLY_CREATED);
    } catch (error) {
      cloneSuccessErrorList.push({
        id: "error",
        status: "Stock Map Products Update Error.",
        errorFunction: "handleSubmitOutputMultiplier",
        data: [],
      });
      setSuccessErrorList(cloneSuccessErrorList);
      setError(ERROR_MESSAGE_CREATING_ERROR);
    }
  };

  const handleCreateSuppliersForStockItem = async (
    stockItemId: any,
    isDelete?: any,
  ) => {
    setError("");
    const cloneSuccessErrorList = _.cloneDeep(successErrorList);
    cloneSuccessErrorList.push({
      id: "loading",
      status: "Loading1",
      data: "",
    });
    setSuccessErrorList(cloneSuccessErrorList);
    const data = { stockItemId: stockItemId, suppliers: selectedSupplierList };
    try {
      const res = await createSupplierForStockItem(
        match.params.locationId,
        data,
      );
      if (isOpenEditStockItemsModal) {
        setIsOpenCreateStockItemsModal(false);
        setSuccess(SUCCESSFULLY_UPDATED);
      } else {
        setSuccess(SUCCESSFULLY_CREATED);
      }
      if (isDelete) {
        handleGetSelectedSupplier();
      }
      const cloneSuccessErrorList = _.cloneDeep(successErrorList);
      cloneSuccessErrorList.push({
        id: "success",
        status: "Successfully updated map products.",
        data: [],
      });
    } catch (error) {
      cloneSuccessErrorList.push({
        id: "error",
        status: "Stock supplier Update Error.",
        errorFunction: "handleCreateSuppliersForStockItem",
        data: stockItemId,
      });
      setSuccessErrorList(cloneSuccessErrorList);
      setError(ERROR_MESSAGE_CREATING_ERROR);
    }
  };

  const handleUpdateSupplierToStockItem = () => {
    const postData = [];
    selectedSupplierList.map((data: any) => {
      if (!selectedSupplierListInitial.includes(data)) {
        postData.push(data);
      }
    });
  };

  const handleIncrement = () => {
    const cloneInitialOutputMultiplier = _.cloneDeep(initialOutputMultiplier);
    let abc: any = "";
    if (!_.isEmpty(cloneInitialOutputMultiplier.split(".")[1])) {
      abc = Math.ceil(parseFloat(cloneInitialOutputMultiplier));
    } else {
      abc = (Number(cloneInitialOutputMultiplier) + 1).toString();
    }
    setInitialOutputMultiplier(abc);
  };

  const handleDecrement = () => {
    const cloneInitialOutputMultiplier = _.cloneDeep(initialOutputMultiplier);
    let abc: any = "";
    if (!_.isEmpty(cloneInitialOutputMultiplier.split(".")[1])) {
      if (Math.floor(parseFloat(cloneInitialOutputMultiplier)) - 1 >= 0) {
        abc = Math.floor(parseFloat(cloneInitialOutputMultiplier)) - 1;
        setInitialOutputMultiplier(abc);
      }
    } else {
      if (Number(cloneInitialOutputMultiplier) - 1 >= 0) {
        abc = (Number(cloneInitialOutputMultiplier) - 1).toString();
        setInitialOutputMultiplier(abc);
      }
    }
  };

  useEffect(() => {
    if (isOpenMapProductsEditModal) {
      setIsCreated(true);
      setIsOpenMapProducts(true);
      setStockItemId(id);
    }
  }, [isOpenMapProductsEditModal]);

  const areArraysEqual = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleChangeDepartmentName = (e: any) => {
    setDepartmentName(e.target.value);
  };

  const handleChangeGroupName = (e: any) => {
    setGroupName(e.target.value);
  };

  const result = areArraysEqual(
    checkedProductIdList,
    checkedProductIdListInitial,
  );

  const handleOpenDepartmentAddModal = () => {
    setDepartmentName("");
    setIsOpenDepartmentAddModal(true);
  };

  const handleOpenSupplierAddModal = () => {
    setIsOpenSupplierAddModals(true);
  };

  const handleOpenGroupAddModal = () => {
    setGroupName("");
    setIsOpenGroupAddModal(true);
  };

  const handleModal = () => {
    if (isCreated && isOpenMapProducts && !isOpenSupplierAddModal) {
      if (!mapProductSkeletonLoading) {
        return (
          <AddMapProduct
            menuGroups={menuGroups}
            menuDepartments={menuDepartments}
            menuProducts={menuProducts}
            handleButtonClick={handleButtonClick}
            registerElementWithTitle={registerElementWithTitle}
            setActiveGroupId={setActiveGroupId}
            setFilterDepartment={setFilterDepartment}
            filterDepartment={filterDepartment}
            menuNode={menuNode}
            setMenuNode={setMenuNode}
            initialOutputMultiplier={initialOutputMultiplier}
            setIsOpenDrawer={setIsOpenDrawer}
            isOpenDrawer={isOpenDrawer}
            handleButtonClickMobile={handleButtonClickMobile}
            selectedDepartmentId={selectedDepartmentId}
            isLoading={isLoading}
            outputMultiplierObj={outputMultiplierObj}
            setOutputMultiplierObj={setOutputMultiplierObj}
            isOpenCreateStockItemsModal={isOpenCreateStockItemsModal}
            checkedProductIdList={checkedProductIdList}
            setCheckedProductIdList={setCheckedProductIdList}
          />
        );
      } else {
        return handleMapProductSkeleton();
      }
    } else if (isCreated && !isOpenMapProducts && isOpenSupplierAddModal) {
      if (!mapProductSkeletonLoading) {
        return (
          <AddSupplier
            supplierDetailsNode={supplierDetailsNode}
            setSelectedSupplierList={setSelectedSupplierList}
            selectedSupplierList={selectedSupplierList}
          />
        );
      } else {
        return handleSupplierSkeleton();
      }
    } else {
      return (
        <AddStockItems
          register={register}
          errors={errors}
          selectedNodeData={selectedNodeData}
          reset={reset}
          stockDepartmentSelectedNode={stockDepartmentSelectedNode}
          supplierDetailsNode={supplierDetailsNode}
          setSelectedSupplierList={setSelectedSupplierList}
          selectedSupplierList={selectedSupplierList}
          selectedDepartmentObj={selectedDepartmentObj}
          setSelectedDepartmentObj={setSelectedDepartmentObj}
          handleOpenDepartmentAddModal={handleOpenDepartmentAddModal}
          setSelectedDepartmentObjInitial={setSelectedDepartmentObjInitial}
          handleOpenSupplierAddModal={handleOpenSupplierAddModal}
        />
      );
    }
  };

  const handleCreateNewDepartment = async () => {
    const data = {
      name: departmentName,
      stockGroupId: selectedGroupObj.id,
      locationId: match.params.locationId,
    };

    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await createStockDepartments(match.params.locationId, data);

      setGroupId(res.data.data.stockGroupId.id);
      getAllStockDepartments(res.data.data.id);
      // getAllStockDepartmentForGroup(res.data.data.stockGroupId.id, true);
      setIsOpenDepartmentAddModal(false);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleCreateNewGroup = async () => {
    const data = {
      name: groupName,
      locationId: match.params.locationId,
    };
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await createStockGroups(match.params.locationId, data);
      setGroupId(res.data.data.id);
      getAllStockGroup(res.data.data.id, true);
      setIsOpenGroupAddModal(false);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleGroupChange = (e: any, groupData: any) => {
    setSelectedGroupObj(groupData);
    setGroupId(groupData.id);
  };
  const [initialName, setInitialName] = useState("");
  useEffect(() => {
    setInitialName(selectedNodeData.name);
  }, [isOpenCreateStockItemsModal]);

  const arraysAreEqual = (arr1: any, arr2: any) => {
    // Check if arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort the arrays
    const sortedArr1 = arr1.slice().sort((a: any, b: any) => a - b);
    const sortedArr2 = arr2.slice().sort((a: any, b: any) => a - b);

    // Compare sorted arrays element by element
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleDisable = () => {
    if (!_.isEmpty(selectedDepartmentObj)) {
      const selectedIdList: any = [];

      selectedSupplierListInitial.map((data: any) => {
        if (!selectedSupplierList.includes(data)) {
          selectedIdList.push(data);
        }
      });

      // selectedDepartmentObj.map((data: any) => {
      //   selectedIdList.push(data.id);
      // });
      // selectedDepartmentObjInitial.map((data: any) => {
      //   selectedIdListInitial.push(data.id);
      // });
      if (isDirty) {
        return false;
      } else if (selectedDepartmentObj.id !== selectedDepartmentObjInitial.id) {
        return false;
      } else if (
        !_.isEmpty(selectedIdList) ||
        selectedSupplierListInitial.length !== selectedSupplierList.length
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleSupplierMap = async (supplierId: any) => {
    const data = {
      locationId: match.params.locationId,
      supplierId: supplierId,
    };

    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await supplierMap(match.params.locationId, data);

      handleGetSupplierInfo();
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenSupplierAddModals(false);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleCreateNewSupplier = async (data: any) => {
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await createSupplier(match.params.locationId, data);
      handleSupplierMap(res.data.data.id);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const classes = useStyles();
  const dialogRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <DialogCommonDefault
        open={isOpenCreateStockItemsModal}
        setOpen={setIsOpenCreateStockItemsModal}
        isOpenMapProd={
          isCreated && (isOpenMapProducts || isOpenSupplierAddModal)
        }
      >
        <DialogContent style={{ padding: "0px" }}>
          <Hidden xsDown>
            {isCreated && isOpenMapProducts ? (
              <div style={{ display: "block" }}>
                <div>
                  <Tabs
                    classes={{
                      root:
                        Object.values(menuGroups).length === 1
                          ? classes.rootCenterTabs
                          : classes.root,
                    }}
                    value={activeGroupId}
                    onChange={handleChange}
                    variant="scrollable"
                    selectionFollowsFocus
                    scrollButtons="on"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable category menu items list"
                  >
                    {!_.isEmpty(menuGroups) &&
                      Object.values(menuGroups).map(
                        (group: any, idx: number) => (
                          <Tab
                            key={String(idx)}
                            onClick={handleButtonClick(`group ${group.id}`)}
                            label={
                              <Typography style={{ fontSize: "14px" }}>
                                {group.name}
                              </Typography>
                            }
                            {...a11yProps(group)}
                          />
                        ),
                      )}
                  </Tabs>
                </div>
                <div style={{ margin: "16px 36px" }}>
                  <TextfieldCommon
                    id="search"
                    name="search"
                    label="Search"
                    type="text"
                    style={{ width: "240px" }}
                    value={searchValue}
                    onChange={handleChangeSearch}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue ? (
                            <CloseIcon onClick={handleClearSearch} />
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            ) : (
              <DialogTitle
                id="alert-dialog-title"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {isOpenCreateStockItemsModal && !isOpenSupplierAddModal
                  ? isOpenEditStockItemsModal
                    ? `Update ${initialName} Stock Item`
                    : "Add New Stock Item"
                  : isOpenSupplierEditModal
                  ? "Edit Supplier"
                  : "Add Supplier"}
              </DialogTitle>
            )}
          </Hidden>
          {/* Mobile */}
          <Hidden smUp>
            {isCreated && isOpenMapProducts ? (
              <div style={{ display: "block" }}>
                <div style={{ display: "flex", marginLeft: "32px" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setIsOpenDrawer(!isOpenDrawer)}
                  >
                    {isOpenDrawer ? <KeyboardArrowLeftIcon /> : <MenuIcon />}
                  </IconButton>
                  <Tabs
                    classes={{
                      root:
                        Object.values(menuGroups).length === 1
                          ? classes.rootCenterTabs
                          : classes.root,
                    }}
                    value={activeGroupId}
                    onChange={handleChange}
                    variant="scrollable"
                    selectionFollowsFocus
                    scrollButtons="on"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable category menu items list"
                  >
                    {!_.isEmpty(menuGroups) &&
                      Object.values(menuGroups).map(
                        (group: any, idx: number) => (
                          <Tab
                            key={String(idx)}
                            // classes={{ root: classes.tabRoot }}
                            onClick={handleButtonClick(`group ${group.id}`)}
                            label={
                              <Typography style={{ fontSize: "14px" }}>
                                {group.name}
                              </Typography>
                            }
                            {...a11yProps(group)}
                          />
                          // <Button onClick={handleButtonClick(`group ${group.id}`)}>
                          //   {group.name}
                          // </Button>
                        ),
                      )}
                  </Tabs>
                </div>
                <div style={{ margin: "16px 36px" }}>
                  <TextfieldCommon
                    id="search"
                    name="search"
                    label="Search"
                    type="text"
                    style={{ width: "240px" }}
                    value={searchValue}
                    onChange={handleChangeSearch}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue ? (
                            <CloseIcon onClick={handleClearSearch} />
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            ) : (
              <DialogTitle
                id="alert-dialog-title"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {isOpenEditStockItemsModal
                  ? `Update ${initialName} Stock Item`
                  : "Add New Stock Item"}
              </DialogTitle>
            )}
          </Hidden>
          <div ref={dialogRef} style={{ overflow: "auto", maxHeight: "56vh" }}>
            {handleModal()}
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "block", width: "100%" }}>
            {/* <ApiCallLoader
              successErrorList={successErrorList}
              handleSubmitData={handleSubmitData}
              isCompleteLoad={isCompleteLoad}
              handleSubmitOutputMultiplier={handleSubmitOutputMultiplier}
              handleCreateSuppliersForStockItem={
                handleCreateSuppliersForStockItem
              }
            /> */}
            <div
              style={
                isCreated && isOpenMapProducts
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: "24px",
                      marginRight: "14px",
                    }
                  : {
                      marginRight: "16px",
                      display: "flex",
                      justifyContent: "end",
                    }
              }
            >
              {isCreated && isOpenMapProducts && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <IncrementDecrementTextboxDefault
                    handleDecrement={handleDecrement}
                    handleIncrement={handleIncrement}
                    initialOrderQty={initialOutputMultiplier}
                    setInitialOrderQty={setInitialOutputMultiplier}
                  />
                  {/* <IconButton onClick={handleDecrement}>
                    <IndeterminateCheckBoxOutlinedIcon
                      style={{
                        fontSize: "16px",
                      }}
                    />
                  </IconButton>
                  <TextfieldCommon
                    id="outputMultiplier"
                    name="outputMultiplier"
                    label=""
                    type="number"
                    style={{ width: "80px" }}
                    value={initialOutputMultiplier}
                    onChange={(event: any) =>
                      setInitialOutputMultiplier(event.target.value)
                    }
                  />{" "}
                  <IconButton onClick={handleIncrement}>
                    <AddBoxOutlinedIcon
                      style={{
                        fontSize: "16px",
                      }}
                    />
                  </IconButton> */}
                </div>
              )}
              <div>
                <Hidden xsDown>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      handleReset();
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>

                  {isCreated && !isOpenMapProducts && (
                    <Button
                      style={{ textTransform: "none" }}
                      color="secondary"
                      autoFocus
                      onClick={() => {
                        isOpenEditStockItemsModal &&
                          handleOpenEditMapProductsItemsModal(stockItemId);
                        setIsOpenMapProducts(true);
                      }}
                    >
                      {`Map products to ${name}`}
                    </Button>
                  )}
                  {isCreated && isOpenMapProducts ? (
                    <Button
                      style={{ textTransform: "none" }}
                      color="secondary"
                      autoFocus
                      disabled={checkedProductIdList.length === 0 || result}
                      onClick={handleSubmitOutputMultiplier}
                    >
                      {`${
                        checkedProductIdList.length === 0 ? "Save" : "Update"
                      }`}
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{ textTransform: "none", paddingRight: "36px" }}
                        color="secondary"
                        autoFocus
                        disabled={
                          !isOpenEditStockItemsModal
                            ? !isDirty ||
                              _.isEmpty(selectedDepartmentObj) ||
                              _.isEmpty(selectedSupplierList)
                            : handleDisable()
                        }
                        onClick={isCreated ? handleResetData : handleSubmitData}
                      >
                        {isCreated
                          ? "Add another stock items"
                          : `${isOpenEditStockItemsModal ? "Update" : "Save"}`}
                      </Button>
                    </>
                  )}
                </Hidden>
                <Hidden smUp>
                  {isCreated && !isOpenMapProducts ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{ textTransform: "none" }}
                        onClick={() => {
                          handleReset();
                        }}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ textTransform: "none" }}
                        color="secondary"
                        autoFocus
                        onClick={() => {
                          isOpenEditStockItemsModal &&
                            handleOpenEditMapProductsItemsModal(stockItemId);
                          setIsOpenMapProducts(true);
                        }}
                      >
                        {`Map products to ${name}`}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        handleReset();
                      }}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  )}
                  {isCreated && isOpenMapProducts ? (
                    <Button
                      style={{ textTransform: "none" }}
                      color="secondary"
                      disabled={checkedProductIdList.length === 0 || result}
                      autoFocus
                      onClick={handleSubmitOutputMultiplier}
                    >
                      {`${
                        checkedProductIdList.length === 0 ? "Save" : "Update"
                      }`}
                    </Button>
                  ) : isCreated ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        style={{ textTransform: "none" }}
                        color="secondary"
                        autoFocus
                        onClick={handleResetData}
                      >
                        Add another stock items
                      </Button>
                    </div>
                  ) : (
                    <Button
                      style={{ textTransform: "none", paddingRight: "36px" }}
                      color="secondary"
                      autoFocus
                      disabled={
                        !isOpenEditStockItemsModal
                          ? !isDirty ||
                            _.isEmpty(selectedDepartmentObj) ||
                            _.isEmpty(selectedSupplierList)
                          : handleDisable()
                      }
                      onClick={handleSubmitData}
                    >
                      {isOpenEditStockItemsModal ? "Update" : "Save"}
                    </Button>
                  )}
                </Hidden>
              </div>
            </div>
          </div>
        </DialogActions>
      </DialogCommonDefault>

      {isOpenSupplierAddModals && (
        <AddSupplierModal
          isOpenSupplierAddModals={isOpenSupplierAddModals}
          setIsOpenSupplierAddModals={setIsOpenSupplierAddModals}
          handleCreateNewSupplier={handleCreateNewSupplier}
        />
      )}

      {isOpenDepartmentAddModal && (
        <DialogCommonDefault
          open={isOpenDepartmentAddModal}
          setOpen={setIsOpenDepartmentAddModal}
          isOpenMapProd={false}
        >
          <DialogTitle>Add New Department</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Autocomplete
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  size={"small"}
                  id="locationSelectGlobal"
                  color="inherit"
                  options={stockGroupSelectedNode}
                  value={selectedGroupObj}
                  getOptionLabel={(option: any) => option.name || ""}
                  style={{ width: 300 }}
                  disableClearable
                  onChange={handleGroupChange}
                  classes={{ root: classes.autoComplete }}
                  popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                  renderOption={(props: any) => {
                    return (
                      <Grid
                        container
                        {...props}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          zIndex: 0,
                          height: "20px",
                          marginBottom: "4px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            className={classes.titleColor}
                          >
                            {props.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      color="inherit"
                      {...params}
                      label=""
                      placeholder="Select Department"
                      variant="outlined"
                    />
                  )}
                />
                <Button
                  style={{ textTransform: "none" }}
                  color="secondary"
                  autoFocus
                  // disabled={checkedProductIdList.length === 0 || result}
                  onClick={handleOpenGroupAddModal}
                >
                  {"Add new Group"}
                </Button>
              </Grid>
            </Grid>
            <TextfieldCommon
              id="departmentName"
              name="departmentName"
              type="text"
              label="Department Name"
              disabled={false}
              value={departmentName}
              onChange={handleChangeDepartmentName}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "none" }}
              onClick={() => {
                setIsOpenDepartmentAddModal(false);
              }}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              style={{ textTransform: "none" }}
              color="secondary"
              autoFocus
              disabled={!departmentName}
              onClick={handleCreateNewDepartment}
            >
              Save
            </Button>
          </DialogActions>
        </DialogCommonDefault>
      )}

      {isOpenGroupAddModal && (
        <DialogCommonDefault
          open={isOpenGroupAddModal}
          setOpen={setIsOpenGroupAddModal}
          isOpenMapProd={false}
        >
          <DialogTitle>Add New Group</DialogTitle>
          <DialogContent>
            <TextfieldCommon
              id="groupName"
              name="groupName"
              type="text"
              label="Group Name"
              disabled={false}
              value={groupName}
              onChange={handleChangeGroupName}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "none" }}
              onClick={() => {
                setIsOpenGroupAddModal(false);
              }}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              style={{ textTransform: "none" }}
              color="secondary"
              autoFocus
              disabled={!groupName}
              onClick={handleCreateNewGroup}
            >
              Save
            </Button>
          </DialogActions>
        </DialogCommonDefault>
      )}
    </>
  );
};

export default AddStockItemsModal;

import _ from "lodash";
import moment from "moment";

/* Returns Id list from array object.
  ex:- arrayObject = [{id: "001", label: "test1"}, {id: "002", label: "test2"}]
       return     =  ["001", "002"] */
export const getIdListFromArrayObject = (arrayObject: any) => {
  const idList = arrayObject.map((selectObject: any) => selectObject.id);
  return idList;
};

/* The ID in the array object and only the objects that match the given ID are returned as a array object.
  ex:- arrayObject = [{id: "001", label: "test1"}, {id: "002", label: "test2"}]
       fileName    = "001"
       return      = [{id: "001", label: "test1"}] */
export const getFilterListFromArrayObject = (arrayObject: any, id: any) => {
  const filterList = arrayObject.filter((itm: any) => itm.id === id);
  return filterList;
};

/* Only objects with the same ID in the array object and the same ID in a list are returned as a array object.
   arrayObject = [{id: "001", label: "test1"}, {id: "002", label: "test2"}]
   list        = ["001"]
   return      = [{id: "001", label: "test1"}]*/
export const getFilterListFromArrayObjectAndArray = (
  arrayObject: any,
  list: any,
) => {
  const filteredArrayObject = arrayObject.filter((itm: any) =>
    list.includes(itm.id),
  );
  return filteredArrayObject;
};

/* Returns true or false if the id in the array object is equal to the given id. */
export const checkArrayObjectIncludeString = (arrayObject: any, id: string) => {
  const match = arrayObject.filter((itm: any) => itm.id === id);
  if (_.isEmpty(match)) {
    return false;
  } else {
    return true;
  }
};

/* Return the label of array object  */
export const getLabelListFromArrayObjectAndArray = (
  arrayObject: any,
  list: any,
) => {
  let labelList: any = [];
  arrayObject.filter((item: any) => {
    if (list.includes(item.id)) {
      labelList.push(item.label);
    }
  });
  return labelList;
};

/* Sort numbers */
export const sortNumbers = (array: any, value: any) =>
  array.sort((a: any, b: any) => a[value] - b[value]);

/* Sort string */
export const sortStrings = (array: any, value: any) =>
  array.sort((a: any, b: any) => {
    if (value === "PayInOrOut") {
      return a[value]["props"]["label"].localeCompare(
        b[value]["props"]["label"],
      );
    }
    return a[value].localeCompare(b[value]);
  });

/* Sort date */
export const sortDates = (array: any, value: any) =>
  array.sort((a: any, b: any) => {
    const aTimestamp = moment(a[value], "MMM DD, YYYY").valueOf();
    const bTimestamp = moment(b[value], "MMM DD, YYYY").valueOf();
    return aTimestamp - bTimestamp;
  });

export const checkIncludedValue = (value: any, included: string) =>
  value.toLowerCase().includes(included.toLowerCase());

export enum ExportPdfColor {
  HeaderRowColor = "#2C3341",
  OddRowColor = "#424957",
  EvenRowColor = "#32363E",
  green = "#3A9D76",
  purple = "#895EC4",
  blueDark = "#18B5FA",
  blueLight = "#2B6DCF",
}

export enum ExportPdfColorInventory {
  HeaderRowColor = "#EEEEEE",
  OddRowColor = "#F8F8F8",
  EvenRowColor = "#EEEEEE",
  green = "#3A9D76",
  purple = "#895EC4",
  blueDark = "#18B5FA",
  blueLight = "#2B6DCF",
}

export enum ExportExcelColor {
  HeaderRowColor = "2C3341",
  OddRowColor = "424957",
  EvenRowColor = "303e5b",
  green = "3A9D76",
  purple = "895EC4",
  blueDark = "18B5FA",
  blueLight = "2B6DCF",
}

import React from "react";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";
import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import StockDepartmentInfoNode from "./StockDepartmentInfoNode";

export interface StockDepartmentHeaderProps {
  nodeData: any;
  handleChangeStockDepartmentListSorting: any;
  handleRemoveStockDepartmentListSorting: any;
  disableButton: any;
  isLoading: any;
  locationSelectorList: any;
  handleOpenEditStockDepartmentModal: any;
  orderId: any;
  setOrderId: any;
  isSort: any;
  headerName: any;
  sortingMethod: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerContainerStyle: {
      marginTop: "16px",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerItemStyle: {
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
        paddingLeft: "0px",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyleLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      placeItems: "center",
      paddingRight: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
      },
    },
  }),
);

/* This is stock department Table header page. In addition, Until the required data for the order 
   table is received from the API call, this will show the loading of the skeleton. */
const StockDepartmentHeader: React.FunctionComponent<
  StockDepartmentHeaderProps
> = ({
  nodeData,
  handleChangeStockDepartmentListSorting,
  handleRemoveStockDepartmentListSorting,
  disableButton,
  isLoading,
  locationSelectorList,
  handleOpenEditStockDepartmentModal,
  isSort,
  headerName,
  sortingMethod,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.headerContainerStyle}>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px" }}>
            <Grid item xs={3} className={classes.headerFirstItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={
                  handleChangeStockDepartmentListSorting
                }
                handleRemoveOrderListSorting={
                  handleRemoveStockDepartmentListSorting
                }
                headerName={"Location"}
                headerId={"locationId"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "locationId" ? isSort : false}
                sortingMethod={headerName === "locationId" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={
                  handleChangeStockDepartmentListSorting
                }
                handleRemoveOrderListSorting={
                  handleRemoveStockDepartmentListSorting
                }
                headerName={"Name"}
                headerId={"name"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "name" ? isSort : false}
                sortingMethod={headerName === "name" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={
                  handleChangeStockDepartmentListSorting
                }
                handleRemoveOrderListSorting={
                  handleRemoveStockDepartmentListSorting
                }
                headerName={"Created"}
                headerId={"created"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "created" ? isSort : false}
                sortingMethod={headerName === "created" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyleLastColumn}>
              <SortingMethod
                handleChangeOrderListSorting={
                  handleChangeStockDepartmentListSorting
                }
                handleRemoveOrderListSorting={
                  handleRemoveStockDepartmentListSorting
                }
                headerName={"Updated"}
                headerId={"updated"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "updated" ? isSort : false}
                sortingMethod={headerName === "updated" ? sortingMethod : ""}
              />
            </Grid>
          </Grid>
        </CardCommon>
      </div>
      <Grid container style={{ marginTop: "1px" }}>
        {!_.isEmpty(nodeData) ? (
          nodeData.map((nodeData: any) => (
            <Grid
              item
              xs={12}
              key={nodeData.id}
              style={{ placeItems: "center", marginTop: "4px" }}
            >
              <StockDepartmentInfoNode
                nodeData={nodeData}
                locationSelectorList={locationSelectorList}
                handleOpenEditStockDepartmentModal={
                  handleOpenEditStockDepartmentModal
                }
              />
            </Grid>
          ))
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No Stock Department available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(StockDepartmentHeader);

import {
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";

import { CustomTheme } from "../../../../../../types/customTheme";
import TextfieldCommon from "../../../../../../components/textField/TextfieldCommon";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    "& .MuiTabs-root": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 280px)",
      },
    },
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
}));

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export interface DesktopNavProps {
  allStockOrderItemNodeInitial: any;
  activeGroupId: any;
  handleChange: any;
  searchValue: any;
  handleChangeSearchUsingSearchTextbox: any;
  handleClearSearch: any;
  isDisable: any;
}

/**
 * This component represents the desktop navigation bar for selecting departments and searching.
 * It contains tabs for different groups and a search input field.
 */
const DesktopNav: React.FunctionComponent<DesktopNavProps> = ({
  allStockOrderItemNodeInitial,
  activeGroupId,
  handleChange,
  searchValue,
  handleChangeSearchUsingSearchTextbox,
  handleClearSearch,
  isDisable,
}) => {
  const classes = useStyles();

  return (
    <div style={{ display: "block" }}>
      <div>
        <Tabs
          classes={{
            root:
              allStockOrderItemNodeInitial.length === 1
                ? classes.rootCenterTabs
                : classes.root,
          }}
          value={activeGroupId}
          onChange={handleChange}
          variant="scrollable"
          selectionFollowsFocus
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable groups list"
        >
          {!_.isEmpty(allStockOrderItemNodeInitial) &&
            allStockOrderItemNodeInitial.map((group: any, idx: number) => (
              <Tab
                key={String(idx)}
                disabled={isDisable}
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    {group.name}
                  </Typography>
                }
                {...a11yProps(group)}
              />
            ))}
        </Tabs>
      </div>
      <div style={{ margin: "16px 36px" }}>
        {/* Search input */}
        <TextfieldCommon
          id="search"
          name="search"
          label="Search"
          type="text"
          style={{ width: "240px" }}
          value={searchValue}
          disabled={isDisable}
          onChange={handleChangeSearchUsingSearchTextbox}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchValue ? (
                  <CloseIcon onClick={handleClearSearch} />
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default DesktopNav;

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BookmarkBorder from "@material-ui/icons/BookmarkBorder";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { Icon } from "@iconify/react";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { getLocationBasicInfo } from "../../../services/locationApp/storeService";
import { CustomTheme } from "../../../types/customTheme";
import ViewListIcon from '@material-ui/icons/ViewList';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export interface PageHeaderProps {}

/* This component displays the header for each page. */
const PageHeader: React.FunctionComponent<PageHeaderProps> = () => {
  const [locationData, setLocationData] = useState<any>();

  const match: any = useRouteMatch();

  const fetchBasicInfo = async () => {
    try {
      const res = await getLocationBasicInfo(match.params.locationId);
      setLocationData(res.data.data);
    } catch (error) {}
  };
  
  useEffect(() => {
    fetchBasicInfo();
  }, [document.title]);

  const handleIcons = (title: string) => {
    if (title === " Dashboard") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          Dashboard
        </span>
      );
    }
    if (title === " Sale Information") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          list_alt
        </span>
      );
    }
    if (title === " Shift Summary") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          description
        </span>
      );
    }
    if (title === " Item Sales") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          format_list_numbered
        </span>
      );
    }
    if (title === " Daily Sales Report") {
      return <DateRangeOutlinedIcon style={{ fontSize: "36px" }} />;
    }
    if (title === " Pay In Out") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          swap_horiz
        </span>
      );
    }
    if (title === " Payment Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          wallet
        </span>
      );
    }
    if (title === " Void Summary") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          assured_workload
        </span>
      );
    }
    if (title === " Sale Cancel") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          cancel
        </span>
      );
    }
    if (title === " Void Transaction") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          delete_forever
        </span>
      );
    }
    if (window.location.href.includes("/presto-locations/basic-info")) {
      return <StorefrontIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-locations/opening-times")) {
      return <AccessTimeIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-locations")) {
      return <DashboardIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/orders")) {
      return <BookmarkBorder style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/discounts")) {
      return <Icon icon="tabler:discount-2" height="36" width="36" />;
    }
    if (window.location.href.includes("/eatpresto/delivery-info")) {
      return <LocalShippingIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/theme")) {
      return <ColorLensIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/banners")) {
      return <ArtTrackIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto/closureDates")) {
      return <QueryBuilderIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/eatpresto")) {
      return <DashboardIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-task/task")) {
      return <ListAltIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-task")) {
      return <DashboardIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-menus/menu")) {
      return <MenuBookIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/presto-menus")) {
      return <DashboardIcon style={{ fontSize: "36px" }} />;
    }
    if (window.location.href.includes("/stockItems")) {
      return <BusinessCenterIcon style={{ fontSize: "36px" }} />;
    } 
    if (window.location.href.includes("admin/group")) {
      return <GroupAddIcon style={{ fontSize: "36px" }} />;
    } 
    if (window.location.href.includes("admin/department")) {
      return <PersonIcon style={{ fontSize: "36px" }} />;
    } 
    if (window.location.href.includes("admin/supplier")) {
      return <PersonAddIcon style={{ fontSize: "36px" }} />;
    } 
    if (window.location.href.includes("/stockOrders")) {
      return <ViewListIcon style={{ fontSize: "36px" }} />;
    } 
    if (window.location.href.includes("/stockTake")) {
      return <ViewListIcon style={{ fontSize: "36px" }} />;
    } 
  };

  const handleTitle = (title: string) => {
    if (title === " Sale Information") {
      return "Sale Transactions";
    }
    if (title === " Pay In Out") {
      return "Paid In-Out Report";
    }
    if (title === " Payment Report") {
      return "Payment Types Report";
    }
    if (title === " Void Summary") {
      return "VAT Summary Report";
    }
    if (title === " Sale Cancel") {
      return "Cancelled Sales Transactions";
    }
    if (title === " Void Transaction") {
      return "Voided Transactions";
    }
    if (window.location.href.includes("/presto-locations/opening-times")) {
      return "Opening Times";
    }
    if (window.location.href.includes("/presto-locations/basic-info")) {
      return "Business Info";
    }
    if (window.location.href.includes("/presto-locations")) {
      return "Dashboard";
    }
    if (window.location.href.includes("/eatpresto/orders")) {
      return "Eat Presto Online Orders";
    }
    if (window.location.href.includes("/eatpresto/discounts")) {
      return "Discounts";
    }
    if (window.location.href.includes("/eatpresto/delivery-info")) {
      return "Collection, Delivery & Dine-In Information";
    }
    if (window.location.href.includes("/eatpresto/theme")) {
      return "Online Ordering Theme";
    }
    if (window.location.href.includes("/eatpresto/banners")) {
      return "Banners";
    }
    if (window.location.href.includes("/eatpresto/closureDates")) {
      return "Closure Dates";
    }
    if (window.location.href.includes("/eatpresto")) {
      return "Dashboard";
    }
    if (window.location.href.includes("/presto-task/task")) {
      return "Task Information";
    }
    if (window.location.href.includes("/presto-task")) {
      return "Dashboard";
    }
    if (window.location.href.includes("/presto-menus/menu")) {
      return "Menu Information";
    }
    if (window.location.href.includes("/presto-menus")) {
      return "Dashboard";
    } else if (window.location.href.includes("/stockItems")) {
      return "Stock Items";
    } else if (window.location.href.includes("/admin/group")) {
      return "Stock Groups";
    } else if (window.location.href.includes("/admin/department")) {
      return "Stock Departments";
    }  else if (window.location.href.includes("/admin/supplier")) {
      return "Stock Departments";
    } else if (window.location.href.includes("/stockOrders")) {
      return "Stock Orders";
    } else if (window.location.href.includes("/stockTake")) {
      return "Stock Take";
    }
    return title;
  };
 
  const handleSubTitle = (app: string, title: string) => {
    if (app === "Sale " && title === " Dashboard") {
      return `Sale Overview-${locationData?.businessDisplayName}`;
    }
    if (title === " Sale Information") {
      return `Browse sales transactions-${locationData?.businessDisplayName}`;
    }
    if (title === " Shift Summary") {
      return `Browse shift reports-${locationData?.businessDisplayName}`;
    }
    if (title === " Item Sales") {
      return `Generate itemized sales report-${locationData?.businessDisplayName}`;
    }
    if (title === " Daily Sales Report") {
      return `Generate Daily Sales report-${locationData?.businessDisplayName}`;
    }
    if (title === " Pay In Out") {
      return `Generate paid in-out report-${locationData?.businessDisplayName}`;
    }
    if (title === " Payment Report") {
      return `Generate payment types report-${locationData?.businessDisplayName}`;
    }
    if (title === " Void Summary") {
      return `Generate VAT summary report-${locationData?.businessDisplayName}`;
    }
    if (title === " Sale Cancel") {
      return `Browse cancelled sales transactions-${locationData?.businessDisplayName}`;
    }
    if (title === " Void Transaction") {
      return `Browse voided transactions-${locationData?.businessDisplayName}`;
    }
    if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations/basic-info")
    ) {
      return `Manage business information-${locationData?.businessDisplayName}`;
    }
    if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations/opening-times")
    ) {
      return `Manage opening times-${locationData?.businessDisplayName}`;
    }
    if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations")
    ) {
      return `Location Overview-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/orders")) {
      return `Browse online orders-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/discounts")) {
      return `Manage discounts-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/delivery-info")) {
      return `Manage collection, delivery & dine-in information-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/theme")) {
      return `Manage theme colours-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/banners")) {
      return `Manage promo and information banners-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto/closureDates")) {
      return `Manage irregular business closure dates-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/eatpresto")) {
      return `Eat Presto online ordering-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-task/task")) {
      return `Eat Presto online ordering-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-task")) {
      return `Task Overview-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-menus/menu")) {
      return `Menu Overview-${locationData?.businessDisplayName}`;
    }
    if (window.location.href.includes("/presto-menus")) {
      return `Menu Overview-${locationData?.businessDisplayName}`; 
    } else if (window.location.href.includes("/stockItems")) {
      return  `Stock Items Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockOrders")) {
      return  `Stock Order Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/group")) {
      return  `Stock Group Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/department")) {
      return  `Stock Department Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/supplier")) {
      return  `Supplier Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockTake")) {
      return  `Stock Take Overview-${locationData?.businessDisplayName}`;
    }
  };
  const theme: CustomTheme = useTheme();
  return (
    <Grid container>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Typography
          style={{
            color: theme.palette.custom.orange.contrastText,
            marginTop: "2px",
          }}
        >
          {handleIcons(document.title.split("-")[1])}
        </Typography>
        <div style={{ marginLeft: "8px" }}>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              color: theme.palette.custom.orange.contrastText,
              lineHeight: "20px",
            }}
          >
            {handleTitle(document.title.split("-")[1])}
          </div>
          <div
            style={{
              color: theme.palette.custom.orange.contrastText,
              fontSize: "10px",
              display: "flex",
              fontWeight: "lighter",
            }}
          >
            {handleSubTitle(
              document.title.split("-")[0],
              document.title.split("-")[1],
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default PageHeader;

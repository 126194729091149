import Authorities, {
  authoritiesMapping,
  Authority,
} from "../auth/authorities";
import _ from "lodash";
import rolesAuthoritiesMapping from "../auth/rolesAuthoritiesMapping";
import { getLocalStore } from "./store/localStore";

/* Obtaining the authority relevant to the role of the user.
Its role is on the list and the return authority is on the list. */
export const getAuthoritiesByRoles = (serverLevelRoles: Array<any>) => {
  try {
    return serverLevelRoles.map((role) => rolesAuthoritiesMapping[role]).flat();
  } catch (error) {
    return [];
  }
};

export const getAuthoritiesByServerLevelAuthorities = (
  serverLevelAuthorities: Array<any>,
) => {
  
  try {
    let authorities: Array<string> = [];
    if (_.isEmpty(serverLevelAuthorities)) return [];
    serverLevelAuthorities.forEach((authority) => {
      for (const clientAuthority in authoritiesMapping) {
        if (authoritiesMapping[clientAuthority].includes(authority)) {
          authorities.push(Authorities[clientAuthority]);
        }
      }
    });
    
    return authorities;
  } catch (error) {
    return [];
  }
};

/* Obtaining the authority relevant to the role of the user.
Its role is as a string. The return authority is as a string. */
export const getAuthoritiesByRolesAuthoritiesString = (
  rolesAndAuthorities: string,
) => {
  const rolesAndAuthoritiesList = rolesAndAuthorities.split(",");
  const { true: serverLevelRoles, false: serverLevelAuthorities } = _.groupBy(
    rolesAndAuthoritiesList,
    (roleAuthority: string) => roleAuthority.startsWith("ROLE_"),
  );
  
  const authoritiesByRoles = getAuthoritiesByRoles(serverLevelRoles);
  const authoritiesByServerLevelAuthorities =
    getAuthoritiesByServerLevelAuthorities(serverLevelAuthorities);
  const authSet = new Set(
    [...(authoritiesByRoles || []), authoritiesByServerLevelAuthorities].flat(),
  );
  return [...authSet];
};

/* Put all the authorities on a list. */
export const getAutoAuthoritiesByAuthorityLevel = (authority: string) => {
  const [authorityComponent, authorityLevel] = authority.split(":");
  switch (authorityLevel) {
    case "CREATE":
      return [
        `${authorityComponent}:READ`,
        `${authorityComponent}:UPDATE`,
        `${authorityComponent}:DELETE`,
        `${authorityComponent}:CREATE`,
      ];

    case "DELETE":
      return [
        `${authorityComponent}:READ`,
        `${authorityComponent}:UPDATE`,
        `${authorityComponent}:DELETE`,
      ];

    case "UPDATE":
      return [`${authorityComponent}:READ`, `${authorityComponent}:UPDATE`];
    case "READ":
      return [`${authorityComponent}:READ`];
  }
};

export const getGrantedAuthoritiesByAuthorityList = (
  permissions: Array<string>,
) => [
  ...new Set(
    permissions
      .map((permission: string) =>
        getAutoAuthoritiesByAuthorityLevel(permission),
      )
      .flat(),
  ),
];

/* Get all authorities list */
export function getGrantedAuthorities() {
  try {
    const rolesAuthoritiesString = getLocalStore("authorities");
    let grantedAuthorities = getAuthoritiesByRolesAuthoritiesString(
      rolesAuthoritiesString,
    );
    grantedAuthorities =
      getGrantedAuthoritiesByAuthorityList(grantedAuthorities);
    return grantedAuthorities;
  } catch (error) {
    return [];
  }
}

/* Check that the authority here is matching the authority given to the user. */
export const getIsAuthorized = (requiredAuthority: Authority) => {
  const grantedAuthorities = getGrantedAuthorities();
  return grantedAuthorities.includes(requiredAuthority);
};

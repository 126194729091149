import React, { useEffect, useState } from "react";
import DialogCommon from "../dialogs/DialogCommon";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import ValidationMessage from "../validation/ValidationMessage";
import TextfieldCommon from "../textField/TextfieldCommon";
import { useForm } from "react-hook-form";
import { EMAIL_PATTERN, INTEGER_NUMBER_PATTERN, PHONE_NUMBER_PATTERN, UK_PHONE_NUMBER_PATTERN } from "../../utils/consts";
import _ from "lodash";
import CardCommon from "../card/CardCommon";
import DialogCommonDefault from "../dialogs/DialogCommonDefault";

interface FormData {
  name: string;
  address: string;
  contactNo: string;
  email: string;
}

export interface paymentReportProps {
  isOpenSupplierAddModals: any;
  setIsOpenSupplierAddModals: any;
  handleCreateNewSupplier: any
}
/* Set the filter, sale title, sale sub title, and table using filter details */
const AddSupplierModal: React.FunctionComponent<paymentReportProps> = ({
  isOpenSupplierAddModals,
  setIsOpenSupplierAddModals,
  handleCreateNewSupplier
}) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    clearErrors,
    watch,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      address: "",
      contactNo: "",
      email: "",
    },
  });
  const handleSubmitSupplier = handleSubmit((data) => {
    handleCreateNewSupplier(data)
  });
  return (
    <DialogCommonDefault
      open={isOpenSupplierAddModals}
      setOpen={setIsOpenSupplierAddModals}
      isOpenMapProd={false}
    >
      <DialogTitle>Add New Supplier</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextfieldCommon
              id="name"
              name="name"
              type="text"
              label="Supplier Name"
              disabled={false}
              inputRef={register({
                required: "Name is required.",
              })}
            />
            {errors.name ? (
              <ValidationMessage message={errors.name.message} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldCommon
              id="address"
              name="address"
              type="text"
              label="Address"
              inputRef={register({
                required: "Address is required.",
              })}
            />
            {errors.address ? (
              <ValidationMessage message={errors.address.message} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldCommon
              id="contactNo"
              name="contactNo"
              type="text"
              label="Contact No"
              inputRef={register({
                required: true,
                pattern: PHONE_NUMBER_PATTERN,
              })}
            />
            {errors.contactNo ? (
              <ValidationMessage  message={
                errors.contactNo.type === "required"
                  ? "Contact No is required"
                  : "Please enter a valid Contact No"
              } />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextfieldCommon
              id="email"
              name="email"
              type="email"
              label="Email"
              inputRef={register({
                required: true,
                pattern: EMAIL_PATTERN,
              })}
            />
            {errors.email ? (
              <ValidationMessage
              message={ errors.email && "Please enter a valid email address"}
            />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ textTransform: "none" }}
          onClick={() => {
            setIsOpenSupplierAddModals(false);
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          style={{ textTransform: "none" }}
          color="secondary"
          autoFocus
          disabled={!errors}
          onClick={handleSubmitSupplier}
        >
          Save
        </Button>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default AddSupplierModal;

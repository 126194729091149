import * as React from "react";
import { Route, Switch } from "react-router-dom";
// import ManageAccounts from "../pages/ManageAccounts";
import ManageApps from "../pages/ManageApps";
import SignInPage from "../pages/SignInPage";

// const PAGES = [
//   { id: "HOME", title: "Home", path: "/home" },
//   { id: "BASIC_INFO", title: "Basic Info", path: "/basic-info" },
//   { id: "OPENING_TIMES", title: "Opening times", path: "/opening-times" },
//   // {
//   //   id: "SETTINGS",
//   //   title: "Settings",
//   //   path: "/",
//   //   childRoutes: [
//   //     {
//   //       id: "SETTINGS_ORDER",
//   //       title: "Order Settings",
//   //       path: "/order-settings",
//   //     },
//   //     {
//   //       id: "BASIC_INFO",
//   //       title: "Basic Info",
//   //       path: "/basic-info",
//   //     },
//   //   ],
//   // },
// ];

export interface AccountsAppRoutesProps {}

const AccountsAppRoutes: React.FunctionComponent<AccountsAppRoutesProps> = () => {
  return (
    <Switch>
      <Route path="/accounts/sign-in" component={SignInPage} />
      {/* <Route path="/accounts/locations" component={ManageAccounts} /> */}
      <Route path="/accounts/apps" component={ManageApps} />
    </Switch>
  );
};

export default AccountsAppRoutes;

import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import {
  createStockGroups,
  updateStockGroups,
} from "../../../../services/inventory/stockItems";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../utils/consts";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";

export interface stockGroupModalProps {
  isOpenStockGroupModal: any;
  setIsOpenStockGroupModal: any;
  setSuccess: any;
  setError: any;
  isOpenStockGroupEditModal: any;
  setGroupName: any;
  groupName: any;
  version: any;
  groupId: any;
  groupNameInitial: any;
  handleGetStockGroupList: any;
}

/**
 * This component provides a modal interface for adding or editing stock groups.
 * It manages the form inputs, handles user interactions, and communicates with APIs
 * to create or update stock groups. The component displays a dialog box where users
 * can enter a new group name or modify an existing one. The form submission triggers
 * the appropriate API call based on whether the modal is in "add" or "edit" mode.
 */
const AddStockGroupModal: React.FunctionComponent<stockGroupModalProps> = ({
  isOpenStockGroupModal,
  setIsOpenStockGroupModal,
  setSuccess,
  setError,
  isOpenStockGroupEditModal,
  setGroupName,
  groupName,
  version,
  groupId,
  groupNameInitial,
  handleGetStockGroupList,
}) => {
  const match: any = useRouteMatch();

  /**
   * Handler for changing the group name input.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleChangeGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };

  /**
   * Handler for creating a new group.
   * This function submits the new group details to the API,
   * handles success and error cases, and updates relevant state.
   */
  const handleCreateNewGroup = async () => {
    // Prepare form data for creating a new group
    const formData = {
      name: groupName,
      locationId: match.params.locationId,
    };
    try {
      // Call the API to create a new stock group
      await createStockGroups(match.params.locationId, formData);

      // Get the stock department list
      handleGetStockGroupList();

      // Reset the selected group object, set success message, and close the modal
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenStockGroupModal(false);
    } catch (err) {
      // If an error occurs during API call, set error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handler for updating a group.
   * This function submits the updated group details to the API,
   * handles success and error cases, and updates relevant state.
   */
  const handleUpdateGroup = async () => {
    const formData = {
      id: groupId,
      name: groupName,
      locationId: match.params.locationId,
      version: version,
    };

    try {
      // Call the API to update the stock department
      await updateStockGroups(match.params.locationId, formData);

      // Update the stock group list
      handleGetStockGroupList();

      // Reset the selected group object, set success message, and close the modal
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenStockGroupModal(false);
    } catch (err) {
      // If an error occurs during API call, set error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handler for submitting the group form.
   * This function determines whether to create a new group or update an existing one,
   * and then invokes the appropriate handler function accordingly.
   */
  const handleSubmit = () => {
    if (isOpenStockGroupEditModal) {
      // If in edit mode, call the function to update the group
      handleUpdateGroup();
    } else {
      // If not in edit mode, call the function to create a new group
      handleCreateNewGroup();
    }
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpenStockGroupModal}
        setOpen={setIsOpenStockGroupModal}
        isOpenMapProd={false}
      >
        <DialogTitle>
          {isOpenStockGroupEditModal
            ? `Update ${groupNameInitial} Group`
            : "Add New Group"}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextfieldCommon
                id="groupName"
                name="groupName"
                type="text"
                label="Group Name"
                disabled={false}
                value={groupName}
                onChange={handleChangeGroupName}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ textTransform: "none" }}
            onClick={() => {
              setIsOpenStockGroupModal(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            style={{ textTransform: "none" }}
            color="secondary"
            autoFocus
            disabled={!groupName || groupNameInitial === groupName}
            onClick={handleSubmit}
          >
            {isOpenStockGroupEditModal ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default AddStockGroupModal;

import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import PostAddIcon from "@material-ui/icons/PostAdd";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Theme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { CapitalizeFirstLetter } from "../../../../utils/ReplaceIcon";
import {
  convertDateTimeFormat,
  convertDateTimeFormatInventory,
} from "../../../../utils/ConvertDateTimeFormat";

interface styleProps {
  statuss: any;
}

const getColorByStatus = (statuss: any) => {
  switch (statuss.toLowerCase()) {
    case "ordered":
      return "#39B3D1";
    case "delivered":
      return "#5BB995";
    case "draft":
      return "#FFB400";
    default:
      return "#FFB400";
  }
};

const useStyles = makeStyles<any, styleProps>((theme: Theme) =>
  createStyles({
    gridField: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "44px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        minHeight: "0px",
        justifyContent: "center",
        marginTop: "6px",
      },
    },
    iconSelected: {
      color: theme.palette.custom.green.contrastText,
    },
    gridFieldSelected: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "11px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "44px",
      color: theme.palette.custom.green.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        minHeight: "0px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "44px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        minHeight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldLastColumnSelected: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9px",
      color: theme.palette.custom.green.contrastText,
      marginTop: "2px",
      marginBottom: "8px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "44px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        minHeight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldLastColumnDisplayView: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      paddingLeft: "16px",
      textTransform: "none",
      minHeight: "44px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        minHeight: "0px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    gridFieldFirstColumnSelected: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      paddingLeft: "16px",
      textTransform: "none",
      minHeight: "44px",
      color: theme.palette.custom.green.contrastText,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        minHeight: "0px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    detailsDropDownIcon: {
      color: theme.palette.custom.orange.contrastText,
    },
    detailsDropDownIconSelected: {
      color: theme.palette.custom.green.contrastText,
    },
    status: {
      marginRight: "52px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
      },
    },
    statusColor: {
      height: "100%",
      width: "5px",
      borderRadius: "10px",
      backgroundColor: ({ statuss }) => getColorByStatus(statuss),
      marginRight: "4px",
    },
  }),
);

export interface DiscountInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  handleOpenAddStockOrderItemModal: any;
  supplierNode: any;
  handleOpenStockDeliveryItemModal: any
}

/* This component creates the data for the table using the data received from the API call.  */
const StockItemInfoNode: React.FunctionComponent<DiscountInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  handleOpenAddStockOrderItemModal,
  supplierNode,
  handleOpenStockDeliveryItemModal
}) => {
  const [suppliedId, setSuppliedId] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [status, setStatus] = useState("");
  const [statuss, setStatuss] = useState("");
  const [createdTime, setCreatedTime] = useState("");
  const [deliveredTime, setDeliveredTime] = useState("-");
  const [orderedTime, setOrderedTime] = useState("-");
  const [orderId, setOrderId] = useState("");

  /* Update states after getting the order information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { supplierId, created, id, deliveredOn, status, orderedOn } =
      nodeValues;

    setOrderId(id);
    setSuppliedId(supplierId.id);
    const stat = status.toLowerCase() === "draft" ? "Pending": status
    setStatus(stat);
    setStatuss(status)
    console.log("sdfsfasfasfasd")
    setCreatedTime(convertDateTimeFormatInventory(created));
    if (deliveredOn && status === "delivered") {
      setDeliveredTime(convertDateTimeFormatInventory(deliveredOn));
    }
    if (orderedOn && (status === "delivered" || status === "ordered")) {
      setOrderedTime(convertDateTimeFormatInventory(orderedOn));
    }

    // Get the location name using locationId
    const supplierDetails = getFilterListFromArrayObject(
      supplierNode,
      supplierId.id,
    );
    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(supplierDetails)) {
      setSupplierName(supplierDetails[0].name);
    } else {
      // If there is no description of the location, the state will be entered as 'Unknown location'.
      setSupplierName("Unknown supplier");
    }
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /**
   * This function renders the status of the stock item based on the given status value.
   * If the status is "draft", it renders the status in a plain format.
   * Otherwise, it renders the status using a class to apply custom styling.
   */
  const handleStatus = () => {
    if (status.toLowerCase() === "draft") {
      // If the status is "draft", render it in plain format
      return <div>{CapitalizeFirstLetter(status)}</div>;
    } else {
      // If the status is not "draft", apply custom styling using a class
      return (
        <div className={classes.status}>{CapitalizeFirstLetter(status)}</div>
      );
    }
  };

  console.log("safdafasd43fasfaasd", nodeData, supplierNode);
  const maxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles({ statuss });

  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Button
          style={{ width: "100%", margin: "0px", padding: "0px", textTransform: "none" }}
          onClick={(event) =>
            statuss.toLowerCase() === "draft" ?
            handleOpenAddStockOrderItemModal(orderId, suppliedId, nodeData, event) :
            handleOpenStockDeliveryItemModal(orderId, suppliedId, nodeData, event)
          }
        >
          <Grid container>
            <Hidden smDown>
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={3}
                className={classes.gridFieldFirstColumn}
              >
                {CapitalizeFirstLetter(supplierName)}
              </Grid>
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={2}
                className={classes.gridField}
              >
                {createdTime}
              </Grid>
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={2}
                className={classes.gridField}
              >
                {orderedTime}
              </Grid>
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={2}
                className={classes.gridField}
              >
                {deliveredTime}
              </Grid>
              <Grid item xs={12} md={3} className={classes.gridFieldLastColumn}>
                <Hidden smDown>
                  <div style={{ marginRight: "4px" }}>{handleStatus()}</div>
                  <Box className={classes.statusColor}></Box>
                </Hidden>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid style={{ textAlign: "left", display: "flex" }} item xs={12}>
                <div style={{ minWidth: "calc(100vw - 60px)" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ marginTop: "8px" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {CapitalizeFirstLetter(supplierName)}{" "}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {createdTime}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        {orderedTime}{" "}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        {deliveredTime}{" "}
                      </div>
                      <div
                        style={{
                          marginBottom: "8px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {handleStatus()}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "40px",
                  }}
                >
                  <Box className={classes.statusColor}></Box>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Button>
      </CardCommon>
    </>
  );
};

export default withAuthority(StockItemInfoNode, Authorities.ORDERS_READ);

import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { useTheme } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import ItemsReportInfoNode from "./ItemsReportInfoNode";
import { salesItemsFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import PageHeader from "../../../../components/common/PageHeader/PageHeader";
import { CustomTheme } from "../../../../types/customTheme";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { fetchAllItemsReportInfo } from "../../../../services/salesApp/itemsReportService";

export interface paymentReportProps {}

/* Get the sale item details using API call and get the location information */
const SaleItemsReport: React.FunctionComponent<paymentReportProps> = () => {
  const [itemsReportNodeList, setItemsReportNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /* Get the sale items details */
  const getSaleItemReportInfo = async (filter: any, basedOnShift: boolean) => {
    try {
      const res = await fetchAllItemsReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setItemsReportNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get location details. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Item Sales";
    getAllFilterLocation("");
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsOpenSkeletonLoading(true);
    getSaleItemReportInfo(filterData, basedOnShift);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const theme: CustomTheme = useTheme();

  return (
    <>
      <SaleReport
        handleFilterData={handleFilterData}
        locationSelectorList={locationSelectorList}
        handleLocationSelectorTypingList={handleLocationSelectorTypingList}
        setFilterDetails={setFilterDetails}
        availableFilter={salesItemsFilterObject}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        nodeList={itemsReportNodeList}
        filterDetails={filterDetails}
        title={""}
        isChart={false}
        isGetLocationInfo={isGetLocationInfo}
        isSaleItem={true}
        locationSelectedList={locationSelectedList}
        setLocationSelectedList={setLocationSelectedList}
        openFilterCard={openFilterCard}
        setOpenFilterCard={setOpenFilterCard}
        handleOnClickText={handleOnClickText}
        topic="Sales Items Report"
        isReport={true}
      >
        <ItemsReportInfoNode
          itemsReportNodeList={itemsReportNodeList}
          filterDetails={filterDetails}
          locationSelectedList={locationSelectedList}
          handleOnClickText={handleOnClickText}
        />
      </SaleReport>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(SaleItemsReport, Authorities.DASHBOARD_READ);

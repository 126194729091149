import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import _ from "lodash";
import { fetchAllSalesInfo } from "../../../../services/salesApp/salesService";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { payInOutListFilterObject } from "../../../../utils/consts/list";
import { Box, useTheme } from "@material-ui/core";
import PayInOutInfoNode from "./PayInOutInfoNode";
import { CustomTheme } from "../../../../types/customTheme";
import SaleReport from "../../../../components/common/SaleReport";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";

export interface SalesProps {}

/* Get the pay in/out details using API call and get the location information */
const PayInOut: React.FunctionComponent<SalesProps> = () => {
  const [salesNodeList, setSalesNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [pageSize, setPageSize] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [isOpenNoSalesFoundCard, setIsOpenNoSalesFoundCard] = useState(true);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /* Get Pay in out list using query params */
  const getSaleFilterInfo = async (
    pageSize: any,
    currentPage: any,
    filter: any,
  ) => {
    setPageSize(pageSize);
    setCurrentPage(currentPage);
    try {
      const res = await fetchAllSalesInfo(
        idToken,
        match.params.locationId,
        pageSize,
        currentPage,
        filter,
      );
      setSalesNodeList(res.data.data);
      setIsOpenNoSalesFoundCard(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get location details. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Pay In Out";
    getAllFilterLocation("");
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any) => {
    setIsOpenNoSalesFoundCard(true);
    getSaleFilterInfo(pageSize, currentPage, filterData);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const theme: CustomTheme = useTheme();
  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={payInOutListFilterObject}
          isOpenSkeletonLoading={isOpenNoSalesFoundCard}
          nodeList={salesNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          payInOut={true}
          isUserGet={false}
          filterTitle="PayInOut summary report for"
          topic="PayInOut Summary"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
        >
          {" "}
          <PayInOutInfoNode
            nodeData={salesNodeList}
            locationSelectorList={locationSelectorList}
            filterDetails={filterDetails}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
          />
        </SaleReport>
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(PayInOut, Authorities.DASHBOARD_READ);

import React, { useEffect, useState } from "react";
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";

import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import StockItemInfoNode from "./StockItemInfoNode";

export interface StockItemsHeaderProps {
  isLoading: boolean;
  locationSelectorList: any;
  handleOpenStockLevelModal: (
    stockId: string,
    stockLevel: any,
    version: any,
    event?: React.MouseEvent<HTMLLIElement>,
  ) => void;
  registerElementWithTitle: (title: any, elementRef: any) => void;
  stockItemGroupDepartmentNodeInSelector: any[];
  activeGroupId: number;
  stockLevelNode: any[];
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerItemStyle: {
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
        paddingLeft: "0px",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyleLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      placeItems: "center",
      paddingRight: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
      },
    },
  }),
);

/** This React component represents the header and content for a stock items table.
 * It displays a list of stock items grouped by departments.
 */
const StockItemsHeader: React.FunctionComponent<StockItemsHeaderProps> = ({
  isLoading,
  locationSelectorList,
  handleOpenStockLevelModal,
  registerElementWithTitle,
  stockItemGroupDepartmentNodeInSelector,
  activeGroupId,
  stockLevelNode,
}) => {
  const [stockLevelObj, setStockLevelObj] = useState<any>({});

  /**
   * This useEffect is responsible for processing stock level data from the stockLevelNode array
   * and storing it in the stockLevelObj state variable. It ensures that the stockLevelObj is updated
   * whenever there are changes in the stockItemGroupDepartmentNodeInSelector or stockLevelNode.
   */
  useEffect(() => {
    // Initialize an empty object to store stock level data
    const stockLevelObject: any = {};
  
    // Check if the stockLevelNode array is not empty
    if (!_.isEmpty(stockLevelNode)) {
      // Loop through each item in the stockLevelNode array
      stockLevelNode.forEach((data: any) => {
        // Create an object entry with the stockItemId.id as the key
        // and an object containing id and data as the value
        stockLevelObject[data.stockItemId.id] = { id: data.stockItemId.id, data: data };
      });
    }
  
    // Set the stockLevelObj state with the created stockLevelObject
    setStockLevelObj(stockLevelObject);
  }, [stockItemGroupDepartmentNodeInSelector, stockLevelNode]);
  

  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <>
      <div
        style={matchesLg ? { marginTop: "220px" } : { marginTop: "160px" }}
      ></div>
      <Hidden smDown>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px" }}>
            <Grid item xs={2} className={classes.headerFirstItemStyle}>
              <SortingMethod
                headerName={"Department"}
                headerId={""}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Location"}
                headerId={"primaryLocationId"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"name"}
                headerId={"name"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Display Suffix"}
                headerId={"displaySuffix"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={1} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Stock Level"}
                headerId={"displaySuffix"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={1} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Input Suffix"}
                headerId={"inputSuffix"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyleLastColumn}>
              <SortingMethod
                headerName={"Input Multiplier"}
                headerId={"inputMultiplier"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
          </Grid>
        </CardCommon>
      </Hidden>
      <Grid container style={{ marginTop: "1px" }}>
        {!_.isEmpty(stockItemGroupDepartmentNodeInSelector) ? (
          stockItemGroupDepartmentNodeInSelector.map(
            (department: any, index: number) =>
              activeGroupId === index &&
              department.stockDepartmentId.map((data: any) =>
                data.item.map((item: any) => (
                  <Grid item xs={12}>
                    <div
                      ref={(ref) => registerElementWithTitle(data.id, ref)}
                      id={data.name}
                      style={{ position: "sticky", zIndex: 0 }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ placeItems: "center", marginTop: "4px" }}
                      >
                        <StockItemInfoNode
                          nodeData={item}
                          locationSelectorList={locationSelectorList}
                          handleOpenStockLevelModal={handleOpenStockLevelModal}
                          departmentName={data}
                          stockLevelObj={stockLevelObj}
                        />
                      </Grid>
                    </div>
                  </Grid>
                )),
              ),
          )
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No Stock Items available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(StockItemsHeader);

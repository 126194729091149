import React from "react";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";

import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import StockGroupInfoNode from "./SupplierInfoNode";

export interface StockGroupHeaderProps {
  nodeData: any;
  handleChangeSupplierListSorting: any;
  handleRemoveSupplierListSorting: any;
  disableButton: any;
  isLoading: any;
  handleOpenEditSupplierModal: any;
  orderId: any;
  setOrderId: any;
  isSort: any
  headerName: any;
  sortingMethod: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerContainerStyle: {
      marginTop: "16px",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerItemStyle: {
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
        paddingLeft: "0px",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyleLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      placeItems: "center",
      paddingRight: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
      },
    },
  }),
);

/* This is Supplier header page. In addition, Until the required data for the supplier 
   table is received from the API call, this will show the loading of the skeleton. */
const StockGroupHeader: React.FunctionComponent<StockGroupHeaderProps> = ({
  nodeData,
  handleChangeSupplierListSorting,
  handleRemoveSupplierListSorting,
  disableButton,
  isLoading,
  handleOpenEditSupplierModal,
  isSort,
  headerName,
  sortingMethod,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.headerContainerStyle}>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px" }}>
            <Grid item xs={3} className={classes.headerFirstItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeSupplierListSorting}
                handleRemoveOrderListSorting={handleRemoveSupplierListSorting}
                headerName={"Name"}
                headerId={"name"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "name" ? isSort : false}
                sortingMethod={headerName === "name" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeSupplierListSorting}
                handleRemoveOrderListSorting={handleRemoveSupplierListSorting}
                headerName={"Address"}
                headerId={"address"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "address" ? isSort : false}
                sortingMethod={headerName === "address" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeSupplierListSorting}
                handleRemoveOrderListSorting={handleRemoveSupplierListSorting}
                headerName={"Email"}
                headerId={"email"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "email" ? isSort : false}
                sortingMethod={headerName === "email" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyleLastColumn}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeSupplierListSorting}
                handleRemoveOrderListSorting={handleRemoveSupplierListSorting}
                headerName={"Contact No"}
                headerId={"contactNo"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "contactNo" ? isSort : false}
                sortingMethod={headerName === "contactNo" ? sortingMethod : ""}
              />
            </Grid>
          </Grid>
        </CardCommon>
      </div>
      <Grid container style={{ marginTop: "1px" }}>
        {!_.isEmpty(nodeData) ? (
          nodeData.map((nodeData: any) => (
            <Grid
              item
              xs={12}
              key={nodeData.id}
              style={{ placeItems: "center", marginTop: "4px" }}
            >
              <StockGroupInfoNode
                nodeData={nodeData}
                handleOpenEditSupplierModal={handleOpenEditSupplierModal}
              />
            </Grid>
          ))
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No Supplier available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(StockGroupHeader);

import React from "react";
import _ from "lodash";
import TextfieldCommon from "../../textField/TextfieldCommon";
import { IconButton } from "@material-ui/core";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

export interface IncrementDecrementTextboxProps {
  handleOrderQty: any;
  value: any;
  id: string;
  handleIncrement: any;
  handleDecrement: any;
  isDisable?: any
}

/**
 * This component provides an input field with increment and decrement buttons for adjusting quantity values.
 * It is commonly used in various parts of the application to allow users to modify quantities easily.
 */
const IncrementDecrementTextbox: React.FunctionComponent<
  IncrementDecrementTextboxProps
> = ({ handleOrderQty, value, id, handleIncrement, handleDecrement, isDisable }) => {
  return (
    <>
      <IconButton   disabled={isDisable} onClick={() => handleDecrement(id)}>
        <IndeterminateCheckBoxOutlinedIcon
          style={{
            fontSize: "16px",
          }}
        />
      </IconButton>

      <TextfieldCommon
        id="outputMultiplier"
        name="outputMultiplier"
        label=""
        type="number"
        style={{
          width: "80px",
          marginLeft: "2px",
          marginRight: "2px",
        }}
        disabled={isDisable}
        value={value}
        onChange={(event: any) => handleOrderQty(id, event)}
      />
      <IconButton   disabled={isDisable} onClick={() => handleIncrement(id)}>
        <AddBoxOutlinedIcon
          style={{
            fontSize: "16px",
          }}
        />
      </IconButton>
    </>
  );
};

export default IncrementDecrementTextbox;

import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box, Grid, Hidden, useTheme } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import Filter from "../../../../components/common/Filter";
import {
  fetchAllDashboardAverageInfo,
  fetchAllDashboardChannelInfo,
  fetchAllDashboardModeInfo,
  fetchAllDashboardOrdersInfo,
  fetchAllDashboardPaymentInfo,
  fetchAllDashboardPlatformInfo,
  fetchAllDashboardSaleInfo,
  fetchAllDashboardSalesChannelsInfo,
  fetchAllDashboardSalesPlatformsInfo,
  fetchAllDashboardSalesTotalInfo,
  fetchAllDashboardSalesModeInfo,
} from "../../../../services/salesApp/saleDashboardService";

import {
  dashboardFilterObject,
  zeroToTwentyThreeList,
} from "../../../../utils/consts/list";
import moment from "moment";
import DashboardDetailsInfoNode from "./DashboardDetailsInfoNode";
import ChartLoading from "./ChartLoading";
import Mode from "./Mode";
import PageHeader from "../../../../components/common/PageHeader/PageHeader";
import { CustomTheme } from "../../../../types/customTheme";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { enumerateDaysBetweenDates } from "../../../../utils/enumerateDaysBetweenDates";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { fetchAllUserInfo } from "../../../../services/salesApp/salesService";
import SalePageTitleAndLocationDetails from "../../../../components/common/SaleReport/SalePageTitleAndLocationDetails";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import { Skeleton } from "@material-ui/lab";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface DashboardProps {}

/* Show dashboard total, chart and mode information */
const DashboardReport: React.FunctionComponent<DashboardProps> = () => {
  const [currentDashboardNodeList, setCurrentDashboardNodeList] = useState<any>(
    {},
  );
  const [previousDashboardNodeList, setPreviousDashboardNodeList] =
    useState<any>({});
  const [paymentNodeList, setPaymentNodeList] = useState<any>([]);
  const [previousPaymentNodeList, setPreviousPaymentNodeList] = useState<any>(
    [],
  );
  const [ordersNodeList, setOrdersNodeList] = useState<any>([]);
  const [previousOrdersNodeList, setPreviousOrdersNodeList] = useState<any>([]);
  const [averageNodeList, setAverageNodeList] = useState<any>([]);
  const [previousAverageNodeList, setPreviousAverageNodeList] = useState<any>(
    [],
  );
  const [platformNodeList, setPlatformNodeList] = useState<any>([]);
  const [previousPlatformNodeList, setPreviousPlatformNodeList] = useState<any>(
    [],
  );
  const [modeNodeList, setModeNodeList] = useState<any>([]);
  const [previousModeNodeList, setPreviousModeNodeList] = useState<any>([]);
  const [channelNodeList, setChannelNodeList] = useState<any>([]);
  const [previousChannelNodeList, setPreviousChannelNodeList] = useState<any>(
    [],
  );
  const [salesModeNodeList, setSalesModeNodeList] = useState<any>([]);
  const [previousSalesModeNodeList, setPreviousSalesModeNodeList] =
    useState<any>([]);
  const [salesPlatformsNodeList, setSalesPlatformsNodeList] = useState<any>([]);
  const [previousSalePlatformsNodeList, setPreviousSalesPlatformsNodeList] =
    useState<any>([]);
  const [salesChannelsNodeList, setSalesChannelsNodeList] = useState<any>([]);
  const [previousSalChannelsNodeList, setPreviousSalesChannelsNodeList] =
    useState<any>([]);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [error, setError] = useState("");
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [isLoadingIsLoadingSalesTotal, setIsLoadingSalesTotal] = useState(true);
  const [isLoadingIsLoadingMode, setIsLoadingMode] = useState(true);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [userList, setUserList] = useState<any>([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState<any>([]);
  const [previousStartDate, setPreviousStartDate] = React.useState<any>(
    moment().subtract(61, "d").format("YYYY-MM-DD"),
  );
  const [previousEndDate, setPreviousEndDate] = React.useState<any>(
    moment().subtract(31, "d").format("YYYY-MM-DD"),
  );
  const [currentStartDate, setCurrentStartDate] = React.useState<any>(
    moment().subtract(30, "d").format("YYYY-MM-DD"),
  );
  const [currentEndDate, setCurrentEndDate] = React.useState<any>(
    moment().format("YYYY-MM-DD"),
  );
  const [currentSalesTotal, setCurrentSalesTotal] = useState<any>();
  const [previousSalesTotal, setPreviousSalesTotal] = useState<any>();
  const [isEmptyCurrentData, setIsEmptyCurrentData] = useState(false);
  const [isEmptyPreviousData, setIsEmptyPreviousData] = useState(false);
  const [isEmptyList, setIsEmptyList] = useState(false);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  useEffect(() => {
    let currentDateListObject: any = {};
    let previousDateListObject: any = {};
    let currentDateList: any = [];
    let previousDateList: any = [];
    if (isEmptyCurrentData && isEmptyPreviousData) {
      /* If the start date and end date are the same, get the date as a list of hours.
        ex: - [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] */
      if (currentStartDate === currentEndDate) {
        currentDateList = zeroToTwentyThreeList;
        previousDateList = zeroToTwentyThreeList;
      } else {
        /* If the start date and end date are not same, get the dates between the two dates as a list. */
        currentDateList = enumerateDaysBetweenDates(
          currentStartDate,
          currentEndDate,
        );
        previousDateList = enumerateDaysBetweenDates(
          previousStartDate,
          previousEndDate,
        );
      }
      // Here, the amount​ ​for the current date are given randomly.
      currentDateList.map(
        (date: string) =>
          (currentDateListObject[date] = {
            total_amount: Math.floor(Math.random() * (100 - 0)) + 0,
          }),
      );
      // Here, the amount​ ​for the previous date are given randomly.
      previousDateList.map(
        (date: string) =>
          (previousDateListObject[date] = {
            total_amount: Math.floor(Math.random() * (100 - 0)) + 0,
          }),
      );

      setCurrentDashboardNodeList(currentDateListObject);
      setPreviousDashboardNodeList(previousDateListObject);
      setIsEmptyList(true);
    } else {
      setIsEmptyList(false);
    }
  }, [isEmptyCurrentData, isEmptyPreviousData]);

  /* Get current sale total information  using API call */
  const getCurrentSalesTotal = async (
    filterData: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSalesTotalInfo(
        idToken,
        match.params.locationId,
        filterData,
        basedOnShift,
      );
      setCurrentSalesTotal(res.data.data);
      getFilterInfoSalesTab(filterData, basedOnShift);
    } catch (err) {
      setIsLoadingSalesTotal(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      getFilterInfoSalesTab(filterData, basedOnShift);
    }
  };

  /* Ge previous sale total information  using API call */
  const getPreviousSalesTotal = async (
    filterData: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSalesTotalInfo(
        idToken,
        match.params.locationId,
        filterData,
        basedOnShift,
      );
      setIsLoadingSalesTotal(false);
      setPreviousSalesTotal(res.data.data);
      getPreviousFilterInfoSalesTab(filterData, basedOnShift);
    } catch (err) {
      setIsLoadingSalesTotal(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      getPreviousFilterInfoSalesTab(filterData, basedOnShift);
    }
  };

  /* Getting the data required for the current sale chart using the API call. */
  const getFilterInfoSalesTab = async (
    filterData: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSaleInfo(
        idToken,
        match.params.locationId,
        filterData,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);

      if (!_.isEmpty(res.data.data)) {
        setIsEmptyCurrentData(false);
      } else {
        setIsEmptyCurrentData(true);
      }
      setCurrentDashboardNodeList(res.data.data);
      getFilterInfoPaymentTab(filterData, basedOnShift);
      getFilterInfoOrdersTab(filterData, basedOnShift);
      getFilterInfoAverageTab(filterData, basedOnShift);
      getFilterInfoPlatformTab(filterData, basedOnShift);
      getFilterInfoModeTab(filterData, basedOnShift);
      getFilterInfoChannelTab(filterData, basedOnShift);
      getFilterInfoSalesModeTab(filterData, basedOnShift);
      getFilterInfoSalesPlatformsTab(filterData, basedOnShift);
      getFilterInfoSalesChannelsTab(filterData, basedOnShift);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      getFilterInfoPaymentTab(filterData, basedOnShift);
      getFilterInfoOrdersTab(filterData, basedOnShift);
      getFilterInfoAverageTab(filterData, basedOnShift);
      getFilterInfoPlatformTab(filterData, basedOnShift);
      getFilterInfoModeTab(filterData, basedOnShift);
      getFilterInfoChannelTab(filterData, basedOnShift);
      getFilterInfoSalesModeTab(filterData, basedOnShift);
      getFilterInfoSalesPlatformsTab(filterData, basedOnShift);
      getFilterInfoSalesChannelsTab(filterData, basedOnShift);
    }
  };

  /* Getting the data required for the previous sale chart using the API call. */
  const getPreviousFilterInfoSalesTab = async (
    filterData: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSaleInfo(
        idToken,
        match.params.locationId,
        filterData,
        basedOnShift,
      );
      if (!_.isEmpty(res.data.data)) {
        setIsEmptyPreviousData(false);
      } else {
        setIsEmptyPreviousData(true);
      }
      setPreviousDashboardNodeList(res.data.data);
      getPreviousFilterInfoPaymentTab(filterData, basedOnShift);
      getPreviousFilterInfoOrdersTab(filterData, basedOnShift);
      getPreviousFilterInfoAverageTab(filterData, basedOnShift);
      getPreviousFilterInfoPlatformTab(filterData, basedOnShift);
      getPreviousFilterInfoModeTab(filterData, basedOnShift);
      getPreviousFilterInfoChannelTab(filterData, basedOnShift);
      getPreviousFilterInfoSalesModeTab(filterData, basedOnShift);
      getPreviousFilterInfoSalesPlatformsTab(filterData, basedOnShift);
      getPreviousFilterInfoSalesChannelsTab(filterData, basedOnShift);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      getPreviousFilterInfoPaymentTab(filterData, basedOnShift);
      getPreviousFilterInfoOrdersTab(filterData, basedOnShift);
      getPreviousFilterInfoAverageTab(filterData, basedOnShift);
      getPreviousFilterInfoPlatformTab(filterData, basedOnShift);
      getPreviousFilterInfoModeTab(filterData, basedOnShift);
      getPreviousFilterInfoChannelTab(filterData, basedOnShift);
      getPreviousFilterInfoSalesModeTab(filterData, basedOnShift);
      getPreviousFilterInfoSalesPlatformsTab(filterData, basedOnShift);
      getPreviousFilterInfoSalesChannelsTab(filterData, basedOnShift);
    }
  };

  /* Getting the data required for the current payment chart using the API call. */
  const getFilterInfoPaymentTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardPaymentInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setPaymentNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous payment chart using the API call. */
  const getPreviousFilterInfoPaymentTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardPaymentInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setPreviousPaymentNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the current order chart using the API call. */
  const getFilterInfoOrdersTab = async (filter: any, basedOnShift: boolean) => {
    try {
      const res = await fetchAllDashboardOrdersInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setOrdersNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous order chart using the API call. */
  const getPreviousFilterInfoOrdersTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardOrdersInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setPreviousOrdersNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the current average chart using the API call. */
  const getFilterInfoAverageTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardAverageInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setAverageNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous average chart using the API call. */
  const getPreviousFilterInfoAverageTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardAverageInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setPreviousAverageNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the current platform chart using the API call. */
  const getFilterInfoPlatformTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardPlatformInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setPlatformNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous platform chart using the API call. */
  const getPreviousFilterInfoPlatformTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardPlatformInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setPreviousPlatformNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the current mode chart using the API call. */
  const getFilterInfoModeTab = async (filter: any, basedOnShift: boolean) => {
    try {
      const res = await fetchAllDashboardModeInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setModeNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous mode chart using the API call. */
  const getPreviousFilterInfoModeTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardModeInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setPreviousModeNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the current channel chart using the API call. */
  const getFilterInfoChannelTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardChannelInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setChannelNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous channel chart using the API call. */
  const getPreviousFilterInfoChannelTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardChannelInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setIsOpenSkeletonLoading(false);
      setPreviousChannelNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the current mode table using the API call. */
  const getFilterInfoSalesModeTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSalesModeInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setSalesModeNodeList(res.data.data);
    } catch (err) {
      setIsLoadingMode(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous mode table using the API call. */
  const getPreviousFilterInfoSalesModeTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSalesModeInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setPreviousSalesModeNodeList(res.data.data);
      setIsLoadingMode(false);
    } catch (err) {
      setIsLoadingMode(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the current platform table using the API call. */
  const getFilterInfoSalesPlatformsTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSalesPlatformsInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setSalesPlatformsNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous platform table using the API call. */
  const getPreviousFilterInfoSalesPlatformsTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSalesPlatformsInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setPreviousSalesPlatformsNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the current channel table using the API call. */
  const getFilterInfoSalesChannelsTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSalesChannelsInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      if (Object.keys(res.data.data).includes("wix")) {
        delete res.data.data.wix;
      }

      setSalesChannelsNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Getting the data required for the previous channel table using the API call. */
  const getPreviousFilterInfoSalesChannelsTab = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      const res = await fetchAllDashboardSalesChannelsInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      // Remove "wix" data in the object.
      if (Object.keys(res.data.data).includes("wix")) {
        delete res.data.data.wix;
      }
      setPreviousSalesChannelsNodeList(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get all location information */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
        }
        const locationName = getFilterListFromArrayObject(
          locationList,
          match.params.locationId,
        );
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  /* Get all user information using API call and response data set to userList state. */
  const getUserInfo = async (locationId: any) => {
    try {
      const res = await fetchAllUserInfo(idToken, locationId);
      if (!_.isEmpty(res.data.data)) {
        const res = await fetchAllUserInfo(idToken, locationId);

        let arr: any = [];
        if (!_.isEmpty(res.data.data)) {
          Object.values(res.data.data).map((data: any) =>
            arr.push({ id: data.id, label: data.name }),
          );
        }
        if (!_.isEmpty(arr)) {
          setUserList((prevState: any) => [...prevState, ...arr]);
        }
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  useEffect(() => {
    document.title = "Sale - Dashboard";
    getAllFilterLocation("");
  }, []);

  // Get current filter data
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setPreviousDashboardNodeList({});
    setPreviousDashboardNodeList({});
    setIsEmptyPreviousData(false);
    setIsEmptyCurrentData(false);
    setCurrentDashboardNodeList({});
    setIsLoadingSalesTotal(true);
    setIsOpenSkeletonLoading(true);
    setIsLoadingMode(true);
    getCurrentSalesTotal(filterData, basedOnShift);
  };

  // Get previous filter data
  const handlePreviousFilterData = (filterData: any, basedOnShift: boolean) => {
    setPreviousDashboardNodeList({});
    setPreviousDashboardNodeList({});
    setIsEmptyPreviousData(false);
    setIsEmptyCurrentData(false);
    setCurrentDashboardNodeList({});
    setIsLoadingSalesTotal(true);
    setIsOpenSkeletonLoading(true);
    setIsLoadingMode(true);
    getPreviousSalesTotal(filterData, basedOnShift);
  };
  const [filterLocation, setFilterLocation] = useState("");

  const [filterDate, setFilterDate] = useState("");

  /* Change the filter location details and filter date using filter details list */
  useEffect(() => {
    if (!_.isEmpty(filterDetails)) {
      const filterLocation = filterDetails.split("*")[0].split(",");
      if (filterLocation.length === 1) {
        setFilterLocation(filterLocation[0]);
      } else {
        setFilterLocation(filterLocation.length + " locations");
      }
      const startDateAndDate = filterDetails.split("*")[1];
      if (startDateAndDate) {
        const startDate = moment(startDateAndDate.split(" - ")[0]).format(
          "Do MMM YYYY",
        );
        const endDate = moment(startDateAndDate.split(" - ")[1]).format(
          "Do MMM YYYY",
        );
        const convertedStartDateAndEndDate = `${startDate} - ${endDate}`;
        setFilterDate(convertedStartDateAndEndDate);
      }
    }
  }, [filterDetails]);

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box>
        <Filter
          getFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          getLocationSelectorTypingList={handleLocationSelectorTypingList}
          getUserInfo={getUserInfo}
          userList={userList}
          availableFilter={dashboardFilterObject}
          setFilterDetails={setFilterDetails}
          getPreviousFilterInfo={handlePreviousFilterData}
          setPreviousStartDate={setPreviousStartDate}
          setPreviousEndDate={setPreviousEndDate}
          setCurrentStartDate={setCurrentStartDate}
          setCurrentEndDate={setCurrentEndDate}
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          isGetLocationInfo={isGetLocationInfo}
          isChart={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
        />
        <DashboardDetailsInfoNode
          isLoading={isLoadingIsLoadingSalesTotal}
          currentSalesTotal={currentSalesTotal}
          previousSalesTotal={previousSalesTotal}
        />
        <ChartLoading
          isLoading={isOpenSkeletonLoading}
          currentDashboardNodeList={currentDashboardNodeList}
          previousDashboardNodeList={previousDashboardNodeList}
          paymentNodeList={paymentNodeList}
          previousPaymentNodeList={previousPaymentNodeList}
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          ordersNodeList={ordersNodeList}
          previousOrdersNodeList={previousOrdersNodeList}
          averageNodeList={averageNodeList}
          previousAverageNodeList={previousAverageNodeList}
          platformNodeList={platformNodeList}
          previousPlatformNodeList={previousPlatformNodeList}
          modeNodeList={modeNodeList}
          previousModeNodeList={previousModeNodeList}
          channelNodeList={channelNodeList}
          previousChannelNodeList={previousChannelNodeList}
          isEmptyList={isEmptyList}
          currentSalesTotal={currentSalesTotal}
          previousSalesTotal={previousSalesTotal}
        />
        <Mode
          salesModeNodeList={salesModeNodeList}
          previousSalesModeNodeList={previousSalesModeNodeList}
          salesPlatformsNodeList={salesPlatformsNodeList}
          previousPlatformNodeList={previousSalePlatformsNodeList}
          salesChannelsNodeList={salesChannelsNodeList}
          previousSalChannelsNodeList={previousSalChannelsNodeList}
          isLoading={isLoadingIsLoadingMode}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(DashboardReport, Authorities.DASHBOARD_READ);

import React from "react";
import {
  Box,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import SkeleTonLoading from "../Loading/SkeleTonLoading";
import NoSalesFound from "../NoSalesFound";
import _ from "lodash";
import PageHeaderMobile from "../PageHeader/PageHeaderMoblie";
import ButtonCommon from "../../buttons/ButtonCommon";
import { buttonColors } from "../../../utils/enum";
import { CustomTheme } from "../../../types/customTheme";
import Filter from "../Filter";

export interface InventoryStockOrderProps {
  locationSelectorList: any;
  isOpenSkeletonLoading: any;
  nodeList: any;
  topic: any;
  children: any;
  handleOpenCreateModal: any;
  buttonName: any;
  handleFilterData: any;
  handleLocationSelectorTypingList: any;
  isGetLocationInfo: any;
  setFilterDetails: any;
  availableFilter: any;
  locationSelectedList: any;
  setLocationSelectedList: any;
  openFilterCard: any;
  setOpenFilterCard: any;
}

/* Set the filter, sale title, sale sub title, and table using filter details */
const InventoryStockOrderFilter: React.FunctionComponent<
  InventoryStockOrderProps
> = ({
  locationSelectorList,
  isOpenSkeletonLoading,
  nodeList,
  topic,
  children,
  handleOpenCreateModal,
  buttonName,
  handleFilterData,
  handleLocationSelectorTypingList,
  isGetLocationInfo,
  setFilterDetails,
  availableFilter,
  locationSelectedList,
  setLocationSelectedList,
  openFilterCard,
  setOpenFilterCard,
}) => {
  const theme: CustomTheme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box>
        <div
          style={
            matchesLg
              ? {
                  padding: "8px 6px 8px 8px",
                }
              : {
                  padding: "2px 12px 2px 12px",
                  marginTop: "6px",
                }
          }
        >
          <Hidden lgUp>
            <PageHeaderMobile />
          </Hidden>
          {!isOpenSkeletonLoading && (
            <Grid
              container
              style={{
                marginTop: "36px",
                placeItems: "center",
              }}
            >
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <div>
                  <Filter
                    getFilterData={handleFilterData}
                    locationSelectorList={locationSelectorList}
                    getLocationSelectorTypingList={
                      handleLocationSelectorTypingList
                    }
                    isGetLocationInfo={isGetLocationInfo}
                    setFilterDetails={setFilterDetails}
                    availableFilter={availableFilter}
                    locationSelectedList={locationSelectedList}
                    setLocationSelectedList={setLocationSelectedList}
                    openFilterCard={openFilterCard}
                    setOpenFilterCard={setOpenFilterCard}
                    isChart={false}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <ButtonCommon
                  style={{
                    marginRight: 4,
                    width: "210px",
                  }}
                  variant="contained"
                  color={buttonColors.CREATE_BUTTON_COLOR}
                  onClick={handleOpenCreateModal}
                >
                  <Typography style={{ textTransform: "none", color: "white" }}>
                    {buttonName}
                  </Typography>
                </ButtonCommon>
              </Grid>
            </Grid>
          )}
        </div>
        {!isOpenSkeletonLoading ? (
          <>
            {!_.isEmpty(nodeList) ? (
              <div
                style={
                  matchesLg
                    ? { padding: "8px" }
                    : { padding: "8px 16px 8px 8px" }
                }
              >
                {children}
              </div>
            ) : (
              <NoSalesFound title={topic} />
            )}
          </>
        ) : (
          <div
            style={
              matchesLg
                ? { marginTop: "0px", padding: "8px" }
                : { marginTop: "20px", padding: "8px 16px 8px 8px" }
            }
          >
            <SkeleTonLoading isReport={false} />
          </div>
        )}
      </Box>
    </>
  );
};

export default InventoryStockOrderFilter;

import React, { useEffect, useState } from "react";
import DialogCommon from "../dialogs/DialogCommon";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import ValidationMessage from "../validation/ValidationMessage";
import TextfieldCommon from "../textField/TextfieldCommon";
import { useForm } from "react-hook-form";
import { INTEGER_NUMBER_PATTERN } from "../../utils/consts";
import _ from "lodash";
import CardCommon from "../card/CardCommon";

export interface paymentReportProps {
  supplierDetailsNode: any;
  setSelectedSupplierList: any;
  selectedSupplierList: any;
}
/* Set the filter, sale title, sale sub title, and table using filter details */
const AddSupplier: React.FunctionComponent<paymentReportProps> = ({
  supplierDetailsNode,
  setSelectedSupplierList,
  selectedSupplierList,
}) => {
  const handleSelectedSupplier = (id: string) => {
    const cloneSelectedSupplier = _.cloneDeep(selectedSupplierList);
    if (cloneSelectedSupplier.includes(id)) {
      const filterSelectedSupplier = cloneSelectedSupplier.filter(
        (data: any) => data !== id,
      );
      setSelectedSupplierList(filterSelectedSupplier);
    } else {
      cloneSelectedSupplier.push(id);
      setSelectedSupplierList(cloneSelectedSupplier);
    }
  };

  const handleColor = (id: string) => {
    if (selectedSupplierList.includes(id)) {
      return "green";
    }
  };
  return (
    <div style={{ margin: "20px" }}>
      <Grid container>
        {supplierDetailsNode.map((supplier: any) => (
          <Grid item xs={4} style={{padding: "4px"}}>
            <CardCommon
              color={handleColor(supplier.id)}
              backgroundColor={"entity_highlight_background"}
            >
              <Button
                fullWidth
                onClick={() => handleSelectedSupplier(supplier.id)}
              >
                <Typography style={{ margin: "4px" }}>
                  {supplier.name}
                </Typography>
              </Button>
            </CardCommon>{" "}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AddSupplier;

import React, { useState } from "react";
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";

import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import StockItemInfoNode from "./StockItemInfoNode";

export interface OrdersHeaderProps {
  disableButton: any;
  isLoading: any;
  locationSelectorList: any;
  handleOnClickText: any;
  handleOpenEditStockItemsModal: any;
  handleOpenEditMapProductsItemsModal: any;
  handleOpenEdtSupplierToTheStockItems: any;
  selectedDepartmentId: any;
  searchName: any;
  registerElementWithTitle: any;
  stockItemGroupDepartmentNodeInSelectorInitial: any;
  stockItemGroupDepartmentNodeInSelector: any;
  activeGroupId: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerItemStyle: {
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
        paddingLeft: "0px",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
  }),
);

/* This is order Table header page. In addition, Until the required data for the order 
   table is received from the API call, this will show the loading of the skeleton. */
const StockItemsHeader: React.FunctionComponent<OrdersHeaderProps> = ({
  disableButton,
  isLoading,
  locationSelectorList,
  handleOpenEditStockItemsModal,
  handleOpenEditMapProductsItemsModal,
  handleOpenEdtSupplierToTheStockItems,
  selectedDepartmentId,
  searchName,
  registerElementWithTitle,
  stockItemGroupDepartmentNodeInSelectorInitial,
  stockItemGroupDepartmentNodeInSelector,
  activeGroupId,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenDepartmentList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const hasWindow = typeof window !== "undefined";
  /* Close the UI showing the list of categories. */
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  return (
    <>
      <div
        style={matchesLg ? { marginTop: "220px" } : { marginTop: "160px" }}
      ></div>
      <Hidden smDown>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px" }}>
            <Grid item xs={2} className={classes.headerFirstItemStyle}>
              <SortingMethod
                headerName={"Department"}
                headerId={""}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Location"}
                headerId={"primaryLocationId"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"name"}
                headerId={"name"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Display Suffix"}
                headerId={"displaySuffix"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={1} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Input Suffix"}
                headerId={"inputSuffix"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Input Multiplier"}
                headerId={"inputMultiplier"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
          </Grid>
        </CardCommon>
      </Hidden>
      <Grid container style={{ marginTop: "1px" }}>
        {!_.isEmpty(stockItemGroupDepartmentNodeInSelector) ? (
          stockItemGroupDepartmentNodeInSelector.map(
            (department: any, index: number) =>
              activeGroupId === index &&
              department.stockDepartmentId.map((data: any) =>
                data.item.map((item: any) => (
                  <Grid item xs={12}>
                    <div
                      ref={(ref) => registerElementWithTitle(data.id, ref)}
                      id={data.name}
                      style={{ position: "sticky", zIndex: 0 }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ placeItems: "center", marginTop: "4px" }}
                      >
                        <StockItemInfoNode
                          nodeData={item}
                          locationSelectorList={locationSelectorList}
                          handleOpenEditStockItemsModal={
                            handleOpenEditStockItemsModal
                          }
                          handleOpenEditMapProductsItemsModal={
                            handleOpenEditMapProductsItemsModal
                          }
                          handleOpenEdtSupplierToTheStockItems={
                            handleOpenEdtSupplierToTheStockItems
                          }
                          handleOpenDepartmentList={handleOpenDepartmentList}
                          anchorEl={anchorEl}
                          handleClose={handleClose}
                          departmentName={data}
                          registerElementWithTitle={registerElementWithTitle}
                        />
                      </Grid>
                    </div>
                  </Grid>
                )),
              ),
            // department.map((data: any) =>
            //   data.map((item: any) => (
            //     <Grid item xs={12}>
            //       <div
            //         ref={(ref) => registerElementWithTitle(data.id, ref)}
            //         id={data.name}
            //         style={
            //           index > 0
            //             ? { position: "sticky", zIndex: 0 }
            //             : { position: "sticky", zIndex: 0 }
            //         }
            //       >
            //         <Grid
            //           item
            //           xs={12}
            //           style={{ placeItems: "center", marginTop: "4px" }}
            //         >
            //           <StockItemInfoNode
            //             nodeData={item}
            //             locationSelectorList={locationSelectorList}
            //             numberOfLocations={numberOfLocations}
            //             handleOpenEditStockItemsModal={
            //               handleOpenEditStockItemsModal
            //             }
            //             handleOpenEditMapProductsItemsModal={
            //               handleOpenEditMapProductsItemsModal
            //             }
            //             handleOpenEdtSupplierToTheStockItems={
            //               handleOpenEdtSupplierToTheStockItems
            //             }
            //             handleOpenDepartmentList={handleOpenDepartmentList}
            //             anchorEl={anchorEl}
            //             handleClose={handleClose}
            //             departmentName={department}
            //             registerElementWithTitle={registerElementWithTitle}
            //           />
            //         </Grid>
            //       </div>
            //     </Grid>
            //   )),
            // ),
          )
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No Stock Items available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(StockItemsHeader);

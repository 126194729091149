import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControlLabel,
  Grid,
  Hidden,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import _ from "lodash";
import { Skeleton } from "@material-ui/lab";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import { CustomTheme } from "../../../../../types/customTheme";
import {
  updateStockOrder,
  fetchStockOrderItemForStockOrder,
  stockOrderItemDelete,
  updateStockOrderItem,
} from "../../../../../services/inventory/stockOrder";
import {
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  INTEGER_NUMBER_PATTERN,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
} from "../../../../../utils/consts";
import { fetchAllStockItemForSupplierInfo } from "../../../../../services/inventory/supplier";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import AlertDialog from "../../../../../components/alerts/AlertDialog";
import IncrementDecrementTextboxDefault from "../../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxDefault";
import { handleDownloadPdf } from "../convertPdf/convertDataToPdf";
import CardCommon from "../../../../../components/card/CardCommon";
import ValidationMessage from "../../../../../components/validation/ValidationMessage";
import { useForm } from "react-hook-form";
import SwitchCommon from "../../../../../components/switches/SwitchCommon";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    "& .MuiTabs-root": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 280px)",
      },
    },
  },
  roots: {
    display: "flex",
    alignItems: "start",
  },
  rowOdd: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    marginTop: "16px",
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  departmentStyle: {
    display: "block",
  },
  firstGridField: {
    display: "flex",
    justifyContent: "start",
    minHeight: "40px",
    alignItems: "center",
    paddingLeft: "16px",
  },
  gridField: {
    display: "flex",
    justifyContent: "center",
    minHeight: "40px",
    alignItems: "center",
  },
  lastGridField: {
    display: "flex",
    justifyContent: "end",
    minHeight: "40px",
    alignItems: "center",
    paddingRight: "16px",
  },
}));

export interface paymentReportProps {
  isOpenDeliveryDetailsModal: any;
  setIsOpenDeliveryDetailsModal: any;
  deliveryDetailsNode: any;
  handleUpdateDelivery: any;
  setIsNotDelivery: any;
  selectedStockOrderDetails: any;
}

interface FormData {
  orderQty: number;
  unitPrice: number;
  totalCost: number;
  deliveredQty: number;
}

/**
 * This component is responsible for rendering a modal that allows users to manage
 * stock order items. It fetches data from API endpoints to display a list of items
 * available from suppliers and the corresponding order quantities. Users can adjust
 * the order quantities, remove items from the order, and submit the updated quantities.
 *
 * The component uses Material-UI components for styling and UI elements. It handles
 * various user interactions, updates, and API calls to manage the stock order items.
 */
const DeliveryDetailsModal: React.FunctionComponent<paymentReportProps> = ({
  isOpenDeliveryDetailsModal,
  setIsOpenDeliveryDetailsModal,
  deliveryDetailsNode,
  handleUpdateDelivery,
  setIsNotDelivery,
  selectedStockOrderDetails,
}) => {
  const [unitPrice, setUnitPrice] = useState<number | undefined>(undefined);
  const [totalCost, setTotalCost] = useState<number | undefined>(undefined);
  const [deliveredQty, setDeliveredQty] = useState(0);
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      orderQty: 0,
      unitPrice: 0,
      totalCost: 0,
      deliveredQty: 0,
    },
  });
  const [status, setStatus] = useState("");
  const [isAutoAdjust, setIsAutoAdjust] = useState(true);

  const handleChangeAutoAdjust = (e: any) => {
    const { value, name, checked } = e.target;
    if (checked) {
      if (unitPrice && deliveredQty) {
        setTotalCost(unitPrice * deliveredQty);
      } else {
        setTotalCost(0);
      }
    }

    setIsAutoAdjust(!isAutoAdjust);
  };

  useEffect(() => {
    const { deliveredQty, orderQty, totalCost, unitPrice, status } =
      deliveryDetailsNode;
    setStatus(status);
    reset({
      orderQty: orderQty,
      unitPrice: unitPrice,
      totalCost: totalCost,
      deliveredQty: deliveredQty,
    });

    setUnitPrice(unitPrice);
    setTotalCost(totalCost);
    setDeliveredQty(deliveredQty);
    console.log("sdfsdfse234aasfdas", deliveryDetailsNode);
  }, [deliveryDetailsNode]);

  const handleConfirmDelivery = handleSubmit((data: any) => {
    console.log("sdfasdas234fsd", data);
    setIsOpenDeliveryDetailsModal(false);
    handleUpdateDelivery(data, "delivered");
  });

  const handleNotConfirmDelivery = () => {
    const { deliveredQty, orderQty, totalCost, unitPrice, status } =
      deliveryDetailsNode;
    const data = {
      orderQty: orderQty,
      unitPrice: unitPrice,
      totalCost: totalCost,
      deliveredQty: deliveredQty,
    };
    setIsOpenDeliveryDetailsModal(false);
    handleUpdateDelivery(data, "not-delivered");
    setIsNotDelivery(true);
  };

  const formatNumber = (num: any) => {
    // Check if the number has a fractional part
    if (num % 1 !== 0) {
      return num.toFixed(2);
    } else {
      return num.toString();
    }
  };

  const classes = useStyles();
  return (
    <>
      <DialogCommonDefault
        open={isOpenDeliveryDetailsModal}
        setOpen={setIsOpenDeliveryDetailsModal}
        isOpenMapProd={false}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          Ordered Details
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={10}>
              <TextfieldCommon
                id="orderQty"
                name="orderQty"
                type="number"
                label="Qty"
                disabled
                inputRef={register({
                  required: "Order QTY is required.",
                })}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <FormControlLabel
                style={{ marginLeft: "0px", marginRight: "0px" }}
                value="autoAdjust"
                disabled={selectedStockOrderDetails.status === "delivered"}
                control={<SwitchCommon />}
                label={
                  <Typography variant="caption" color="textSecondary">
                    Auto Adjust
                  </Typography>
                }
                name="autoAdjust"
                checked={isAutoAdjust}
                labelPlacement="bottom"
                onChange={handleChangeAutoAdjust}
              />
            </Grid>
            <Grid item xs={12}>
              <TextfieldCommon
                id="deliveredQty"
                name="deliveredQty"
                type="number"
                label="Delivered QTY"
                disabled={selectedStockOrderDetails.status === "delivered"}
                onChange={(e: any) => {
                  const newDeliveredQty = parseFloat(e.target.value) || 0;
                  if (isAutoAdjust) {
                    const newTotalCost =
                      unitPrice !== undefined &&
                      parseFloat(newDeliveredQty.toString()) !== undefined
                        ? unitPrice * parseFloat(newDeliveredQty.toString())
                        : undefined;
                    if (newTotalCost === undefined) {
                      setValue("totalCost", newTotalCost);
                    } else {
                      setValue("totalCost", formatNumber(newTotalCost));
                    }

                    setTotalCost(newTotalCost);
                  }

                  setDeliveredQty(newDeliveredQty);
                }}
                inputRef={register({
                  required: "Delivered QTY is required.",
                  min: {
                    value: 1,
                    message: "Delivered QTY is greater than 0.",
                  },
                  pattern: {
                    value: INTEGER_NUMBER_PATTERN,
                    message: "Please enter a Delivered QTY.",
                  },
                })}
              />
              {errors.deliveredQty ? (
                <ValidationMessage message={errors.deliveredQty.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12}>
              <TextfieldCommon
                id="unitPrice"
                name="unitPrice"
                type="number"
                label="Unit Price"
                disabled={selectedStockOrderDetails.status === "delivered"}
                onChange={(e: any) => {
                  const newUnitPrice = parseFloat(e.target.value);
                  if (isAutoAdjust) {
                    const newTotalCost =
                      newUnitPrice !== undefined &&
                      parseFloat(deliveredQty.toString()) !== undefined
                        ? newUnitPrice * parseFloat(deliveredQty.toString())
                        : undefined;
                    setTotalCost(newTotalCost);

                    if (newTotalCost === undefined) {
                      setValue("totalCost", newTotalCost);
                    } else {
                      setValue("totalCost", formatNumber(newTotalCost));
                    }
                  }
                  setUnitPrice(newUnitPrice);
                }}
                inputRef={register({
                  required: "Unit Price is required.",
                  min: {
                    value: 1,
                    message: "Unit Price is greater than 0.",
                  },
                })}
              />
              {errors.unitPrice ? (
                <ValidationMessage message={errors.unitPrice.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12}>
              <TextfieldCommon
                id="totalCost"
                name="totalCost"
                type="number"
                label="Total"
                disabled={selectedStockOrderDetails.status === "delivered"}
                onChange={(e: any) => {
                  const newTotalCost = parseFloat(e.target.value);
                  if (isAutoAdjust) {
                    const newUnitPrice =
                      newTotalCost !== undefined &&
                      parseFloat(deliveredQty.toString()) !== undefined
                        ? newTotalCost / parseFloat(deliveredQty.toString())
                        : undefined;
                    setUnitPrice(newUnitPrice);

                    if (newUnitPrice === undefined) {
                      setValue("unitPrice", newUnitPrice);
                    } else {
                      setValue("unitPrice", formatNumber(newUnitPrice));
                    }
                  }
                  setTotalCost(newTotalCost);
                }}
                inputRef={register({
                  required: "Total is required.",
                  min: {
                    value: 1,
                    message: "Total is greater than 0.",
                  },
                })}
              />
              {errors.totalCost ? (
                <ValidationMessage message={errors.totalCost.message} />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <div style={{ display: "block", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "18px",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => setIsOpenDeliveryDetailsModal(false)}
                style={{ textTransform: "none" }}
                color="secondary"
                autoFocus
              >
                Back
              </Button>
              <Button
                onClick={handleConfirmDelivery}
                style={{ textTransform: "none" }}
                color="secondary"
                autoFocus
                disabled={
                  !isDirty || selectedStockOrderDetails.status === "delivered"
                }
              >
                Confirm
              </Button>
              <Button
                onClick={handleNotConfirmDelivery}
                style={{ textTransform: "none" }}
                color="secondary"
                autoFocus
                disabled={
                  status === "not-delivered" ||
                  selectedStockOrderDetails.status === "delivered"
                }
              >
                Not Delivery
              </Button>
            </div>
          </div>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default DeliveryDetailsModal;

import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useContext } from "react";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import LocationAppRoutes, {
  getSidebarRoutes,
} from "./routes/LocationAppRoutes";
import { initTheme } from "./theme/primary";

export interface PrestoExpressLocationAppProps {}

const PrestoExpressLocationApp: React.FunctionComponent<PrestoExpressLocationAppProps> =
  () => {
    const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([]);
    const { globalState } = useContext(GlobalContext);
    const theme = initTheme({ isDark: globalState.isDarkMode });

    const handleChangeSidebar = () => {
      const sidebar = getSidebarRoutes();
      setSidebarRoutes(sidebar);
    };

    React.useEffect(() => {
      handleChangeSidebar();
    }, []);

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PrimaryLayout
          sidebarRoutes={sidebarRoutes}
          handleChangeSidebar={handleChangeSidebar}
        >
          <LocationAppRoutes />
        </PrimaryLayout>
      </ThemeProvider>
    );
  };

export default PrestoExpressLocationApp;

import React, { useContext, useEffect, useState } from "react";
import InventoryAppRoutes, {
  getSidebarRoutes,
} from "./routes/InventoryAppRoutes";
import { ThemeProvider } from "@material-ui/core/styles";
import { initTheme } from "./theme/primary";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import { CssBaseline } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { getCookie } from "../../utils/cookies";
import { fetchAllMasterLocations } from "../../services/inventory/masterLocations";

export interface PrestoExpressInventoryAppProps {}

const PrestoExpressInventoryApp: React.FunctionComponent<
  PrestoExpressInventoryAppProps
> = () => {
  const [sidebarRoutes, setSidebarRoutes] = useState<any>([]);
  const { globalState } = useContext(GlobalContext);
  const theme = initTheme({ isDark: globalState.isDarkMode });

  const match: any = useRouteMatch();

  const handleGetMasterLocations = async () => {
    try {
      const res = await fetchAllMasterLocations(match.params.locationId);

      return res.data;
    } catch (error) {}
  };
  const idToken = getCookie("idToken");
  console.log("dfsssssssssssssssssdfsdfs", idToken)
  const handleChangeSidebar = async () => {
    const sidebar = getSidebarRoutes();
    const masterLocations = await handleGetMasterLocations();
    if (masterLocations.status === 404) {
      const sidebar = getSidebarRoutes();
      const updatedSidebar = sidebar.filter((data) => _.isEmpty(data.childRoutes));
      setSidebarRoutes(updatedSidebar);
    } else {
      const { masterLocationId, locationId } = masterLocations.data;
      let updatedSidebar: any = [];
      if (_.isEmpty(masterLocationId) || masterLocationId === locationId) {
        updatedSidebar = sidebar;
      } else {
        updatedSidebar = sidebar.filter((data) => _.isEmpty(data.childRoutes));
      }

      setSidebarRoutes(updatedSidebar);
    }
  };
  const [isOpenDesktopSideBar, seIsOpenDesktopSideBar] = useState(false)
  useEffect(() => {
    handleChangeSidebar();
  }, [match.params.locationId]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <PrimaryLayout
        sidebarRoutes={sidebarRoutes}
        handleChangeSidebar={handleChangeSidebar}
        seIsOpenDesktopSideBar={seIsOpenDesktopSideBar}
        isNedSidebarOpen={true}
      >
        {sidebarRoutes.length > 0 && <InventoryAppRoutes isOpenDesktopSideBar={isOpenDesktopSideBar} />}
      </PrimaryLayout>
    </ThemeProvider>
  );
};

export default PrestoExpressInventoryApp;

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useState } from "react";
import { verifyAuthTokenWithServer } from "../../services/accountApp/authService";
import { ACCOUNT_ORIGIN } from "../../utils/consts";
import { getCookie, removeAllCookies, setCookie } from "../../utils/cookies";
import firebase from "../../utils/firebase";
import LocationSelect from "../common/LocationSelect";
import DarkLightModeSwitch from "./DarkLightModeSwitch";
import PrestoApps from "./PrestoApps";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import headerLogoDark from "../../assets/images/logos/header_logo_dark.png";
import headerLogoLight from "../../assets/images/logos/header_logo_light.png";
import { Box, Hidden, useMediaQuery } from "@material-ui/core";
import { CustomTheme } from "../../types/customTheme";
import { useTheme } from "@material-ui/styles";
import { getLocalStore } from "../../utils/store/localStore";
import PageHeader from "../common/PageHeader/PageHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    iconStyle: {
      "&:hover": {
        background: "none",
      },
    },
  }),
);

export interface AppHeaderBarProps {
  setOpen: any;
  open: boolean;
  handleChangeSidebar: any;
}

/* This component displays location selection, dark/light mode selection, 
   application selection, and account details in the app bar. */
const AppHeaderBar: React.FunctionComponent<AppHeaderBarProps> = ({
  setOpen,
  open,
  handleChangeSidebar,
}) => {
  const [authUser, setAuthUser] = useState<{
    isAuthenticated: boolean;
    name: string | "";
    email: string | "";
    uId: String | "";
  }>({
    isAuthenticated: false,
    name: "",
    email: "",
    uId: "",
  });

  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    const CURRENT_DOMAIN =
      process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "localhost";

    // Checking if the user is now in the account application.
    const isAccountApp = window.location.href.includes(CURRENT_DOMAIN);

    if (isAccountApp) {
      firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (
          firebaseUser &&
          window.location.href !==
            `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`
        ) {
          firebaseUser
            .getIdToken(true)
            .then(function (idToken) {
              // Get the user details
              setAuthUser({
                isAuthenticated: true,
                email: firebaseUser?.email || "",
                name: firebaseUser?.displayName || "",
                uId: firebaseUser?.uid || "",
              });
              // Get the login details
              verifyAuthTokenWithServer(
                idToken,
                firebaseUser,
                firebaseUser.refreshToken,
              );
            })
            .catch(function (error) {
              alert("error");
              setAuthUser({
                isAuthenticated: false,
                email: "",
                name: "",
                uId: "",
              });
            });
        } else {
          setAuthUser({ isAuthenticated: false, email: "", name: "", uId: "" });
        }
      });
    } else {
      try {
        const idToken = getCookie("idToken");
        const firebaseUser = getCookie("firebaseUser");
        const refreshToken = getCookie("refreshToken");
        if (typeof firebaseUser === "string") {
          setAuthUser(JSON.parse(firebaseUser));
          verifyAuthTokenWithServer(
            idToken,
            JSON.parse(firebaseUser),
            refreshToken,
          );
        } else {
          window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`;
        }
      } catch (error) {
        window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`;
      }
    }
  }, []);

  /* Open the profile menu information */
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  /* This function is use to log out the app. */
  const handleLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        removeAllCookies();
        window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`;
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          style={{ height: "16px" }}
        >
          <PowerSettingsNewIcon />
        </IconButton>
        Logout
      </MenuItem>
    </Menu>
  );
  const isAccountDomain = window.location.pathname.includes(ACCOUNT_ORIGIN);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const theme: CustomTheme = useTheme();

  // Show the location selection, dark/light mode selection, application selection, and account details in mobile view.
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        disableGutters={true}
        dense={true}
        style={{ height: "48px", display: "flex", justifyContent: "start" }}
      >
        <Box
          style={{
            borderRadius: 12,
            color: theme.palette.custom.orange.contrastText,
            height: "48px",
            display: "flex",
            justifyContent: "start",
            width: "220px",
          }}
        >
          {authUser.isAuthenticated && (
            <div className={classes.sectionDesktop}>
              {!isAccountDomain && <PrestoApps authUser={authUser} />}
            </div>
          )}
        </Box>
      </MenuItem>
      <MenuItem
        style={{
          height: "48px",
          display: "flex",
          justifyContent: "start",
        }}
        disableGutters={true}
        dense={true}
      >
        <Box
          style={{
            borderRadius: 12,
            color: theme.palette.custom.orange.contrastText,
            height: "48px",
            display: "flex",
            justifyContent: "start",
            width: "220px",
          }}
        >
          <DarkLightModeSwitch />
        </Box>
      </MenuItem>

      <MenuItem onClick={handleMobileMenuClose}>
        <IconButton
          style={{
            borderRadius: 12,
            color: theme.palette.custom.orange.contrastText,
            height: "40px",
          }}
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          color="inherit"
          className={classes.iconStyle}
        >
          <AccountCircle />
          <span style={{ margin: 4 }}></span>
          <Typography>{authUser.name ? authUser.name : "Account"}</Typography>
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          style={{
            borderRadius: 12,
            color: theme.palette.custom.orange.contrastText,
            height: "40px",
          }}
        >
          <PowerSettingsNewIcon />
        </IconButton>
        Logout
      </MenuItem>
    </Menu>
  );

  const matchesLg = useMediaQuery("(max-width: 960px)");
  const isDarkMode = getLocalStore("isDarkMode");
  return (
    <>
      {(!window.location.origin.includes(
        process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
      ) ||
        window.location.origin.includes(
          process.env.REACT_APP_LOCAL_HOST || "",
        )) && (
        <div className={classes.grow}>
          <AppBar elevation={0} style={{ zIndex: 4 }}>
            <Toolbar>
              <div style={{ display: "flex", alignItems: "center" }}>
                {!isAccountDomain && (
                  <IconButton
                    style={{ color: theme.palette.custom.orange.contrastText }}
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowLeftIcon /> : <MenuIcon />}
                  </IconButton>
                )}
                <Hidden mdDown>
                  <PageHeader />
                </Hidden>
                {/* <img
                  src={isDarkMode==="true" ? headerLogoDark : headerLogoLight}
                  width="112px"
                  height="44px"
                /> */}
              </div>

              <div className={classes.grow} />
              {!isAccountDomain && (
                <LocationSelect handleChangeSidebar={handleChangeSidebar} />
              )}
              <Hidden smDown={true}>
                <DarkLightModeSwitch />
              </Hidden>
              {authUser.isAuthenticated && (
                <>
                  <Hidden smDown={true}>
                    <div className={classes.sectionDesktop}>
                      {!isAccountDomain && <PrestoApps authUser={authUser} />}
                      <IconButton
                        style={{
                          borderRadius: 12,
                          color: theme.palette.custom.orange.contrastText,
                        }}
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                      >
                        <AccountCircle />
                        <span style={{ margin: 4 }}></span>
                        <Typography
                          style={{
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          {authUser.name}
                        </Typography>
                      </IconButton>
                      <Hidden mdDown>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "12px",
                          }}
                        >
                          <img
                            src={
                              isDarkMode === "true"
                                ? headerLogoDark
                                : headerLogoLight
                            }
                            height="24px"
                          />
                        </div>
                      </Hidden>
                    </div>
                  </Hidden>
                  <div className={classes.sectionMobile}>
                    <IconButton
                      style={{
                        color: theme.palette.custom.orange.contrastText,
                      }}
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreIcon
                        style={{
                          color: theme.palette.custom.orange.contrastText,
                        }}
                      />
                    </IconButton>
                  </div>
                </>
              )}
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </div>
      )}
    </>
  );
};

export default AppHeaderBar;

import { axiosClient } from "../client";

const queryString = require("query-string");

const STOCK_ORDERS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockOrder`;

const STOCK_ORDERS_INFO_UPDATE = (locationId, orderId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockOrder/${orderId}/stockOrderItemMapping`;

  const STOCK_ORDERS_ITEM_DELETE = (locationId, stockOrderItemId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockOrderItem/id/${stockOrderItemId}`;

const STOCK_ORDER_ITEM_INFO_FOR_STOCK_ORDER = (locationId, stockOrderId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockOrderItem/stockOrder/${stockOrderId}`;

  const STOCK_ORDER_ITEM_STATUS_UPDATE = (locationId, stockOrderId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockOrder/${stockOrderId}`;

const STOCK_ORDER_GET_SINGLE = (locationId, stockOrderId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockOrderItem/stockOrder/${stockOrderId}`;

const STOCK_ORDER_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockOrder`;

  const STOCK_ORDER_INFO_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockOrder`;

export const createStockOrder = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: STOCK_ORDER_INFO_CREATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const updateStockOrder = (locationId, data) =>
  axiosClient({
    method: "PATCH",
    url: STOCK_ORDER_INFO_UPDATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateStockOrderItem = (locationId, orderId, data) =>
  axiosClient({
    method: "POST",
    url: STOCK_ORDERS_INFO_UPDATE(locationId, orderId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });


  export const stockOrderItemDelete = (locationId, stockOrderItemId) =>
  axiosClient({
    method: "DELETE",
    url: STOCK_ORDERS_ITEM_DELETE(locationId, stockOrderItemId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchStockOrderItemForStockOrder = (locationId, stockOrderId) =>
  axiosClient({
    method: "GET",
    url: STOCK_ORDER_ITEM_INFO_FOR_STOCK_ORDER(locationId, stockOrderId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const updateStockOrderItemStatus = (locationId, stockOrderId, data) =>
  axiosClient({
    method: "PATCH",
    url: STOCK_ORDER_ITEM_STATUS_UPDATE(locationId, stockOrderId),
    data: data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchStockOrderGetSingle = (locationId, stockOrderId) =>
  axiosClient({
    method: "GET",
    url: STOCK_ORDER_GET_SINGLE(locationId, stockOrderId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllStockOrdersInfo = (
  locationId,
  size,
  currentPage,
  shortingMethod,
  shortingHeaderName,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: STOCK_ORDERS_INFO_GET_ALL(locationId),
        query: {
          size: size,
          page: currentPage,
          sort: [shortingHeaderName, shortingMethod],
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

import React from "react";
import { Route, Switch } from "react-router-dom";
import BasicInfo from "../pages/BasicInfo";
import OpeningTimes from "../pages/OpeningTimes";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import Location from "../pages/Location";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Dashboard from "../pages/Dashboard";
import StorefrontIcon from "@material-ui/icons/Storefront";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "DASHBOARD",
      title: "Dashboard",
      path: "",
      icon: <DashboardIcon />,
      isAuthorized: getIsAuthorized(Authorities.LOCATION_DASHBOARD_READ),
    },
    {
      id: "BASIC_INFO",
      title: "Business Info",
      path: "/basic-info",
      icon: <StorefrontIcon />,
      isAuthorized: getIsAuthorized(Authorities.BASIC_INFO_READ),
    },
    {
      id: "OPENING_TIMES",
      title: "Opening Times",
      path: "/opening-times",
      icon: <AccessTimeIcon />,
      isAuthorized: getIsAuthorized(Authorities.OPENING_TIME_READ),
    },
  ];
  return sidebarRoute;
};

export interface LocationAppRoutesProps {}

const LocationAppRoutes: React.FunctionComponent<
  LocationAppRoutesProps
> = () => {
  return (
    <Switch>
      <Route path="/:locationId/presto-locations" component={Dashboard} exact />
      <Route
        path="/:locationId/presto-locations/opening-times"
        component={OpeningTimes}
      />
      <Route
        path="/:locationId/presto-locations/basic-info"
        component={BasicInfo}
      />

      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default LocationAppRoutes;

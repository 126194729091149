import { PaletteType } from "@material-ui/core";

export default {
  palette: {
    type: "dark" as PaletteType,
    primary: {
      main: "#00e676",
    },
    secondary: {
      main: "#ffeb3b",
    },
    background: {
      paper: "#424957",
      default: "#1E2027",
      sidebarBackground: "#15161D",
      imgBackground: "#61687a",
      entity_background: "#32363E",
      entity_highlight_background: "#444850",
      entity_highlight_background_Default: "#444850",
      entity_highlight_background_mode_title: "#494a50",
      entity_highlight_background_mode_prev: "#5a5b61",
      entity_highlight_background_mode_current: "#444850",
      entity_highlight_background_body_mode: "#313336",
      entity_highlight_background_body_current: "#292b30",
      entity_highlight_background_mode_footer: "#202020",
      entity_highlight_background_prev_footer: "#2f3236",
      entity_highlight_background_current_footer: "#191a1d",
      table_header_background: "#2C3341",
      g_line_1: "#2D79B1",
      g_line_2: "#B89625",
      g_line_3: "#AC3B52",
      g_line_4: "#775597",
      g_line_5: "#3A9D76",
      g_line_lighter_1: "#174465",
      g_line_lighter_2: "#8c6e1c",
      g_line_lighter_3: "#82293f",
      g_line_lighter_4: "#53456b",
      g_line_lighter_5: "#308160",
      g_line_lighter_6: "#296ea1",
      entity_border: "#32363E",
      entity_border_highlight_background: "#444850",
      entity_border_entity_background: "#32363E",
    },
    error: {},
    warning: {},
    info: {},
    contrastThreshold: 3,
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      hint: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.5)",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(255, 255, 255, 0.16)",
      selectedOpacity: 0.16,
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(255, 255, 255, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    custom: {
      yellow: {
        main: "#B89625",
        dark: "#B89625",
        light: "#B89625",
        contrastText: "#000",
      },
      green: {
        main: "#367760",
        dark: "#367760",
        light: "#367760",
        contrastText: "#D7D7D7",
        secondary: "#39d388",
      },
      blue: {
        main: "#2D79B1",
        dark: "#2D79B1",
        light: "#2D79B1",
        contrastText: "#D7D7D7",
        secondary: "#3e99c7",
      },
      orange: {
        main: "#BA7134",
        dark: "#BA7134",
        light: "#BA7134",
        contrastText: "#D7D7D7",
      },
      red: {
        main: "#AC3B52",
        dark: "#AC3B52",
        light: "#AC3B52",
        contrastText: "#D7D7D7",
        secondary: "#b83682",
      },
      purple: {
        main: "#775597",
        dark: "#775597",
        light: "#775597",
        contrastText: "#D7D7D7",
      },
      grey: {
        main: "#7D7D7D",
        dark: "#7D7D7D",
        light: "#7D7D7D",
        contrastText: "#D7D7D7",
      },
    },
  },
};

import {
  Button,
  makeStyles,
  createStyles,
  Typography,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { SORTING_TYPE_ASC, SORTING_TYPE_DESC } from "../../../utils/consts";
import { CustomColor } from "../../../types/customTheme";
import _ from "lodash";

export interface SortingMethodProps {
  handleChangeOrderListSorting?: any;
  handleRemoveOrderListSorting?: any;
  headerName: any;
  headerId: any;
  disableButton: any;
  color: CustomColor;
  isSort?: any;
  sortingMethod?: any;
}

type styleProps = {
  headerId: number;
  color: CustomColor;
};

const headerIdCheck = (headerId: any) => {
  if (headerId === "deliveryType" || headerId === "customer") {
    return "block";
  } else {
    return "flex";
  }
};

const useStyles = makeStyles<any, styleProps>((theme: any) =>
  createStyles({
    headerItemStyle: {
      fontSize: "12px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "9px",
      },
      color: ({ color }) =>
        color ? theme.palette.custom[color].contrastText : "",
    },
    alignStyle: {
      placeItems: "center",
      [theme.breakpoints.up("md")]: {
        display: ({ headerId }: any) => headerIdCheck(headerId),
        minWidth: "50px",
      },
    },
    iconStyle: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "start",
      },
    },
  }),
);

/* This component sorts the data in a table using the header of that table. 
   Here, the arrow up icon and arrow down icon used for sorting is changed according to the sorting method. */
const SortingMethod: React.FunctionComponent<SortingMethodProps> = ({
  handleChangeOrderListSorting,
  handleRemoveOrderListSorting,
  headerName,
  headerId,
  disableButton,
  color,
  isSort,
  sortingMethod,
}) => {
  /* Sort the data in ascending order. */
  const handleChangeOrderListSortingAsc = (headerName: any) => {
    handleChangeOrderListSorting(headerName, SORTING_TYPE_ASC);
  };

  /* Sort the data in descending order. */
  const handleChangeOrderListSortingDesc = (headerName: any) => {
    handleChangeOrderListSorting(headerName, SORTING_TYPE_DESC);
  };

  /* After clicking the button for the first time, sort the data in descending order. */
  const handleChangeSortingMethod = (headerName: any) => {
    handleChangeOrderListSorting(headerName, SORTING_TYPE_DESC);
  };

  /* Remove the sorting method. */
  const handleChangeRemoveSortingMethod = () => {
    handleRemoveOrderListSorting();
  };

  const classes = useStyles({ headerId, color });
  return (
    <div className={classes.alignStyle}>
      <Button
        disabled={disableButton}
        onClick={() => handleChangeSortingMethod(headerId)}
      >
        <Typography
          align="left"
          style={{ fontWeight: "bolder", textTransform: "none" }}
          className={classes.headerItemStyle}
        >
          {headerName}
        </Typography>
      </Button>
      {isSort && (
        <div className={classes.iconStyle}>
          {sortingMethod === SORTING_TYPE_ASC ? (
            <ArrowDropUpIcon
              onClick={() => handleChangeOrderListSortingDesc(headerId)}
              style={{ color: "#25ACE3", cursor: "pointer" }}
            />
          ) : (
            <ArrowDropDownIcon
              onClick={() => handleChangeOrderListSortingAsc(headerId)}
              style={{ color: "#25ACE3", cursor: "pointer" }}
              // color={"secondary"}
            />
          )}
          <CloseIcon
            onClick={handleChangeRemoveSortingMethod}
            style={{
              fontSize: "18px",
              marginTop: "3px",
              cursor: "pointer",
            }}
            color={"error"}
          />
        </div>
      )}
    </div>
  );
};

export default SortingMethod;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-scroll";

import SkeleTonLoading from "../Loading/SkeleTonLoading";
import NoSalesFound from "../NoSalesFound";
import PageHeaderMobile from "../PageHeader/PageHeaderMoblie";
import ButtonCommon from "../../buttons/ButtonCommon";
import { buttonColors } from "../../../utils/enum";
import { CustomTheme } from "../../../types/customTheme";
import { getWindowDimensions } from "../../../utils/windowDimension";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    maxWidth: "calc(100vw - 360px)",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "calc(100vw - 150px)",
    },
  },
  rootDesktop: {
    maxWidth: "calc(100vw - 628px)",
  },
  rootMobile: {
    maxWidth: "calc(100vw - 448px)",
  },
  textFieldOpen: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    borderRadius: "10px",
    border: "none",
    minWidth: "calc(100vw - 108px)",
    backgroundColor: theme.palette.background.entity_highlight_background,
    [`& fieldset`]: {
      borderRadius: "10px",
      border: "none",
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  textField: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    borderRadius: "10px",
    border: "none",
    minWidth: "200px",
    backgroundColor: theme.palette.background.entity_highlight_background,
    [`& fieldset`]: {
      borderRadius: "10px",
      border: "none",
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  avatarStyle: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "54px",
    height: "54px",
    marginLeft: "4px",
  },
  menuStyleLg: {
    padding: "20px",
    borderRadius: "10px",
    marginRight: "14px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    backgroundColor: theme.palette.background.imgBackground,
  },
  menuStyle: {
    top: "200px",
    padding: "20px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    backgroundColor: theme.palette.background.imgBackground,
  },
  cardStyle: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
  },
  tabletScreen: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    zIndex: 5,
    padding: "0px 6px 16px 8px",
    marginTop: "0px",
  },
  mobileScreen: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    zIndex: 5,
    padding: "8px 26px 16px 12px",
    marginTop: "8px",
  },
  desktopModalOpen: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    zIndex: 5,
    padding: "2px 296px 16px 12px",
    marginTop: "6px",
  },
  desktopModalClose: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    zIndex: 5,
    padding: "2px 108px 16px 12px",
    marginTop: "6px",
  },
}));

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export interface paymentReportProps {
  locationSelectorList: any;
  isOpenSkeletonLoading: any;
  isGetLocationInfo: any;
  topic: any;
  children: any;
  handleOpenCreateModal?: any;
  buttonName: any;
  handleChangeSearch?: any;
  searchName?: any;
  setSearchName?: any;
  isOpen?: any;
  setIsOpen?: any;
  activeGroupId: any;
  setActiveGroupId: any;
  selectedDepartmentId: any;
  stockItemGroupDepartmentNodeInSelector: any;
  isOpenDesktopModal?: any;
  setSelectedDepartmentId: any;
  stockItemGroupDepartmentNodeInSelectorInitial?: any;
  createdId?: any;
  setCreatedId?: any;
  isStockTake?: any;
}

/**
 * This React component represents a UI element for displaying inventory details.
 * It provides functionality for filtering and searching inventory items, as well as
 * selecting different inventory categories and departments. It also includes a mobile-
 * friendly interface and various responsive design features.
 */
const InventoryDetails: React.FunctionComponent<paymentReportProps> = ({
  isOpenSkeletonLoading,
  topic,
  children,
  handleOpenCreateModal,
  buttonName,
  handleChangeSearch,
  searchName,
  setSearchName,
  isOpen,
  setIsOpen,
  activeGroupId,
  setActiveGroupId,
  selectedDepartmentId,
  stockItemGroupDepartmentNodeInSelector,
  isOpenDesktopModal,
  setSelectedDepartmentId,
  stockItemGroupDepartmentNodeInSelectorInitial,
  createdId,
  setCreatedId,
  isStockTake,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const theme: CustomTheme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const tabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const open = Boolean(anchorEl);
  const classes = useStyles();

  useEffect(() => {
    if (!_.isEmpty(createdId)) {
      stockItemGroupDepartmentNodeInSelector.map((group: any, index: any) => {
        if (group.id === createdId.stockDepartmentId.stockGroupId.id) {
          group.stockDepartmentId.map((department: any) => {
            if (department.id === createdId.stockDepartmentId.id) {
              department.item.map((item: any) => {
                if (item.id === createdId.id) {
                  setActiveGroupId(index);
                }
              });
            }
          });
        }
      });
    }
    setSelectedDepartmentId(
      stockItemGroupDepartmentNodeInSelector[activeGroupId]
        ?.stockDepartmentId[0]?.id,
    );
  }, [activeGroupId, stockItemGroupDepartmentNodeInSelector]);

  const handleChange = (val: any, catMenuIdx: any) => {
    setCreatedId([]);
    setActiveGroupId(catMenuIdx);
  };

  const handleClose = (e?: any) => {
    setSelectedDepartmentId(e.id);
    setAnchorEl(null);
  };

  const handleClick = (e: any) => {
    setAnchorEl(null);
  };

  const handleClearButton = () => {
    if (searchName || isOpen) {
      handleChangeSearch("");
      setSearchName("");
      setIsOpen(false);
    }
  };
  const handleOpenSearch = () => {
    setIsOpen(true);
  };

  const handleOpenDepartmentList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStockDepartmentPopUpView = () => {
    if (!_.isEmpty(stockItemGroupDepartmentNodeInSelectorInitial)) {
      return stockItemGroupDepartmentNodeInSelectorInitial.map(
        (department: any, index: number) =>
          index === activeGroupId &&
          department.stockDepartmentId.map((departmentData: any) => (
            <div
              style={{
                maxHeight: `${windowDimensions.height}px`,
              }}
              onClick={handleClick}
            >
              {" "}
              <MenuItem
                style={{
                  padding: "0px",
                  display: "flex",
                }}
              >
                <Link
                  onClick={() => handleClose(departmentData)}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    padding: "12px",
                  }}
                  activeClass="active"
                  to={departmentData.name}
                  spy={true}
                  smooth={true}
                  offset={tabletScreen ? -284 : -274}
                  duration={500}
                  delay={50}
                >
                  <Typography
                    style={
                      selectedDepartmentId === departmentData.id
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                  >
                    {departmentData.name}
                  </Typography>
                </Link>
              </MenuItem>
              <Divider />
            </div>
          )),
      );
    }
  };

  const rootStyle = () => {
    if (tabletScreen) {
      if (mobileScreen) {
        return classes.tabletScreen;
      } else {
        return classes.mobileScreen;
      }
    } else if (isOpenDesktopModal) {
      return classes.desktopModalOpen;
    } else {
      return classes.desktopModalClose;
    }
  };

  const tabStyle = () => {
    let rootStyle;

    if (stockItemGroupDepartmentNodeInSelectorInitial.length === 1) {
      rootStyle = classes.rootCenterTabs;
    } else if (tabletScreen) {
      rootStyle = classes.root;
    } else if (isOpenDesktopModal) {
      rootStyle = classes.rootDesktop;
    } else {
      rootStyle = classes.rootMobile;
    }

    // Then use rootStyle in your component
    return {
      root: rootStyle,
    };
  };

  const handleTabView = () => {
    return (
      <Tabs
        classes={tabStyle()}
        value={activeGroupId}
        onChange={handleChange}
        variant="scrollable"
        selectionFollowsFocus
        scrollButtons="on"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="scrollable category menu items list"
      >
        {!_.isEmpty(stockItemGroupDepartmentNodeInSelectorInitial) &&
          stockItemGroupDepartmentNodeInSelectorInitial.map(
            (group: any, idx: number) => (
              <Tab
                key={String(idx)}
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    {group.name}
                  </Typography>
                }
                {...a11yProps(group)}
              />
            ),
          )}
      </Tabs>
    );
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={rootStyle()}>
              <Grid item xs={12}>
                <Hidden lgUp>
                  <PageHeaderMobile />
                </Hidden>
                {!isOpenSkeletonLoading && (
                  <Grid
                    container
                    style={{
                      marginTop: "36px",
                    }}
                  >
                    <Grid
                      item
                      xs={!isStockTake ? 6 : 12}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Typography
                        variant="h4"
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        color="secondary"
                      >
                        {topic}
                      </Typography>
                    </Grid>
                    {!isStockTake && (
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <ButtonCommon
                          style={{
                            marginRight: 4,
                            width: "180px",
                          }}
                          variant="contained"
                          color={buttonColors.CREATE_BUTTON_COLOR}
                          onClick={handleOpenCreateModal}
                        >
                          <Typography
                            style={{ textTransform: "none", color: "white" }}
                          >
                            {buttonName}
                          </Typography>
                        </ButtonCommon>
                      </Grid>
                    )}
                  </Grid>
                )}
                {!isOpenSkeletonLoading && (
                  <Grid
                    container
                    style={{
                      marginTop: "12px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {mobileScreen && !isOpen && handleTabView()}
                        <Hidden xsDown>
                          <div>{handleTabView()}</div>
                        </Hidden>
                        {mobileScreen && !isOpen && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <SearchIcon
                              onClick={handleOpenSearch}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                            <Avatar
                              onClick={handleOpenDepartmentList}
                              className={classes.avatarStyle}
                            >
                              <ListIcon
                                style={{
                                  fontSize: "30px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                              />
                            </Avatar>
                            <Popover
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                className: tabletScreen
                                  ? classes.menuStyleLg
                                  : classes.menuStyle,
                              }}
                              style={{
                                maxHeight: `${windowDimensions.height}px`,
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Card className={classes.cardStyle}>
                                {handleStockDepartmentPopUpView()}
                              </Card>
                            </Popover>
                          </div>
                        )}

                        <div style={{ display: "flex", alignItems: "center" }}>
                          {mobileScreen && isOpen && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextField
                                classes={{ root: classes.textFieldOpen }}
                                id="outlined-inline-static"
                                placeholder="Search for a item"
                                variant="outlined"
                                onChange={(e: any) =>
                                  handleChangeSearch(e.target.value)
                                }
                                value={searchName}
                                style={{
                                  height: "40px",
                                  marginTop: "4px",
                                  marginBottom: "4px",
                                  width: "!00%",
                                }}
                                fullWidth
                                size="small"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={handleClearButton}
                                      >
                                        {isOpen ? (
                                          <ClearIcon />
                                        ) : (
                                          <SearchIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <Avatar
                                onClick={handleOpenDepartmentList}
                                className={classes.avatarStyle}
                              >
                                <ListIcon
                                  style={{
                                    fontSize: "30px",
                                    color:
                                      theme.palette.custom.orange.contrastText,
                                  }}
                                />
                              </Avatar>
                              <Popover
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  className: tabletScreen
                                    ? classes.menuStyleLg
                                    : classes.menuStyle,
                                }}
                                style={{
                                  maxHeight: `${windowDimensions.height}px`,
                                }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Card className={classes.cardStyle}>
                                  {handleStockDepartmentPopUpView()}
                                </Card>
                              </Popover>
                            </div>
                          )}
                          <Hidden xsDown>
                            <TextField
                              classes={{ root: classes.textField }}
                              id="outlined-inline-static"
                              placeholder="Search for a item"
                              variant="outlined"
                              onChange={(e: any) =>
                                handleChangeSearch(e.target.value)
                              }
                              value={searchName}
                              style={{ width: "240px", height: "40px" }}
                              fullWidth
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      edge="end"
                                      onClick={handleClearButton}
                                    >
                                      {searchName ? (
                                        <ClearIcon />
                                      ) : (
                                        <SearchIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Avatar
                              onClick={handleOpenDepartmentList}
                              className={classes.avatarStyle}
                            >
                              <ListIcon
                                style={{
                                  fontSize: "30px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                              />
                            </Avatar>
                            <Popover
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                className: tabletScreen
                                  ? classes.menuStyleLg
                                  : classes.menuStyle,
                              }}
                              style={{
                                maxHeight: `${windowDimensions.height}px`,
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Card className={classes.cardStyle}>
                                {handleStockDepartmentPopUpView()}
                              </Card>
                            </Popover>
                          </Hidden>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isOpenSkeletonLoading ? (
          <>
            {!_.isEmpty(stockItemGroupDepartmentNodeInSelectorInitial) ? (
              <div
                style={
                  tabletScreen
                    ? { padding: "8px 8px 8px 8px" }
                    : { padding: "8px 16px 8px 8px" }
                }
              >
                {children}
              </div>
            ) : (
              <div style={{ marginTop: "160px" }}>
                <NoSalesFound title={"Stock Items"} />
              </div>
            )}
          </>
        ) : (
          <div
            style={
              tabletScreen
                ? { marginTop: "60px", padding: "8px 4px 8px 8px" }
                : { marginTop: "20px", padding: "8px 16px 8px 8px" }
            }
          >
            <SkeleTonLoading isReport={false} />
          </div>
        )}
      </Box>
    </>
  );
};

export default InventoryDetails;

import { Grid } from "@material-ui/core";
import React from "react";
import StockItemDetailsNode from "./StockItemDetailsNode";

export interface DiscountDetailsTypesProps {
  nodeData: Array<any>;
  locationName: any;
  updateTime: any;
  createdTime: any;
  handleOpenStockLevelModal: any;
}

/**
 * StockItemDetails component displays detailed information about a stock item.
 */
const StockItemDetails: React.FunctionComponent<DiscountDetailsTypesProps> = ({
  nodeData,
  locationName,
  updateTime,
  createdTime,
  handleOpenStockLevelModal,
}) => {
  return (
    <Grid container style={{ marginTop: "8px" }}>
      <Grid item xs={12}>
        <StockItemDetailsNode
          nodeData={nodeData}
          locationName={locationName}
          updateTime={updateTime}
          createdTime={createdTime}
          handleOpenStockLevelModal={handleOpenStockLevelModal}
        />
      </Grid>
    </Grid>
  );
};

export default StockItemDetails;

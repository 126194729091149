import React, { useCallback, useEffect, useState } from "react";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";
import {
  createDeliveryInfo,
  deleteDeliveryInfo,
  updateDeliveryInfo,
} from "../../../../services/eatprestoApp/deliveryInfoService";
import ConfirmDeleteDialog from "../../components/dialogs/ConfirmDeleteDialog";
import DeliveryArea from "./DeliveryArea";
import {
  combineMinuteOfWeekArrays,
  normalizeTimeSelections,
} from "../../../../utils/time-conversions";
import CardCommon from "../../../../components/card/CardCommon";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import { CustomTheme } from "../../../../types/customTheme";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_DELETING_ERROR,
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { HttpStatus, submitButtonName } from "../../../../utils/enum";
import { AxiosError } from "axios";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    base: {
      padding: "16px 16px 0 16px",
      borderRadius: 4,
    },
    header: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      marginBottom: 8,
    },
    error: {
      backgroundColor: theme.palette.error.dark,
      borderRadius: "8px 8px 0 0",
      border: `1px solid ${theme.palette.background.entity_border}`,
      padding: 8,
    },
    actionsWrapper: {
      padding: "16px 16px",
      display: "flex",
      justifyContent: "center",
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface DeliveryInfoNodeProps {
  nodeData: any;
  type: string;
  getDeliveryInfo: any;
  isAuthorized: boolean;
  handleCopyNode: any;
  handleRemoveNode: (type: string, uuid: string) => void;
  setIsLoadingButton: any;
  isLoadingButton: any;
}

/* Create, delete, and update delivery information and users can view all delivery information. */
const DeliveryInfoNode: React.FunctionComponent<DeliveryInfoNodeProps> = ({
  nodeData,
  getDeliveryInfo,
  type,
  handleRemoveNode,
  handleCopyNode,
  isAuthorized,
  setIsLoadingButton,
  isLoadingButton,
}) => {
  const [deliveryType, setDeliveryType] = useState("PICKUP");
  const [paymentTypes, setPaymentTypes] = useState<any>([]);
  const [minOrderPrice, setMinOrderPrice] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [delayMins, setDelayMins] = useState(0);
  const [availability, setAvailability] = useState([]);
  const [area, setArea] = useState({ title: "", data: { distance: 0 } });
  const [charge, setCharge] = useState(0);
  const [error, setError] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [nodeID, setNodeID] = useState("");
  const [success, setSuccess] = useState("");
  const [availabilityErrorMessage, setAvailabilityErrorMessage] =
    useState(false);
  const [originalNodeData, setOriginalNodeData] = useState({
    type: "",
    availability: [],
    area: {},
    charge: 0,
    delayMins: 0,
    isActive: false,
    minOrderPrice: 0,
    paymentTypes: [],
  });

  const classes = useStyles();
  const match: any = useRouteMatch();

  /* Get updated data after changes. */
  const getNodeDataByState = () => {
    const updatedData = _.cloneDeep(originalNodeData);
    updatedData.type = deliveryType;
    updatedData.availability = availability;
    updatedData.area = area;
    updatedData.charge = charge;
    updatedData.delayMins = delayMins;
    updatedData.isActive = isActive;
    updatedData.minOrderPrice = minOrderPrice;
    updatedData.paymentTypes = paymentTypes;
    return updatedData;
  };

  /* Update states after creating delete or update banner information. */
  const setNodeDataToState = useCallback((nodeData) => {
    const {
      id,
      availability,
      area,
      charge,
      delayMins,
      isActive,
      minOrderPrice,
      paymentTypes,
      type,
    } = nodeData;
    setNodeID(id);
    setIsActive(isActive);
    setDeliveryType(type);
    setMinOrderPrice(minOrderPrice);
    setDelayMins(delayMins);
    setAvailability(availability);
    setArea(area);
    setCharge(charge);
    setPaymentTypes(paymentTypes);
  }, []);

  useEffect(() => {
    setOriginalNodeData(nodeData);
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /* Update the paymentTypeList after selecting the payment options using switches (cash, card). */
  const handleSelectPaymentTypes = (e: any) => {
    const { value, name, checked } = e.target;
    // When a payment type option is selected, that payment type option is entered into a list.
    if (checked) {
      const selectedList: any = new Set([...paymentTypes, value]);
      setPaymentTypes([...selectedList]);
    } else {
      // When a payment type option is not selected, that payment type option is removed into a list.
      const selectedList: any = paymentTypes.filter(
        (paymentType: String) => name !== paymentType,
      );
      setPaymentTypes(selectedList);
    }
  };

  /* Update the deliveryType state after selecting the delivery options using selector
  (dine-in, pickup, and delivery). */
  const handleSelectDeliveryType = (e: any) => {
    const { value } = e.target;
    setDeliveryType(value);
  };

  /* Active switch status change using switch */
  const handleSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsActive(checked);
  };

  /* Send an API call to update delivery information after click save changes button. */
  const handleSave = async () => {
    setAvailabilityErrorMessage(false);
    setError("");
    const updatedData = getNodeDataByState();
    try {
      const res = await updateDeliveryInfo(
        match.params.locationId,
        nodeID,
        updatedData,
      );
      getDeliveryInfo();
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      const err: any = error as AxiosError;

      if (err.response.status === 409) {
        getDeliveryInfo();
        setError(ERROR_MESSAGE_VERSION_ERROR);
      } else {
        setError(ERROR_MESSAGE_UPDATING_ERROR);
      }
    }
  };

  /* Send an API call to crete delivery information after click create button. */
  const handleCreateDeliveryOption = async () => {
    setError("");
    setAvailabilityErrorMessage(false);
    const data = getNodeDataByState();
    try {
      await createDeliveryInfo(match.params.locationId, data);
      getDeliveryInfo();
      setSuccess(SUCCESSFULLY_CREATED);
      setIsCreated(true);
    } catch (error) {
      const err: any = error as AxiosError;
      if (err.response.status === HttpStatus.CONFLICT_409) {
        setError(ERROR_MESSAGE_VERSION_ERROR);
        getDeliveryInfo();
      } else {
        setError(ERROR_MESSAGE_CREATING_ERROR);
      }
    }
  };

  /* Send an API call to delete delivery information  after click delete button. */
  const handleDeleteDeliveryOption = async () => {
    setError("");
    setAvailabilityErrorMessage(false);
    try {
      await deleteDeliveryInfo(match.params.locationId, nodeID);
      getDeliveryInfo();
      setOpenDeleteConfirm(false);
      setSuccess(SUCCESSFULLY_DELETED);
    } catch (error) {
      setOpenDeleteConfirm(false);
      const err: any = error as AxiosError;
      if (err.response.status === HttpStatus.CONFLICT_409) {
        setError(ERROR_MESSAGE_VERSION_ERROR);
        getDeliveryInfo();
      } else {
        setError(ERROR_MESSAGE_DELETING_ERROR);
      }
    }
  };

  /* Change the delivery title */
  const handleTitleChange = (e: any) => {
    const { value } = e.target;
    const areaUpdated = { ...area };
    areaUpdated.title = value;
    setArea(areaUpdated);
  };

  /* Reset states after clicking the cancel button. */
  const resetToOriginalData = () => {
    setNodeDataToState(originalNodeData);
  };

  const updatedData = getNodeDataByState();
  // Checking if a change has occurred in the form.
  const isSame = _.isEqual(originalNodeData, updatedData);
  const { newId } = nodeData;
  const deleteFunc = isCreated
    ? handleDeleteDeliveryOption
    : newId
    ? () => handleRemoveNode(type, newId)
    : () => setOpenDeleteConfirm(true);

  /* Check for a create or update and 
  call the handleCreateDeliveryOption function if it is a create and 
  handleSave function if it is a update.
  Only update or save data if minOrderPrice, charge, and delayMins are 0 or greater than 0. */
  const handleSubmit = () => {
    if (
      (!_.isEmpty(minOrderPrice) && minOrderPrice < 0) ||
      (!_.isEmpty(charge) && charge < 0) ||
      (!_.isEmpty(delayMins) && delayMins < 0)
    ) {
    } else {
      if (!area.title) return;
      if (nodeData.status === "create" && !isCreated) {
        return handleCreateDeliveryOption();
      }
      return handleSave();
    }
  };

  /* Send an API call to after click availability apply button. 
  An API call is sent only if the status is not "create". */
  const handleSubmitAvailability = async (
    availabilitySelectionList: Array<any>,
  ) => {
    setAvailabilityErrorMessage(false);
    try {
      /* availabilitySelectionList = [{openTime: '11:49', closeTime: '11:49', id: 2, 
                                         nodeList: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                                    {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]}]
           combinedMowArrays = {tue: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                thu:  {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]} */
      const combinedMowArrays: any = combineMinuteOfWeekArrays(
        availabilitySelectionList,
      );
      let normalizedArray = _.cloneDeep(combinedMowArrays);

      // Remove select same date range in same same date.
      Object.keys(combinedMowArrays).forEach((day) => {
        normalizedArray[day] = normalizeTimeSelections(normalizedArray[day]);
      });

      // Convert array to minute of week array
      const mowArray: any = Object.values(normalizedArray).flat();
      const data = _.cloneDeep(originalNodeData);

      // Update data
      data.availability = mowArray;

      // If the discount has been created, add new 'availability'.
      await updateDeliveryInfo(
        match.params.locationId,
        nodeID,
        data,
      );
      getDeliveryInfo();
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      const err: any = error as AxiosError;
      if (err.response.status === HttpStatus.CONFLICT_409) {
        setError(ERROR_MESSAGE_VERSION_ERROR);
        getDeliveryInfo();
      } else {
        setError(ERROR_MESSAGE_UPDATING_ERROR);
      }
    }
  };
  return (
    <Grid item sm={12} md={6} xs={12} lg={4} style={{ display: "flex" }}>
      <ConfirmDeleteDialog
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        confirmAction={handleDeleteDeliveryOption}
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <CardCommon backgroundColor={"entity_background"}>
        {error && (
          <Typography component="div" className={classes.error} variant="body1">
            {error}
          </Typography>
        )}
        <form className={classes.base}>
          <Typography variant="h5" align="left">
            {area?.title}
          </Typography>
          <Divider variant="fullWidth" style={{ margin: "4px 0 8px 0" }} />
          <div className={classes.header}>
            <div>
              <TextfieldCommon
                style={{ flex: 2 }}
                label="Area title"
                name="ariaTitle"
                id="ariaTitle"
                type={"text"}
                value={area?.title}
                onChange={handleTitleChange}
                disabled={!isAuthorized}
                inputProps={{ maxLength: 100 }}
                error={!area.title}
              />
              {!area.title && (
                <ValidationMessage message={"Title can not be empty"} />
              )}
            </div>
            <FormControlLabel
              style={{ flex: 1 }}
              value="card"
              control={<SwitchCommon />}
              checked={isActive}
              onChange={handleSwitchActivate}
              label="Active"
              labelPlacement="start"
              disabled={!isAuthorized}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6}>
              <TextfieldCommon
                label="Minimum order price"
                id="minOrderPrice"
                name="minOrderPrice"
                type="number"
                value={minOrderPrice}
                onChange={(e: any) => {
                  if (e.target.value.length > 4) return;
                  setMinOrderPrice(e.target.value);
                }}
                disabled={!isAuthorized}
              />
              {minOrderPrice < 0 && (
                <ValidationMessage message="Invalid limit entered" />
              )}
            </Grid>
            <Grid item md={6} xs={6}>
              <TextfieldCommon
                label="Charge"
                id="charge"
                name="charge"
                inputProps={{ max: 9999 }}
                type="number"
                value={charge}
                onChange={(e: any) => {
                  if (e.target.value.length > 4) return;
                  setCharge(e.target.value);
                }}
                disabled={!isAuthorized}
              />
              {charge < 0 && (
                <ValidationMessage message="Invalid limit entered" />
              )}
            </Grid>
            <Grid item md={6} xs={6}>
              <TextField
                fullWidth
                id="deliveryType"
                select
                label="Delivery type"
                className={classes.textField}
                value={deliveryType}
                size="small"
                onChange={handleSelectDeliveryType}
                // helperText="Please select your currency"
                variant="outlined"
                disabled
              >
                <MenuItem value="takeout">Pick up</MenuItem>
                <MenuItem value="delivery">Delivery</MenuItem>
                <MenuItem value="dinein">Dine in</MenuItem>
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={6}
              style={{
                padding: 8,
                // border: "1px solid gray",
                borderRadius: 8,
              }}
            >
              {/* <Typography>Orders&nbsp;are&nbsp;ready&nbsp;in&nbsp;</Typography> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label="Order ready time"
                  variant="outlined"
                  fullWidth
                  type="number"
                  size="small"
                  value={delayMins}
                  className={classes.textField}
                  onChange={(e: any) => {
                    if (e.target.value.length > 4) return;
                    setDelayMins(e.target.value);
                  }}
                  disabled={!isAuthorized}
                />
              </div>
              {delayMins < 0 && (
                <ValidationMessage message="Invalid limit entered" />
              )}
            </Grid>
          </Grid>
          <AvailabilitySelection
            nodeData={originalNodeData}
            disabled={!isAuthorized}
            handleSubmit={handleSubmitAvailability}
            setAvailabilityErrorMessage={setAvailabilityErrorMessage}
          />
          {availabilityErrorMessage && (
            <ValidationMessage
              message={"Please create a delivery information before updating."}
            />
          )}
          <Grid container spacing={1} style={{ placeItems: "center" }}>
            <Grid item>
              <FormGroup
                style={{
                  padding: 8,
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start",
                }}
                row
              >
                <FormControlLabel
                  value="cash"
                  disabled={!isAuthorized}
                  control={<SwitchCommon />}
                  label="Cash"
                  name="cash"
                  checked={paymentTypes.includes("cash")}
                  labelPlacement="start"
                  onChange={handleSelectPaymentTypes}
                />
                <FormControlLabel
                  value="card"
                  disabled={!isAuthorized}
                  name="card"
                  control={<SwitchCommon />}
                  label="Card"
                  checked={paymentTypes.includes("card")}
                  labelPlacement="start"
                  onChange={handleSelectPaymentTypes}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              {deliveryType === "delivery" && (
                <DeliveryArea area={area} setArea={setArea} />
              )}
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <ButtonCommon
                variant="contained"
                color="blue"
                onClick={() => handleCopyNode(originalNodeData)}
              >
                <FileCopyIcon />
              </ButtonCommon>
            </Grid>
          </Grid>
        </form>
        {isAuthorized && (
          <div className={classes.actionsWrapper}>
            <ButtonCommon
              isLoadingPage={isLoadingButton}
              disabled={isSame}
              style={{
                flex: 1,
                fontSize: 11,
                marginRight: 4,
              }}
              variant="contained"
              color={nodeData.status === "create" ? "green" : "yellow"}
              onClick={handleSubmit}
            >
              {nodeData.status === "create" && !isCreated
                ? submitButtonName.CREATE_SUBMIT_BUTTON
                : submitButtonName.UPDATE_SUBMIT_BUTTON}
            </ButtonCommon>
            <ButtonCommon
              disabled={isSame}
              variant="contained"
              style={{ fontSize: 11, flex: 1 }}
              color="orange"
              startIcon={<RotateLeftIcon />}
              onClick={resetToOriginalData}
            >
              Cancel
            </ButtonCommon>
            {isAuthorized && (
              <ButtonCommon
                variant="contained"
                style={{
                  fontSize: 11,
                  flex: 1,
                  marginLeft: 4,
                }}
                color="red"
                startIcon={<DeleteIcon />}
                onClick={deleteFunc}
              >
                Delete
              </ButtonCommon>
            )}
          </div>
        )}
      </CardCommon>
    </Grid>
  );
};

export default withAuthority(DeliveryInfoNode, Authorities.DELIVERY_INFO_READ);

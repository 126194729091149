import React from "react";
import _ from "lodash";
import TextfieldCommon from "../../textField/TextfieldCommon";
import { IconButton } from "@material-ui/core";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

export interface IncrementDecrementTextboxProps {
  handleDecrement: any;
  handleIncrement: any;
  initialOrderQty: any;
  setInitialOrderQty: any;
  isDisable?: any
}

/**
 * This component provides an input field with increment and decrement buttons for adjusting quantity values.
 * It is designed to work with a default initial order quantity and provides functions for handling the increment
 * and decrement actions. This component is commonly used in various parts of the application to allow users to
 * modify quantities easily.
 */
const IncrementDecrementTextboxDefault: React.FunctionComponent<
  IncrementDecrementTextboxProps
> = ({
  handleDecrement,
  handleIncrement,
  initialOrderQty,
  setInitialOrderQty,
  isDisable
}) => {
  return (
    <>
      <IconButton onClick={handleDecrement} disabled={isDisable}>
        <IndeterminateCheckBoxOutlinedIcon
          style={{
            fontSize: "16px",
          }}
        />
      </IconButton>
      <TextfieldCommon
        id="outputMultiplier"
        name="outputMultiplier"
        label=""
        type="number"
        disabled={isDisable}
        style={{ width: "80px" }}
        value={initialOrderQty}
        onChange={(event: any) => setInitialOrderQty(event.target.value)}
      />
      <IconButton onClick={handleIncrement}  disabled={isDisable}>
        <AddBoxOutlinedIcon
          style={{
            fontSize: "16px",
          }}
        />
      </IconButton>
    </>
  );
};

export default IncrementDecrementTextboxDefault;

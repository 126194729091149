import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import Shift from "../pages/Shift";
import DashboardReport from "../pages/DashboardReport";
import PayInOut from "../pages/PayInOut";
import PaymentReport from "../pages/PaymentReport";
import Sales from "../pages/Sales";
import CancelSale from "../pages/CancelSale";
import SaleItemsReport from "../pages/SaleItemsReport";
import VoidSummery from "../pages/VoidSummery";
import VoidTransaction from "../pages/VoidTransaction";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

import DailySaleReport from "../pages/DailySaleReport";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "SALES_DASHBOARD",
      title: "Dashboard",
      path: "/dashboard",
      icon: <span className="material-symbols-outlined">Dashboard</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "SALES",
      title: "Sales",
      path: "/salesTransaction",
      icon: <span className="material-symbols-outlined">list_alt</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "SHIFT",
      title: "Shifts",
      path: "/shiftReports",
      icon: <span className="material-symbols-outlined">description</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "ITEMS",
      title: "Item Sales Report",
      path: "/itemsSalesReport",
      icon: (
        <span className="material-symbols-outlined">format_list_numbered</span>
      ),
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "DAILY_SALE",
      title: "Daily Sales Report",
      path: "/dailySalesReport",
      icon: <DateRangeOutlinedIcon />,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "PAYINOUt",
      title: "Paid In-Out Report",
      path: "/paidIn-OutReport",
      icon: <span className="material-symbols-outlined">swap_horiz</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "PAYMENT_REPORT",
      title: "Payments Report",
      path: "/paymentTypesReport",
      icon: <span className="material-symbols-outlined">wallet</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "VOID_SUMMERY",
      title: "VAT Summary Report",
      path: "/vATSummaryReport",
      icon: <span className="material-symbols-outlined">assured_workload</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "SALES_CANCEL",
      title: "Cancelled Sales",
      path: "/cancelledSales",
      icon: <span className="material-symbols-outlined">cancel</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "VOID_TRANSACTION",
      title: "Voids",
      path: "/VoidedTransactions",
      icon: <span className="material-symbols-outlined">delete_forever</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
  ];
  return sidebarRoute;
};

export interface SaleAppRoutesProps {}

const SaleAppRoutes: React.FunctionComponent<SaleAppRoutesProps> = () => {
  return (
    <Switch>
      <Route path="/:locationId/shiftReports" component={Shift} exact />
      <Route path="/:locationId/dashboard" component={DashboardReport} exact />
      <Route path="/:locationId/paidIn-OutReport" component={PayInOut} exact />
      <Route
        path="/:locationId/shiftReports/:shiftId"
        component={Shift}
        exact
      />
      <Route
        path="/:locationId/paymentTypesReport"
        component={PaymentReport}
        exact
      />
      <Route path="/:locationId/salesTransaction" component={Sales} exact />
      <Route path="/:locationId/cancelledSales" component={CancelSale} exact />
      <Route
        path="/:locationId/itemsSalesReport"
        component={SaleItemsReport}
        exact
      />
      <Route
        path="/:locationId/itemsSalesReport/:itemId"
        component={SaleItemsReport}
        exact
      />
      <Route
        path="/:locationId/vATSummaryReport"
        component={VoidSummery}
        exact
      />
      <Route
        path="/:locationId/VoidedTransactions"
        component={VoidTransaction}
        exact
      />
      <Route
        path="/:locationId/dailySalesReport"
        component={DailySaleReport}
        exact
      />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default SaleAppRoutes;

import { Hidden, useTheme } from "@material-ui/core";
import * as React from "react";
import PageHeader from "../../../../components/common/PageHeader/PageHeader";
import { CustomTheme } from "../../../../types/customTheme";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface HomeProps {}

const Home: React.FunctionComponent<HomeProps> = () => {
  React.useEffect(() => {
    document.title = "Inventory - Dashboard ";
  }, []);

  const theme: CustomTheme = useTheme();
  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      Dashboard
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import DialogCommon from "../dialogs/DialogCommon";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AddIcon from "@material-ui/icons/Add";
import ValidationMessage from "../validation/ValidationMessage";
import TextfieldCommon from "../textField/TextfieldCommon";
import { useForm } from "react-hook-form";
import { INTEGER_NUMBER_PATTERN } from "../../utils/consts";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { CustomTheme } from "../../types/customTheme";
import CardCommon from "../card/CardCommon";
import { CapitalizeFirstLetter } from "../../utils/ReplaceIcon";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.background.entity_border}`,
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
  }),
);

export interface paymentReportProps {
  register: any;
  errors: any;
  selectedNodeData: any;
  reset: any;
  stockDepartmentSelectedNode: any;
  supplierDetailsNode: any;
  setSelectedSupplierList: any;
  selectedSupplierList: any;
  selectedDepartmentObj: any;
  setSelectedDepartmentObj: any;
  handleOpenDepartmentAddModal: any;
  setSelectedDepartmentObjInitial: any;
  handleOpenSupplierAddModal: any;
}
/* Set the filter, sale title, sale sub title, and table using filter details */
const AddStockItems: React.FunctionComponent<paymentReportProps> = ({
  register,
  errors,
  selectedNodeData,
  reset,
  stockDepartmentSelectedNode,
  supplierDetailsNode,
  setSelectedSupplierList,
  selectedSupplierList,
  selectedDepartmentObj,
  setSelectedDepartmentObj,
  handleOpenDepartmentAddModal,
  setSelectedDepartmentObjInitial,
  handleOpenSupplierAddModal,
}) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  /* Use a react form hooks */
  useEffect(() => {
    if (!_.isEmpty(selectedNodeData)) {
      const {
        name,
        barcode,
        sku,
        displaySuffix,
        inputSuffix,
        inputMultiplier,
        criticalThreshold,
        warningThreshold,
        stockDepartmentId,
      } = selectedNodeData;
      setSelectedDepartmentObjInitial(stockDepartmentId);
      setSelectedDepartmentObj(stockDepartmentId);
      // Changing the value of the text field according to the response.
      reset({
        name,
        barcode,
        sku,
        displaySuffix,
        inputSuffix,
        inputMultiplier,
        criticalThreshold,
        warningThreshold,
      });
    } else {
      reset({
        name: "",
        barcode: "",
        sku: "",
        displaySuffix: "",
        inputSuffix: "",
        inputMultiplier: "",
        criticalThreshold: "",
        warningThreshold: "",
      });
    }
  }, [selectedNodeData]);

  const handleDepartmentChange = (e: any, departmentData: any) => {
    setSelectedDepartmentObj(departmentData);
  };

  const handleColor = (id: string) => {
    if (selectedSupplierList.includes(id)) {
      return "green";
    }
  };

  const handleSelectedSupplier = (id: string) => {
    const cloneSelectedSupplier = _.cloneDeep(selectedSupplierList);
    if (cloneSelectedSupplier.includes(id)) {
      const filterSelectedSupplier = cloneSelectedSupplier.filter(
        (data: any) => data !== id,
      );
      setSelectedSupplierList(filterSelectedSupplier);
    } else {
      cloneSelectedSupplier.push(id);
      setSelectedSupplierList(cloneSelectedSupplier);
    }
  };

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={6} style={{ paddingLeft: "36px", paddingRight: "12px" }}>
        <TextfieldCommon
          id="name"
          name="name"
          type="text"
          label="Name"
          disabled={false}
          inputRef={register({
            required: "Name is required.",
          })}
        />
        {errors.name ? <ValidationMessage message={errors.name.message} /> : ""}
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "12px", paddingRight: "36px" }}>
        <TextfieldCommon
          id="barcode"
          name="barcode"
          type="text"
          label="Barcode"
          disabled={false}
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "36px", paddingRight: "12px" }}>
        <TextfieldCommon
          id="sku"
          name="sku"
          type="text"
          label="Sku"
          disabled={false}
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "12px", paddingRight: "36px" }}>
        <TextfieldCommon
          id="displaySuffix"
          name="displaySuffix"
          type="text"
          label="Display Suffix"
          disabled={false}
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "36px", paddingRight: "12px" }}>
        <TextfieldCommon
          id="inputSuffix"
          name="inputSuffix"
          type="text"
          label="Input Suffix"
          disabled={false}
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "12px", paddingRight: "36px" }}>
        <TextfieldCommon
          id="inputMultiplier"
          name="inputMultiplier"
          type="number"
          label="Input Multiplier"
          disabled={false}
          inputRef={register({
            required: "Input Multiplier is required.",
            min: {
              value: 1,
              message: "Input Multiplier must be greater than to 0.",
            },
          })}
        />
        {errors.inputMultiplier ? (
          <ValidationMessage message={errors.inputMultiplier.message} />
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={6} style={{ paddingLeft: "36px", paddingRight: "12px" }}>
        <TextfieldCommon
          id="criticalThreshold"
          name="criticalThreshold"
          type="number"
          label="Critical Threshold"
          disabled={false}
          inputRef={register({
            required: "Critical Threshold is required.",
            min: {
              value: 1,
              message: "Critical Threshold must be greater than to 0.",
            },
          })}
        />
        {errors.criticalThreshold ? (
          <ValidationMessage message={errors.criticalThreshold.message} />
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={6} style={{ paddingLeft: "12px", paddingRight: "36px" }}>
        <TextfieldCommon
          id="warningThreshold"
          name="warningThreshold"
          type="number"
          label="Warning threshold"
          disabled={false}
          inputRef={register({
            required: "Warning threshold is required.",
            min: {
              value: 1,
              message: "Warning threshold must be greater than to 0.",
            },
          })}
        />
        {errors.warningThreshold ? (
          <ValidationMessage message={errors.warningThreshold.message} />
        ) : (
          ""
        )}
      </Grid>

      <Grid
        item
        xs={12}
        style={{ paddingLeft: "36px", marginTop: "12px", paddingRight: "36px" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>Select Department</Typography>
          <Button
            style={{ textTransform: "none" }}
            color="secondary"
            autoFocus
            // disabled={checkedProductIdList.length === 0 || result}
            onClick={handleOpenDepartmentAddModal}
          >
            {"Add new department"}
          </Button>
        </div>
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          size={"small"}
          id="locationSelectGlobal"
          color="inherit"
          options={stockDepartmentSelectedNode}
          value={selectedDepartmentObj}
          getOptionLabel={(option: any) => option.name || ""}
          style={{ width: 300 }}
          disableClearable
          onChange={handleDepartmentChange}
          classes={{ root: classes.autoComplete }}
          popupIcon={<ArrowDropDownIcon color={"inherit"} />}
          renderOption={(props: any) => {
            return (
              <Grid
                container
                {...props}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  zIndex: 0,
                  height: "20px",
                  marginBottom: "4px",
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.titleColor}>
                    {props.name}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
          renderInput={(params: any) => (
            <TextField
              color="inherit"
              {...params}
              label=""
              placeholder="Select Department"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            paddingLeft: "32px",
            marginTop: "12px",
            paddingRight: "36px",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Select Suppliers</Typography>
              <Button
                style={{ textTransform: "none" }}
                color="secondary"
                autoFocus
                // disabled={checkedProductIdList.length === 0 || result}
                onClick={handleOpenSupplierAddModal}
              >
                {"Add new supplier"}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            {supplierDetailsNode.map((supplier: any) => (
              <Grid item xs={4} style={{padding: "4px"}}>
                <CardCommon
                  color={handleColor(supplier.id)}
                  backgroundColor={"entity_highlight_background"}
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    fullWidth
                    onClick={() => handleSelectedSupplier(supplier.id)}
                    style={{
                      textTransform: "none",
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                      minHeight: "80px",
                    }}
                  >
                    <Typography
                      style={
                        handleColor(supplier.id)
                          ? {
                              margin: "4px",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                            }
                          : {
                              margin: "4px",
                              display: "flex",
                              alignItems: "center",
                            }
                      }
                    >
                      {CapitalizeFirstLetter(supplier.name)}
                    </Typography>
                  </Button>
                </CardCommon>{" "}
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default AddStockItems;

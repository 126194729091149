import { Button, Typography } from "@material-ui/core";
import React from "react";
import _ from "lodash";

export interface DepartmentSelectMobileViewProps {
  handleButtonClick: any;
  activeGroupId: any;
  selectedStockOrderItemsNode: any;
  selectedDepartmentId: any;
  isDisable: any;
}

/**
 * This component represents the mobile view for selecting departments in the stock order interface.
 * It displays a list of department buttons for the user to choose from.
 */
const DepartmentSelectMobileView: React.FunctionComponent<
  DepartmentSelectMobileViewProps
> = ({
  handleButtonClick,
  activeGroupId,
  selectedStockOrderItemsNode,
  selectedDepartmentId,
  isDisable,
}) => {
  return (
    <>
      {selectedStockOrderItemsNode.map(
        (department: any, index: any) =>
          index === activeGroupId &&
          department.stockDepartmentId.map((data: any) => (
            <div style={{ marginLeft: "32px" }}>
              <Button
                onClick={handleButtonClick(`department ${data.id}`)}
                fullWidth
                style={{
                  textTransform: "none",
                  display: "flex",
                  justifyContent: "start",
                }}
                disabled={isDisable}
              >
                <Typography
                  style={
                    selectedDepartmentId === data.id
                      ? { fontWeight: "bold" }
                      : { fontWeight: "normal" }
                  }
                >
                  {data.name}
                </Typography>
              </Button>
            </div>
          )),
      )}
    </>
  );
};

export default DepartmentSelectMobileView;

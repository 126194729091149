import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box, useTheme } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import PaymentReportInfoNode from "./PaymentReportInfoNode";
import { fetchAllPaymentReportInfo } from "../../../../services/salesApp/paymentReportService";
import { paymentFilterObject } from "../../../../utils/consts/list";
import PageHeader from "../../../../components/common/PageHeader/PageHeader";
import { CustomTheme } from "../../../../types/customTheme";
import SaleReport from "../../../../components/common/SaleReport";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";

export interface paymentReportProps {}

/* Get the payment details using API call and get the location information */
const PaymentReport: React.FunctionComponent<paymentReportProps> = () => {
  const [paymentReportNodeList, setPaymentReportNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /* Get the payment details */
  const getPaymentReportInfo = async (filter: any, basedOnShift: boolean) => {
    setPaymentReportNodeList([]);
    try {
      const res = await fetchAllPaymentReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      let isChecked = false;
      Object.values(res.data.data).map((data: any) => {
        if (!_.isEmpty(data)) {
          isChecked = true;
        }
      });
      if (isChecked) {
        setPaymentReportNodeList(res.data.data);
      } else {
        setPaymentReportNodeList([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get location details. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Payment Report";
    getAllFilterLocation("");
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsLoading(true);
    getPaymentReportInfo(filterData, basedOnShift);
    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={paymentFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={paymentReportNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Payment summary report for"
          topic="Payment Summary"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
        >
          <PaymentReportInfoNode
            paymentReportNodeList={paymentReportNodeList}
            locationSelectorList={locationSelectorList}
            filterDetails={filterDetails}
            filterData={filterData}
            basedOnShift={basedOnShift}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(PaymentReport, Authorities.DASHBOARD_READ);

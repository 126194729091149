import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { fetchAllFilterLocations } from "../../../services/locationApp/locationFilterService";
import {
  fetchAllFilterSalesInfo,
  fetchAllUserInfo,
} from "../../../services/salesApp/salesService";
import { CustomTheme } from "../../../types/customTheme";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  REMOVE_ORDER_CHANNEL,
} from "../../../utils/consts";
import { getCookie } from "../../../utils/cookies";
import DefaultAlert from "../../alerts/DefaultAlert";
import ButtonCommon from "../../buttons/ButtonCommon";
import ModalTableLoading from "../Loading/ModalTableLoading";
import SaleList from "../SaleList";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "../Pagination";

export interface Props {
  openSaleDetailsModal: any;
  setOpenSaleDetailsModal: any;
  filterData: any;
  selectedPaymentMethod?: any;
  basedOnShift?: any;
}

const SaleDetailsModal: React.FunctionComponent<Props> = ({
  openSaleDetailsModal,
  setOpenSaleDetailsModal,
  filterData,
  selectedPaymentMethod,
  basedOnShift,
}) => {
  const [salesNodeList, setSalesNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [userList, setUserList] = useState<any>();
  const [numberOfLocations, setNumberOfLocations] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("date");
  const [sortingMethod, setSortingMethod] = useState("DESC");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);
  const [saleFilterData, setSaleFilterData] = useState<any>();
  const [salePaymentType, setSalePaymentType] = useState("");
  const [isPaymentTypeLoading, setIsPaymentTypeLoading] = useState(false);
  const [isDiscountLoading, setIsDiscountLoading] = useState(false);
  const [isSort, setIsSort] = useState(false);

  const dialogContentRef: any = useRef(null);
  const dialogRef: any = useRef(null);
  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  /* This useEffect hook updates the number of unique locations based on the "locationId" parameter.
     It runs whenever the "locationId" parameter changes. */
  useEffect(() => {
    const locationId: any = params.get("locationId");
    if (!_.isEmpty(locationId)) {
      const numUniqueLocations = locationId.split(",");
      setNumberOfLocations(numUniqueLocations.length);
    }
  }, [params.get("locationId")]);

  useEffect(() => {
    document.title = "Sale - Sale Information";
    setIsLoadingPage(true);
    getAllFilterLocation("");
  }, []);

  useEffect(() => {
    // Check if the dialog ref exists
    if (dialogRef.current) {
      // Check if the content height is greater than the client height
      if (
        dialogContentRef.current.scrollHeight ===
        dialogContentRef.current.clientHeight
      ) {
        // Disable scrolling if content height is less than or equal to client height
        setIsScroll(false);
      } else {
        // Enable scrolling if content height is greater than client height
        setIsScroll(true);
      }
    } else {
      // Disable scrolling if dialog ref does not exist
      setIsScroll(false);
    }
  });

  /* Get sale list using query params */
  const getSaleFilterInfo = async (
    pageSize: any,
    currentPage: any,
    sortingMethod: any,
    headerName: any,
    filter: any,
    selectedPaymentMethod: any,
  ) => {
    try {
      const res = await fetchAllFilterSalesInfo(
        idToken,
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
        filter,
        basedOnShift,
        selectedPaymentMethod,
      );
      setSalesNodeList(res.data.data);
      setTotalPages(res.data.totalPages);
      setIsLoadingPage(false);
      setIsPaymentTypeLoading(false);
      setIsDiscountLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingPage(false);
      setIsPaymentTypeLoading(false);
      setIsDiscountLoading(false);
    }
  };

  /** Function that processes the user details array and updates the user list state object.
   * The user details required for the filter and the user details required to be
   * displayed in the table are entered into the state.
   */
  const handleUserList = (userDetails: any) => {
    let userArray: any = [];
    let userObject: any = {};

    // Loop through the array of user details and add each user's ID and name to the user array and object.
    Object.values(userDetails).map((data: any) => {
      userArray.push({ id: data.id, label: data.name });
      // If the user list state object is not empty and the current user's ID does not exist in the object,
      // add it to the user object. Otherwise, update the existing user object with the current user's ID and name.
      if (!_.isEmpty(userList) && _.isEmpty(userList[data.id])) {
        userObject[data.id] = { id: data.id, label: data.name };
      } else {
        userObject[data.id] = { id: data.id, label: data.name };
      }
    });

    // Update the user list state object with the new user objects.
    setUserList((prevState: any) => ({ ...prevState, ...userObject }));
  };

  /* Get user details list */
  const getUserInfo = async (locationId: any) => {
    try {
      const res = await fetchAllUserInfo(idToken, locationId);
      if (!_.isEmpty(res.data.data)) {
        handleUserList(res.data.data);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get location details. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        if (!_.isEmpty(res.data.data)) {
          /* Setting up the list of locations as needed to select locations. */
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
        }
        setLocationSelectorList(locationList);
      })
      .catch(() => {
        setIsLoadingPage(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };
  // Get filter data
  const handleFilterData = (
    pageSize: any,
    currentPage: any,
    sortingMethod: any,
    headerName: any,
    saleFilterData: any,
    salePaymentType: any,
  ) => {
    setIsLoadingPage(true);
    getSaleFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      saleFilterData,
      salePaymentType,
    );
  };

  /**
   * When the component mounts or updates, set the saleFilterData state to the provided filterData
   * and check if the selectedPaymentMethod state is not empty
   */
  useEffect(() => {
    setSaleFilterData(filterData);
    if (!_.isEmpty(selectedPaymentMethod)) {
      // If the selectedPaymentMethod is empty (i.e. no payment type selected)
      if (_.isEmpty(selectedPaymentMethod.split("-")[1])) {
        // Set the salePaymentType state to the selectedPaymentMethod and call the handleFilterData function
        setSalePaymentType(selectedPaymentMethod);
        // If empty, call handleFilterData function with filterData, and selectedPaymentMethod
        handleFilterData(
          pageSize,
          currentPage,
          sortingMethod,
          headerName,
          filterData,
          selectedPaymentMethod,
        );
      } else {
        // If a payment type was selected, remove the order channel from the filterData and get the prefix from the selectedPaymentMethod
        setSalePaymentType(selectedPaymentMethod);
        // If not empty, remove the order channel from filterData and get the prefix from selectedPaymentMethod
        const updatedFilterData = filterData.replace(REMOVE_ORDER_CHANNEL, "");
        const PREFIX = selectedPaymentMethod.split("-")[0];

        // Get the desired substring starting from the end of the prefix
        const desiredPaymentType = selectedPaymentMethod.substring(
          PREFIX.length + 1,
        );

        // Create a new saleListFilterData by adding the desiredPaymentType to the updatedFilterData, excluding the PREFIX
        const saleListFilterData =
          updatedFilterData + ",orderChannel!(" + PREFIX + ")";

        // Call handleFilterData function with the new saleListFilterData, and desiredPaymentType
        handleFilterData(
          pageSize,
          currentPage,
          sortingMethod,
          headerName,
          saleListFilterData,
          desiredPaymentType,
        );
      }
    } else {
      // If no payment type was selected, call the handleFilterData function with the original filterData
      handleFilterData(
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
        filterData,
        selectedPaymentMethod,
      );
    }
  }, []);

  // Handle sale information using selector
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    setPageSize(pageSize);

    getSaleFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      saleFilterData,
      salePaymentType,
    );
  };

  // Sorting the sale information using a header name
  const handleChangeOrderListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    setIsSort(true);
    setDisableButton(true);
    setHeaderName(headerName);
    setSortingMethod(sortingMethod);
    getSaleFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      saleFilterData,
      salePaymentType,
    );
  };

  // Remove sorting method
  const handleRemoveOrderListSorting = () => {
    setIsSort(false);
    setDisableButton(false);
    setHeaderName("");
    setSortingMethod("");
    getSaleFilterInfo(
      pageSize,
      currentPage,
      "",
      "",
      saleFilterData,
      salePaymentType,
    );
  };

  // Handle sale information using page number
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    setCurrentPage(currentPage);
    handleFilterData(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      saleFilterData,
      salePaymentType,
    );
  };

  // Get filter data
  const handleGetFilterData = () => {
    getSaleFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      saleFilterData,
      salePaymentType,
    );
  };

  const handleOnClickText = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const theme: CustomTheme = useTheme();

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "10px",
          border: `1px solid ${theme.palette.background.entity_border}`,
          backgroundColor: theme.palette.background.default,
        },
      }}
      open={openSaleDetailsModal}
      fullWidth
      disableBackdropClick
      onClose={() => setOpenSaleDetailsModal(false)}
      style={{ zIndex: 4 }}
      maxWidth="xl"
      ref={dialogRef}
    >
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Sales Transactions
          </Typography>
          <div
            style={isScroll ? { marginRight: "16px" } : { marginRight: "2px" }}
          >
            <CancelIcon
              style={{ cursor: "pointer" }}
              onClick={() => setOpenSaleDetailsModal(false)}
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent ref={dialogContentRef} style={{ paddingTop: "0px" }}>
        {!isLoadingPage ? (
          <>
            <SaleList
              handleChangeOrderListSorting={handleChangeOrderListSorting}
              handleRemoveOrderListSorting={handleRemoveOrderListSorting}
              nodeData={salesNodeList}
              disableButton={disableButton}
              isLoading={isLoadingPage}
              locationSelectorList={locationSelectorList}
              userList={userList}
              numberOfLocations={numberOfLocations}
              getUserInfo={getUserInfo}
              isModal={true}
              getSaleFilterInfo={handleGetFilterData}
              isPaymentTypeLoading={isPaymentTypeLoading}
              setIsPaymentTypeLoading={setIsPaymentTypeLoading}
              setIsDiscountLoading={setIsDiscountLoading}
              isDiscountLoading={isDiscountLoading}
              locationSelectedList={[]}
              handleOnClickText={handleOnClickText}
              isSort={isSort}
              headerName={headerName}
              sortingMethod={sortingMethod}
            />
            {!salesNodeList && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "16px",
                }}
              >
                <ButtonCommon
                  style={{
                    flex: 1,
                    fontSize: 11,
                    width: "120px",
                    marginRight: "40px",
                  }}
                  variant="contained"
                  color={"red"}
                  onClick={() => setOpenSaleDetailsModal(false)}
                >
                  {"Close"}
                </ButtonCommon>
              </div>
            )}
          </>
        ) : (
          <ModalTableLoading isSaleModal={false} />
        )}
      </DialogContent>
      <DialogActions
        style={
          isScroll
            ? { marginBottom: "16px", marginLeft: "16px", marginRight: "32px" }
            : { marginBottom: "16px", marginLeft: "16px", marginRight: "8px" }
        }
      >
        {!_.isEmpty(salesNodeList) && (
          <Pagination
            handleChangePaginationUsingSelector={
              handleChangePaginationUsingSelector
            }
            handleChangePaginationUsingPageNumber={
              handleChangePaginationUsingPageNumber
            }
            totalPages={totalPages}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        )}
      </DialogActions>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </Dialog>
  );
};

export default SaleDetailsModal;

import React, { useContext } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { initTheme } from "./theme/primary";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { Route } from "react-router";
import { GlobalContext } from "../../root/App";
import { CssBaseline } from "@material-ui/core";
import SaleAppRoutes, { getSidebarRoutes } from "./routes/SaleAppRoutes";

export interface PrestoExpressLocationAppProps {}

const PrestoExpressLocationApp: React.FunctionComponent<
  PrestoExpressLocationAppProps
> = () => {
  const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([]);
  const { globalState } = useContext(GlobalContext);
  const theme = initTheme({ isDark: globalState.isDarkMode });
  const handleChangeSidebar = () => {
    const sidebar = getSidebarRoutes();
    setSidebarRoutes(sidebar);
  };

  React.useEffect(() => {
    handleChangeSidebar();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PrimaryLayout
        sidebarRoutes={sidebarRoutes}
        handleChangeSidebar={handleChangeSidebar}
      >
        <SaleAppRoutes />
      </PrimaryLayout>
    </ThemeProvider>
  );
};

export default PrestoExpressLocationApp;

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import * as React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import SubMenu from "../../components/SideBar/SubMenu";

export interface MobileLayoutProps {
  children: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  sidebarRoutes: any;
}

/* This component show the mobile view in the app bar. */
const MobileLayout: React.FunctionComponent<MobileLayoutProps> = ({
  children,
  open,
  setOpen,
  sidebarRoutes,
}) => {
  const match = useRouteMatch();
  const { pathname } = useLocation();

  /* If there is a '/' at the end of the URL, removing it. */
  let url = "";
  if (match.url.charAt(match.url.length - 1) === "/") {
    url = match.url.slice(0, -1);
  } else {
    url = match.url;
  }
  const search = useLocation().search;
  const appName = new URLSearchParams(search).get("app");

  return (
    <>
      {_.isEmpty(appName) && !!sidebarRoutes.length && (
        <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
          <List component="div">
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ padding: "8px 16px" }}
            >
              Presto Express
            </Typography>
            <Divider />
            {sidebarRoutes.map((route: any, index: Number) =>
              route.childRoutes ? (
                <SubMenu
                  key={route.path}
                  title={route.title}
                  icon={route.icon}
                  route={route}
                  openSideBar={open}
                />
              ) : (
                // "rftg"
                <div key={route.path}>
                  {route.isAuthorized ? (
                    <ListItem
                      button
                      alignItems="center"
                      component={Link}
                      to={url + route.path}
                      selected={_.isEqual(pathname, url + route.path)}
                      style={{ height: 48 }}
                      onClick={() => setOpen(false)}
                    >
                      <ListItemIcon style={{ minWidth: "unset" }}>
                        {route.icon}
                      </ListItemIcon>
                      {open && <span style={{ margin: 4 }}></span>}
                      {open && <ListItemText primary={route.title} />}
                    </ListItem>
                  ) : null}
                </div>
              ),
            )}
          </List>
        </Drawer>
      )}
      <Box p={1}>{children}</Box>
    </>
  );
};

export default MobileLayout;

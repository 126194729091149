import { axiosClient } from "../client";

const API_GET_MASTER_LOCATIONS = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockConfig`;

  export const fetchAllMasterLocations = (locationId) =>
  axiosClient({
    method: "GET",
    url: API_GET_MASTER_LOCATIONS(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
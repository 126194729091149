import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { CustomTheme } from "../../types/customTheme";
import _ from "lodash";
import IncrementDecrementTextbox from "../common/IncrementDecrementTextbox";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: "flex",
    alignItems: "start",
  },
  departmentStyle: {
    display: "block",
  },
  tabPanel: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`delivery-type-tab-${index}`}
      aria-labelledby={`delivery-type-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "16px 0" }}>{children}</div>}
    </div>
  );
};

function a11yProps(index: any) {
  return {
    id: `group-type-tab-${index}`,
    "aria-controls": `group-type-tab-${index}`,
  };
}

export interface paymentReportProps {
  menuGroups: any;
  menuDepartments: any;
  menuProducts: any;
  handleButtonClick: any;
  registerElementWithTitle: any;
  setActiveGroupId: any;
  setFilterDepartment: any;
  filterDepartment: any;
  menuNode: any;
  setMenuNode: any;
  initialOutputMultiplier: any;
  setIsOpenDrawer: any;
  isOpenDrawer: any;
  handleButtonClickMobile: any;
  selectedDepartmentId: any;
  isLoading: any;
  outputMultiplierObj: any;
  setOutputMultiplierObj: any;
  isOpenCreateStockItemsModal: any;
  checkedProductIdList: any;
  setCheckedProductIdList: any;
}
/* Set the filter, sale title, sale sub title, and table using filter details */
const AddMapProduct: React.FunctionComponent<paymentReportProps> = ({
  menuGroups,
  menuDepartments,
  menuProducts,
  handleButtonClick,
  registerElementWithTitle,
  setActiveGroupId,
  setFilterDepartment,
  filterDepartment,
  menuNode,
  setMenuNode,
  initialOutputMultiplier,
  setIsOpenDrawer,
  isOpenDrawer,
  handleButtonClickMobile,
  selectedDepartmentId,
  isLoading,
  outputMultiplierObj,
  setOutputMultiplierObj,
  isOpenCreateStockItemsModal,
  checkedProductIdList,
  setCheckedProductIdList,
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (
      isOpenCreateStockItemsModal &&
      menuGroups &&
      Object.values(menuGroups).length > 0
    ) {
      const firstGroup: any = Object.values(menuGroups)[0];
      setValue(firstGroup.id);
    }
  }, [menuGroups, isOpenCreateStockItemsModal]);

  useEffect(() => {
    if (isOpenCreateStockItemsModal && menuDepartments) {
      const filterMenuDepartment = Object.values(menuDepartments).filter(
        (department: any) => department.group_id.toString() === value,
      );
      setFilterDepartment(filterMenuDepartment);
    }
  }, [menuDepartments, value, isOpenCreateStockItemsModal]);

  /* Switch the tab to be displayed. */
  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const menuContainerRef = useRef<HTMLDivElement>(null);

  const handleChangeCheckBox = (event: any, products?: any) => {
    const cloneCheckedProductIdList = _.cloneDeep(checkedProductIdList);
    const cloneOutputMultiplierObj = outputMultiplierObj;
    if (Object.values(products).length > 0) {
      if (event.target.checked) {
        Object.values(products).forEach((prod: any) => {
          cloneCheckedProductIdList.push(prod.id);
          cloneCheckedProductIdList.push(prod.id);
          cloneOutputMultiplierObj[prod.id] = {
            product_id: prod.id,
            output_multiplier: initialOutputMultiplier,
          };
        });
        cloneCheckedProductIdList.push(event.target.value);
        setCheckedProductIdList(cloneCheckedProductIdList);
        setOutputMultiplierObj(cloneOutputMultiplierObj);
      } else {
        const matchIdList: any = [];
        Object.values(products).filter((prod: any) =>
          matchIdList.push(prod.id),
        );

        const filteredItems = Object.fromEntries(
          Object.entries(cloneOutputMultiplierObj).filter(
            ([id]) => !matchIdList.includes(id),
          ),
        );

        matchIdList.push(event.target.value);
        // Create a new array that includes elements from array1 where their IDs are not present in array2
        const newArray = cloneCheckedProductIdList.filter(
          (item: any) => !matchIdList.includes(item),
        );

        setOutputMultiplierObj(filteredItems);
        setCheckedProductIdList(newArray);
      }
    } else {
      if (event.target.checked) {
        cloneCheckedProductIdList.push(event.target.value);
        setCheckedProductIdList(cloneCheckedProductIdList);

        cloneOutputMultiplierObj[event.target.value] = {
          product_id: event.target.value,
          output_multiplier: initialOutputMultiplier,
        };
        setOutputMultiplierObj(cloneOutputMultiplierObj);
      } else {
        setCheckedProductIdList((prevCheckedItems: any) =>
          prevCheckedItems.filter((id: any) => id !== event.target.value),
        );
        delete cloneOutputMultiplierObj[event.target.value];
        setOutputMultiplierObj(cloneOutputMultiplierObj);
      }
    }
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleOutputMultiplier = (productId: any, event: any) => {
    const cloneOutputMultiplierObj = _.cloneDeep(outputMultiplierObj);
    cloneOutputMultiplierObj[productId] = {
      product_id: productId,
      output_multiplier: event.target.value,
    };
    setOutputMultiplierObj(cloneOutputMultiplierObj);
  };

  const handleIncrement = (productId: string) => {
    const cloneOutputMultiplierObj = _.cloneDeep(outputMultiplierObj);
    const output_multiplierIncrement =
      cloneOutputMultiplierObj[productId].output_multiplier;
    if (!_.isEmpty(output_multiplierIncrement.split(".")[1])) {
      cloneOutputMultiplierObj[productId].output_multiplier = Math.ceil(
        output_multiplierIncrement,
      );
    } else {
      cloneOutputMultiplierObj[productId].output_multiplier = (
        Number(output_multiplierIncrement) + 1
      ).toString();
    }
    setOutputMultiplierObj(cloneOutputMultiplierObj);
  };

  const handleDecrement = (productId: string) => {
    const cloneOutputMultiplierObj = _.cloneDeep(outputMultiplierObj);
    const output_multiplierDecrement =
      cloneOutputMultiplierObj[productId].output_multiplier;
    if (!_.isEmpty(output_multiplierDecrement.split(".")[1])) {
      if (Math.floor(output_multiplierDecrement) - 1 >= 0) {
        cloneOutputMultiplierObj[productId].output_multiplier =
          Math.floor(output_multiplierDecrement) - 1;
      }
    } else {
      if (Number(output_multiplierDecrement) - 1 >= 0) {
        cloneOutputMultiplierObj[productId].output_multiplier = (
          Number(output_multiplierDecrement) - 1
        ).toString();
      }
    }
    setOutputMultiplierObj(cloneOutputMultiplierObj);
  };

  const drawerRef: any = useRef(null);

  // Function to check if the click is outside the drawer
  const handleClickOutside = (event: any) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      // The click is outside the drawer, handle your logic here
      handleCloseDrawer();
    }
  };

  useEffect(() => {
    // Attach the click event listener to the document
    // document.addEventListener('click', handleClickOutside);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOverlayClick = (event: any) => {
    // Check if the click occurred outside the Drawer
    if (event.target.id === "drawer-overlay") {
      handleCloseDrawer();
    }
  };

  const matchesSmallScreen = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          {isOpenDrawer && (
            <div
              id="drawer-overlay"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 999,
                backgroundColor: "transparent",
              }}
              onClick={handleOverlayClick}
            />
          )}

          <Drawer
            ref={drawerRef}
            anchor={"left"}
            open={isOpenDrawer}
            onClose={handleCloseDrawer}
            PaperProps={{
              style: {
                position: "absolute",
              },
            }}
            variant="persistent"
          >
            {/* <Button onClick={handleCloseDrawer}>dfsd</Button> */}
            <div style={{ marginTop: "48px" }} />
            {!_.isEmpty(filterDepartment) &&
              filterDepartment.map((department: any) => (
                <div style={{ marginLeft: "32px" }}>
                  <Button
                    onClick={() =>
                      handleButtonClickMobile(`department ${department.id}`)
                    }
                    fullWidth
                    style={{
                      textTransform: "none",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      style={
                        selectedDepartmentId === department.id
                          ? { fontWeight: "bold" }
                          : { fontWeight: "normal" }
                      }
                    >
                      {department.name}
                    </Typography>
                  </Button>
                </div>
              ))}
          </Drawer>
          <Hidden xsDown>
            <Grid item xs={5} md={6}>
              <div
                className={classes.departmentStyle}
                style={{
                  overflowY: "auto",
                  maxHeight: "54vh",
                  marginLeft: "32px",
                  marginRight: "12px",
                }}
              >
                {!_.isEmpty(filterDepartment) &&
                  filterDepartment.map((department: any) => (
                    <div>
                      <Button
                        onClick={handleButtonClick(
                          `department ${department.id}`,
                        )}
                        fullWidth
                        style={{
                          textTransform: "none",
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <Typography
                          style={
                            selectedDepartmentId === department.id
                              ? { fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                        >
                          {department.name}
                        </Typography>
                      </Button>
                    </div>
                  ))}
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={7} md={6}>
            <div style={{ overflowY: "auto", maxHeight: "54vh" }}>
              <div>
                {!_.isEmpty(menuNode) &&
                  Object.values(menuNode).map((group: any) => (
                    <div
                      ref={(ref) =>
                        registerElementWithTitle(`group ${group.id}`, ref)
                      }
                    >
                      <div
                        style={
                          matchesSmallScreen
                            ? { height: "32px", marginLeft: "40px" }
                            : { height: "32px" }
                        }
                      >
                        <Typography
                          style={{ fontWeight: "bold", fontSize: "16px" }}
                        >
                          {group.name}
                        </Typography>
                      </div>
                      {!_.isEmpty(group.department) &&
                        Object.values(group.department).map(
                          (department: any) => (
                            <div
                              ref={(ref) =>
                                registerElementWithTitle(
                                  `department ${department.id}`,
                                  ref,
                                )
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginRight: "32px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  style={
                                    matchesSmallScreen
                                      ? {
                                          paddingLeft: "60px",
                                          fontWeight: "bold",
                                          fontSize: "12px",
                                        }
                                      : {
                                          paddingLeft: "20px",
                                          fontWeight: "bold",
                                          fontSize: "12px",
                                        }
                                  }
                                >
                                  {department.name}
                                </Typography>
                                <Checkbox
                                  checked={checkedProductIdList.includes(
                                    department.id,
                                  )}
                                  size="small"
                                  value={department.id}
                                  onChange={(event) =>
                                    handleChangeCheckBox(
                                      event,
                                      department.products,
                                    )
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              </div>
                              {!_.isEmpty(department.products) &&
                                Object.values(department.products).map(
                                  (products: any) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginRight: "32px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        style={
                                          matchesSmallScreen
                                            ? {
                                                paddingLeft: "80px",
                                                fontSize: "12px",
                                              }
                                            : {
                                                paddingLeft: "40px",
                                                fontSize: "12px",
                                              }
                                        }
                                      >
                                        {products.name}
                                      </Typography>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {checkedProductIdList.includes(
                                          products.id,
                                        ) && (
                                          <>
                                            <IncrementDecrementTextbox
                                              handleOrderQty={
                                                handleOutputMultiplier
                                              }
                                              value={
                                                outputMultiplierObj[products.id]
                                                  ?.output_multiplier
                                              }
                                              id={products.id}
                                              handleIncrement={handleIncrement}
                                              handleDecrement={handleDecrement}
                                            />
                                            {/* <IconButton
                                              onClick={() =>
                                                handleDecrement(products.id)
                                              }
                                            >
                                              <IndeterminateCheckBoxOutlinedIcon
                                                style={{
                                                  fontSize: "16px",
                                                }}
                                              />
                                            </IconButton>

                                            <TextfieldCommon
                                              id="outputMultiplier"
                                              name="outputMultiplier"
                                              label=""
                                              type="number"
                                              style={{
                                                width: "80px",
                                                marginLeft: "2px",
                                                marginRight: "2px",
                                              }}
                                              value={
                                                outputMultiplierObj[products.id]
                                                  ?.output_multiplier
                                              }
                                              onChange={(event: any) =>
                                                handleOutputMultiplier(
                                                  products.id,
                                                  event,
                                                )
                                              }
                                            />
                                            <IconButton
                                              onClick={() =>
                                                handleIncrement(products.id)
                                              }
                                            >
                                              <AddBoxOutlinedIcon
                                                style={{
                                                  fontSize: "16px",
                                                }}
                                              />
                                            </IconButton> */}
                                          </>
                                        )}
                                        <Checkbox
                                          checked={checkedProductIdList.includes(
                                            products.id,
                                          )}
                                          value={products.id}
                                          onChange={handleChangeCheckBox}
                                          size="small"
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ),
                                )}
                            </div>
                          ),
                        )}
                    </div>
                  ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AddMapProduct;

import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import _ from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";

export interface paymentReportProps {
  isOpenStockLevelModal: any;
  setIsOpenStockLevelModal: any;
  handleChangeStockLevel: any;
  stockLevel: any;
  handleSaveStockLevel: any;
  stockLevelInitial: any;
  handleDecrement: any;
  handleIncrement: any;
}

/**
 * This component represents a modal dialog for adjusting and saving stock levels.
 * Users can increment or decrement the stock level and save their changes.
 */
const StockLevelModal: React.FunctionComponent<paymentReportProps> = ({
  isOpenStockLevelModal,
  setIsOpenStockLevelModal,
  handleChangeStockLevel,
  stockLevel,
  handleSaveStockLevel,
  stockLevelInitial,
  handleDecrement,
  handleIncrement,
}) => {
  return (
    <DialogCommonDefault
      open={isOpenStockLevelModal}
      setOpen={setIsOpenStockLevelModal}
      isOpenMapProd={false}
    >
      <DialogTitle>Stock Level</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex" }}>
          <IconButton onClick={handleDecrement}>
            <IndeterminateCheckBoxOutlinedIcon
              style={{
                fontSize: "20px",
              }}
            />
          </IconButton>
          <TextfieldCommon
            id="stockLevel"
            name="stockLevel"
            type="number"
            label="Stock Level"
            disabled={false}
            value={stockLevel}
            onChange={handleChangeStockLevel}
            style={{ margin: "0px 4px" }}
          />
          <IconButton onClick={handleIncrement}>
            <AddBoxOutlinedIcon
              style={{
                fontSize: "20px",
              }}
            />
          </IconButton>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ textTransform: "none" }}
          onClick={() => {
            setIsOpenStockLevelModal(false);
          }}
          color="secondary"
        >
          Back
        </Button>
        <Button
          style={{ textTransform: "none" }}
          color="secondary"
          autoFocus
          disabled={
            stockLevel <= 0 ||
            stockLevelInitial.toString() === stockLevel.toString()
          }
          onClick={handleSaveStockLevel}
        >
          Done
        </Button>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default StockLevelModal;

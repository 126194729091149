import {
  Button,
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import _ from "lodash";

import IncrementDecrementTextbox from "../../../../../../components/common/IncrementDecrementTextbox";

export interface DesktopNavProps {
  item: any;
  registerElementWithTitle: any;
  selectedStockOrderItemIdList: any;
  handleDecrement: any;
  selectedStockOrderItemObject: any;
  handleOrderQty: any;
  handleIncrement: any;
  handleSubmit: any;
  handleChangeCheckBox: any;
  handleDisabled: any;
  getStatusMessage: any;
  isDisable: any;
}

/**
 * This component represents the view for displaying stock order items when the active group ID is zero.
 * It shows the stock order items organized by departments, allowing users to select items, adjust quantities,
 * and perform various actions.
 */
const StockOrderItemActiveGroupIdZero: React.FunctionComponent<
  DesktopNavProps
> = ({
  item,
  registerElementWithTitle,
  selectedStockOrderItemIdList,
  handleDecrement,
  selectedStockOrderItemObject,
  handleOrderQty,
  handleIncrement,
  handleSubmit,
  handleChangeCheckBox,
  handleDisabled,
  getStatusMessage,
  isDisable,
}) => {
  const matchesSmallScreen = useMediaQuery("(max-width: 960px)");

  return (
    <div>
      <div
        style={
          matchesSmallScreen
            ? {
                height: "32px",
                marginLeft: "40px",
              }
            : { height: "32px" }
        }
      >
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          {item.name}
        </Typography>
      </div>{console.log("fdaffdfsdfsdfsdfsdfrgr", item)}
      {!_.isEmpty(item.stockDepartmentId) &&
        item.stockDepartmentId.map((department: any) => (
          <div
            ref={(ref) =>
              registerElementWithTitle(`department ${department.id}`, ref)
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "12px",
                alignItems: "center",
              }}
            >
              <Typography
                style={
                  matchesSmallScreen
                    ? {
                        paddingLeft: "80px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginBottom: "4px",
                        marginTop: "8px",
                      }
                    : {
                        paddingLeft: "40px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginBottom: "4px",
                        marginTop: "8px",
                      }
                }
              >
                {department.name}
              </Typography>
              {/* <Checkbox
                      checked={selectedStockOrderItemIdList.includes(
                        department.id,
                      )}
                      size="small"
                      value={department.id}
                      onChange={(event) =>
                        handleChangeCheckBox(
                          event,
                          "group",
                          department,
                        )
                      }
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    /> */}
            </div>
            {!_.isEmpty(department.item) &&
              department.item.map((stockOrder: any) => (
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "4px",
                    padding: "0px 12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  >
                    <Typography
                      style={
                        matchesSmallScreen
                          ? {
                              paddingLeft: "80px",
                              fontSize: "12px",
                            }
                          : {
                              paddingLeft: "80px",
                              fontSize: "12px",
                            }
                      }
                    >
                      {stockOrder.name}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {selectedStockOrderItemIdList.includes(stockOrder.id) && (
                        <>
                          <IncrementDecrementTextbox
                            handleOrderQty={handleOrderQty}
                            value={
                              selectedStockOrderItemObject[stockOrder.id]
                                ?.orderQty
                            }
                            id={stockOrder.id}
                            handleIncrement={handleIncrement}
                            handleDecrement={handleDecrement}
                            isDisable={isDisable}
                          />
                          <Button
                            style={{
                              textTransform: "none",
                            }}
                            color="secondary"
                            onClick={() => {
                              handleSubmit(stockOrder.id);
                            }}
                            disabled={
                              handleDisabled(
                                selectedStockOrderItemObject[stockOrder.id]
                                  ?.orderQty,
                                stockOrder.id,
                              ) || isDisable
                            }
                          >
                            {getStatusMessage(stockOrder)}
                          </Button>
                        </>
                      )}
                      <Checkbox
                        checked={selectedStockOrderItemIdList.includes(
                          stockOrder.id,
                        )}
                        disabled={isDisable}
                        value={stockOrder.id}
                        onChange={(event) =>
                          handleChangeCheckBox(event, stockOrder.id, stockOrder)
                        }
                        size="small"
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              ))}
          </div>
        ))}
    </div>
  );
};

export default StockOrderItemActiveGroupIdZero;

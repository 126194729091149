import { axiosClient } from "../client";

const queryString = require("query-string");

const STOCK_ITEMS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockItem`;

const STOCK_ITEMS_DEPARTMENTS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/location/${locationId}/departments`;

  const STOCK_ITEMS_PRODUCTS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/location/${locationId}/products`;

  const STOCK_ITEMS_GROUPS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CATEGORY_MENU}/location/${locationId}/groups`;

const STOCK_ITEMS_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockItem`;

  const SUPPLIER_FOR_STOCK_ITEMS_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockSupplierMap/multiSupplier`;

  const STOCK_ITEMS_INFO_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockItem`;

  const STOCK_ITEMS_INFO_GET = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockItem`;

  const MAP_PRODUCTS_INFO_GET = (locationId, stockId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockMapping/product/${stockId}`;

  const STOCK_ITEM_GROUP_INFO_GET = (locationId, groupId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockItem/groupId/${groupId}`;

  const ALL_STOCK_DEPARTMENTS_INFO_GET = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockDepartment`;

  const UPDATE_STOCK_DEPARTMENTS_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockDepartment`;

  const CREATE_STOCK_DEPARTMENTS_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockDepartment`;

  const CREATE_STOCK_SUPPLIER_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/supplier`;

  const UPDATE_STOCK_SUPPLIER_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/supplier`;

  const CREATE_SUPPLIER_MAP = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/supplierMap`;

  const STOCK_GROUP_INFO_GET = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockGroup`;

  const CREATE_STOCK_GROUP_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockGroup`;

  const UPDATE_STOCK_GROUP_INFO = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockGroup`;

  const STOCK_ITEM_DEPARTMENT_INFO_GET = (locationId, departmentId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockItem/departmentId/${departmentId}`;

  const MAP_PRODUCTS_INFO_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/stockMapping/multiStock`;

export const fetchAllStockItemsInfo = (
  locationId,
  size,
  currentPage,
  shortingMethod,
  shortingHeaderName,
  filterData,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: STOCK_ITEMS_INFO_GET_ALL(locationId),
        query: {
          size: size,
          page: currentPage,
          sort: [shortingHeaderName, shortingMethod],
          where: filterData,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const createMapProductsInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: MAP_PRODUCTS_INFO_CREATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createStockItemsInfo = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: STOCK_ITEMS_INFO_CREATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const createSupplierForStockItem = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: SUPPLIER_FOR_STOCK_ITEMS_INFO_CREATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const fetchStockItemInfoGetAll = (locationId) =>
  axiosClient({
    method: "GET",
    url: STOCK_ITEMS_INFO_GET(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const updateStockItemsInfo = (locationId, data) =>
  axiosClient({
    method: "PATCH",
    url: STOCK_ITEMS_INFO_UPDATE(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const fetchMapProductInfo = (locationId, stockId) =>
  axiosClient({
    method: "GET",
    url: MAP_PRODUCTS_INFO_GET(locationId, stockId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const fetchStockItemForGroup   = (
    locationId,
    groupId,
    shortingMethod,
    shortingHeaderName,
  ) =>
    axiosClient({
      method: "GET",
      url: queryString.stringifyUrl(
        {
          url: STOCK_ITEM_GROUP_INFO_GET(locationId, groupId),
          query: {
            size: 5000,
            page: 1,
            sort: [shortingHeaderName, shortingMethod],
          },
        },
        {
          sort: false,
          arrayFormat: "comma",
          skipNull: true,
          skipEmptyString: true,
        },
      ),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });



  
  export const fetchGetAllStockDepartments = (locationId) =>
  axiosClient({
    method: "GET",
    url: CREATE_STOCK_DEPARTMENTS_INFO(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const createStockDepartments = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: CREATE_STOCK_DEPARTMENTS_INFO(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const createSupplier = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: CREATE_STOCK_SUPPLIER_INFO(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const supplierPatch = (locationId, data) =>
  axiosClient({
    method: "patch",
    url: UPDATE_STOCK_SUPPLIER_INFO(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const supplierMap = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: CREATE_SUPPLIER_MAP(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const updateStockDepartments = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: UPDATE_STOCK_DEPARTMENTS_INFO(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const fetchAllStockGroups = (locationId) =>
  axiosClient({
    method: "GET",
    url: STOCK_GROUP_INFO_GET(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
  
  export const createStockGroups = (locationId, data) =>
  axiosClient({
    method: "POST",
    url: CREATE_STOCK_GROUP_INFO(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const updateStockGroups = (locationId, data) =>
  axiosClient({
    method: "PATCH",
    url: UPDATE_STOCK_GROUP_INFO(locationId),
    data,
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
  
  export const fetchStockItemForDepartment = (locationId, departmentId) =>
  axiosClient({
    method: "GET",
    url: STOCK_ITEM_DEPARTMENT_INFO_GET(locationId, departmentId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllStockItemsProductInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: STOCK_ITEMS_PRODUCTS_INFO_GET_ALL(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

  export const fetchAllStockItemsGroupsInfoSorting = (
    locationId,
    size,
    currentPage,
    shortingMethod,
    shortingHeaderName,
    filterData,
  ) =>
    axiosClient({
      method: "GET",
      url: queryString.stringifyUrl(
        {
          url: STOCK_GROUP_INFO_GET(locationId),
          query: {
            size: size,
            page: currentPage,
            sort: [shortingHeaderName, shortingMethod],
            where: filterData,
          },
        },
        {
          sort: false,
          arrayFormat: "comma",
          skipNull: true,
          skipEmptyString: true,
        },
      ),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });

  export const fetchAllStockDepartmentsInfoSorting = (
    locationId,
    size,
    currentPage,
    shortingMethod,
    shortingHeaderName,
    filterData,
  ) =>
    axiosClient({
      method: "GET",
      url: queryString.stringifyUrl(
        {
          url: ALL_STOCK_DEPARTMENTS_INFO_GET(locationId),
          query: {
            size: size,
            page: currentPage,
            sort: [shortingHeaderName, shortingMethod],
            where: filterData,
          },
        },
        {
          sort: false,
          arrayFormat: "comma",
          skipNull: true,
          skipEmptyString: true,
        },
      ),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });

  export const fetchAllStockItemsDepartmentsInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: STOCK_ITEMS_DEPARTMENTS_INFO_GET_ALL(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });



  export const fetchAllStockItemsGroupsInfo = ( locationId,
    shortingMethod,
    shortingHeaderName,
  ) =>
    axiosClient({
      method: "GET",
      url: queryString.stringifyUrl(
        {
          url: STOCK_ITEMS_GROUPS_INFO_GET_ALL(locationId),
          query: {
            size: 5000,
            page: 1,
            sort: [shortingHeaderName, shortingMethod],
          },
        },
        {
          sort: false,
          arrayFormat: "comma",
          skipNull: true,
          skipEmptyString: true,
        },
      ),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });

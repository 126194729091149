import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import Home from "../pages/HomePage";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import DashboardIcon from "@material-ui/icons/Dashboard";
import StockItems from "../pages/StockItems";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ViewListIcon from "@material-ui/icons/ViewList";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import StockOrders from "../pages/StockOrders";
import StockGroup from "../pages/StockGroup";
import StockDepartment from "../pages/StockDepartment";
import Supplier from "../pages/Supplier";
import StockTake from "../pages/StockTake";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "Dashboard",
      title: "Dashboard",
      path: "",
      icon: <DashboardIcon />,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
    },
    {
      id: "StockOrders",
      title: "Stock Orders",
      path: "/stockOrders",
      icon: <ViewListIcon />,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
    },
    {
      id: "StockTake",
      title: "Stock Take",
      path: "/stockTake",
      icon: <ViewListIcon />,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
    },
    {
      id: "admin",
      title: "Admin",
      path: "/admin",
      icon: <SupervisorAccountIcon />,
      isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
      childRoutes: [
        {
          id: "stockItems",
          title: "Stock Items",
          path: "/admin/stockItems",
          icon: <BusinessCenterIcon />,
          isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
        },
        {
          id: "stockGroup",
          title: "Stock Group",
          path: "/admin/group",
          icon: <GroupAddIcon />,
          isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
        },
        {
          id: "stockDepartment",
          title: "Stock Department",
          path: "/admin/department",
          icon: <PersonIcon />,
          isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
        },
        {
          id: "supplier",
          title: "Supplier",
          path: "/admin/supplier",
          icon: <PersonAddIcon />,
          isAuthorized: getIsAuthorized(Authorities.INVENTORY_READ),
        },
      ],
    },
  ];
  return sidebarRoute;
};
export interface InventoryAppRoutesProps {
  isOpenDesktopSideBar: any;
}

const InventoryAppRoutes: React.FunctionComponent<InventoryAppRoutesProps> = ({
  isOpenDesktopSideBar,
}) => {
  return (
    <Switch>
      <Route path="/:locationId" component={Home} exact />
      <Route path="/:locationId/stockOrders" component={StockOrders} exact />
      {/* <Route
        path="/:locationId/admin/stockItems"
        component={StockItems}
        exact
      /> */}
      <Route
        path="/:locationId/admin/stockItems"
        render={(props) => <StockItems {...props} additionalProp={isOpenDesktopSideBar} />}
        exact
      />
      <Route
        path="/:locationId/admin/group"
        component={StockGroup}
        exact
      />
       <Route
        path="/:locationId/admin/department"
        component={StockDepartment}
        exact
      />
       <Route
        path="/:locationId/admin/supplier"
        component={Supplier}
        exact
      />
      <Route
        path="/:locationId/stockTake"
        render={(props) => <StockTake {...props} additionalProp={isOpenDesktopSideBar} />}
        exact
      />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default InventoryAppRoutes;

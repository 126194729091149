import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  ListItemIcon,
  useTheme,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(3),
      borderRadius: "0 48px 48px 0",
      border: "none",
    },
    root: {
      borderRadius: "0 48px 48px 0",
      border: "none",
    },
  }),
);

export interface SubMenuProps {
  route: any;
  title: any;
  icon: any;
  openSideBar: Boolean;
}

/* This component is used when one application has several other sub-applications. */
const SubMenu: React.SFC<SubMenuProps> = ({
  route,
  title,
  icon,
  openSideBar,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let match = useRouteMatch();
  const { pathname } = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (window.location.href.includes("/admin/")) {
      handleClick();
    }
  }, []);
  const theme: CustomTheme = useTheme();
  return (
    <div>
      {!openSideBar ? (
        <div>
          <Tooltip
            title={
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", color: "white" }}
              >
                {title}
              </Typography>
            }
          >
            <ListItem className={classes.root} button onClick={handleClick}>
              <ListItemIcon
                style={{
                  minWidth: "unset",
                  color: theme.palette.custom.orange.contrastText,
                }}
              >
                {icon}
              </ListItemIcon>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Tooltip>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {route.childRoutes &&
                route.childRoutes.map((subRoute: any) => (
                  <Tooltip
                    title={
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", color: "white" }}
                      >
                        {subRoute.title}
                      </Typography>
                    }
                  >
                    <ListItem
                      button
                      alignItems="center"
                      component={Link}
                      to={match.url + subRoute.path}
                      key={route.path}
                      selected={pathname.includes(subRoute.path)}
                      className={classes.nested}
                      style={{ height: 48 }}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: "unset",
                          color: theme.palette.custom.orange.contrastText,
                        }}
                      >
                        {subRoute.icon}
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                ))}
            </List>
          </Collapse>
        </div>
      ) : (
        <>
          <ListItem className={classes.root} button onClick={handleClick}>
            <ListItemIcon
              style={{
                minWidth: "unset",
                color: theme.palette.custom.orange.contrastText,
              }}
            >
              {icon}
            </ListItemIcon>
            <span style={{ margin: 4 }}></span>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                  }}
                >
                  {title}
                </Typography>
              }
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {route.childRoutes &&
                route.childRoutes.map((subRoute: any) => (
                  <ListItem
                    button
                    alignItems="center"
                    component={Link}
                    to={match.url + subRoute.path}
                    key={route.path}
                    selected={pathname.includes(subRoute.path)}
                    className={classes.nested}
                    style={{ height: 48 }}
                  >
                    <ListItemIcon
                      style={{
                        minWidth: "unset",
                        color: theme.palette.custom.orange.contrastText,
                      }}
                    >
                      {subRoute.icon}
                    </ListItemIcon>
                    <span style={{ margin: 4 }}></span>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          {subRoute.title}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Collapse>
        </>
      )}
    </div>
  );
};

export default SubMenu;

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  Hidden,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import _ from "lodash";
import { Skeleton } from "@material-ui/lab";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import { CustomTheme } from "../../../../../types/customTheme";
import {
  updateStockOrder,
  fetchStockOrderItemForStockOrder,
  stockOrderItemDelete,
  updateStockOrderItem,
  updateStockOrderItemStatus,
} from "../../../../../services/inventory/stockOrder";
import {
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  ERROR_MESSAGE_UPDATING_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../../utils/consts";
import { fetchAllStockItemForSupplierInfo } from "../../../../../services/inventory/supplier";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import AlertDialog from "../../../../../components/alerts/AlertDialog";
import DesktopNav from "./component/DesktopNav";
import MobileNav from "./component/MobileNav";
import DepartmentSelectMobileView from "./component/DepartmentSelectMobileView";
import DepartmentSelectDesktopView from "./component/DepartmentSelectDesktopView";
import StockOrderItemActiveGroupIdZero from "./component/StockOrderItemActiveGroupIdZero";
import StockOrderItemActiveGroupIdNonZero from "./component/StockOrderItemActiveGroupNonZero";
import IncrementDecrementTextboxDefault from "../../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxDefault";
import { handleDownloadPdf } from "../convertPdf/convertDataToPdf";
import CardCommon from "../../../../../components/card/CardCommon";
import DeliveryDetailsModal from "../DeliveryDetailsModal";
import { CapitalizeFirstLetter } from "../../../../../utils/ReplaceIcon";
import { convertDateTimeFormatInventory } from "../../../../../utils/ConvertDateTimeFormat";

const input = [
  {
    deliveredQty: "20",
    id: "03e3ab9b-58e1-4531-81a9-b2cf20e69bcb",
    locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
    orderQty: 20,
    status: "delivered",
    stockItemId: "1ba0e0f9-dc75-4446-8c53-7adf304b0033",
    stockOrderId: "bdbcec76-2108-48de-96ae-8fabab6ba7d4",
    supplierId: "549ff737-e58d-41d0-810d-fdc38604ed0b",
    totalCost: "600",
    unitPrice: "30",
    updated: "2023-08-31T13:46:57.000+00:00",
    version: 0,
  },
  {
    deliveredQty: "10",
    id: "13e3ab9b-58e1-4531-81a9-b2cf20e69bcb",
    locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
    orderQty: 220,
    status: "delivered",
    stockItemId: "3ba0e0f9-dc75-4446-8c53-7adf304b0033",
    stockOrderId: "bdbcec76-2108-48de-96ae-8fabab6ba7d4",
    supplierId: "549ff737-e58d-41d0-810d-fdc38604ed0b",
    totalCost: "60",
    unitPrice: "3",
    updated: "2023-08-31T13:46:57.000+00:00",
    version: 0,
  },
];

const output = {
  stockOrderitems: [
    {
      stockOrderItemId: "1ba0e0f9-dc75-4446-8c53-7adf304b0033",
      delivered_qty: 20,
      status: "delivered",
      total_cost: 600,
      unit_price: 30,
    },
    {
      stockOrderItemId: "3ba0e0f9-dc75-4446-8c53-7adf304b0033",
      delivered_qty: 10,
      status: "delivered",
      total_cost: 60,
      unit_price: 3,
    },
  ],
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    "& .MuiTabs-root": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 280px)",
      },
    },
  },
  roots: {
    display: "flex",
    alignItems: "start",
  },
  rowOdd: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    marginTop: "16px",
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  departmentStyle: {
    display: "block",
  },
  firstGridField: {
    display: "flex",
    justifyContent: "start",
    minHeight: "40px",
    alignItems: "center",
    paddingLeft: "16px",
  },
  gridField: {
    display: "flex",
    justifyContent: "center",
    minHeight: "40px",
    alignItems: "center",
  },
  lastGridField: {
    display: "flex",
    justifyContent: "end",
    minHeight: "40px",
    alignItems: "center",
    paddingRight: "16px",
  },
}));

export interface paymentReportProps {
  isOpenEditDeliveryDetailsModal: any;
  setIsOpenEditDeliveryDetailsModal: any;
  setSuccess: any;
  setError: any;
  handleGetStockOrdersListSorting: any;
  supplierId: any;
  orderId: any;
  selectedStockOrderDetails: any;
  locationData: any;
  handleOpenDeliveryDetailsModal: any;
  isOpenDeliveryDetailsModal: any;
  setIsOpenDeliveryDetailsModal: any;
  deliveryDetailsNode: any;
  isDeliver: any;
  setIsNotDelivery: any;
  isNotDelivery: any;
}

/**
 * This component is responsible for rendering a modal that allows users to manage
 * stock order items. It fetches data from API endpoints to display a list of items
 * available from suppliers and the corresponding order quantities. Users can adjust
 * the order quantities, remove items from the order, and submit the updated quantities.
 *
 * The component uses Material-UI components for styling and UI elements. It handles
 * various user interactions, updates, and API calls to manage the stock order items.
 */
const EditDeliveryStockOrderItem: React.FunctionComponent<
  paymentReportProps
> = ({
  isOpenEditDeliveryDetailsModal,
  setIsOpenEditDeliveryDetailsModal,
  setSuccess,
  setError,
  handleGetStockOrdersListSorting,
  supplierId,
  orderId,
  selectedStockOrderDetails,
  locationData,
  handleOpenDeliveryDetailsModal,
  isOpenDeliveryDetailsModal,
  setIsOpenDeliveryDetailsModal,
  deliveryDetailsNode,
  isDeliver,
  setIsNotDelivery,
  isNotDelivery,
}) => {
  const [
    allStockOrderItemTransformedNode,
    setAllStockOrderItemTransformedNode,
  ] = useState<any>([]);
  const [allStockOrderItemNodeInitial, setAllStockOrderItemNodeInitial] =
    useState<any>([]);
  const [allStockOrderItemResponseNode, setAllStockOrderItemResponseNode] =
    useState<any>([]);
  const [selectedStockOrderIdList, setSelectedStockOrderIdList] = useState<any>(
    [],
  );
  const [stockOrderList, setStockOrderList] = useState<any>([]);
  const [stockOrderListStatusOrdered, setStockOrderListStatusOrdered] =
    useState<any>([]);
  const [stockOrderListStatusDelivered, setStockOrderListStatusDelivered] =
    useState<any>([]);
  const [stockOrderListStatusDeliveredId, setStockOrderListStatusDeliveredId] =
    useState<any>([]);
  const [
    stockOrderListStatusDeliveredIdInitial,
    setStockOrderListStatusDeliveredIdInitial,
  ] = useState<any>([]);
  const [selectedStockItemObject, setSelectedStockItemObject] = useState<any>(
    {},
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isEmptyStockOrder, setIsEmptyStockOrder] = useState(false);
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [nodesPdf, setNodesPdf] = useState<any>([]);
  const [selectedStockOrderItemIdList, setSelectedStockOrderItemIdList] =
    useState<any>([]);
  const [selectedStockOrderItemObject, setSelectedStockOrderItemObject] =
    useState<any>({});
  // const [updatedStockOrderItemObject, setUpdatedStockOrderItemObject] = useState<any>({});
  const [
    selectedStockOrderItemObjectInitial,
    setSelectedStockOrderItemObjectInitial,
  ] = useState<any>({});
  const [stockItemsDetailsObj, setSStockItemsDetailsObj] = useState<any>({});
  const [isCompleteLoad, setIsCompleteLoad] = useState<any>({});
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [selectedStockOrderItemsNode, setSelectedStockOrderItemsNode] =
    useState<any>([]);
  const [activeGroupId, setActiveGroupId] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isDisable, setIsDisable] = useState(true);

  const match: any = useRouteMatch();
  const theme: CustomTheme = useTheme();

  /**
   * This function fetches stock order items for a given location and order ID,
   * processes the response data to extract unique stock item IDs, and updates
   * relevant states based on the fetched data and IDs.
   */
  const geAllStockOrderItemForStockOrder = async () => {
    try {
      // Fetch stock order items for the specified location and order ID
      const res = await fetchStockOrderItemForStockOrder(
        match.params.locationId,
        orderId,
      );

      // Check if any stock order items were found in the response
      if (res.data.status === 404) {
        // If no items were found, set the 'isEmptyStockOrder' state
        setStockOrderList([]);
      } else {
        // Update states with fetched data and unique stock item IDs
        setStockOrderList(res.data.data);
        const statusOrdered = res.data.data.filter(
          (data: any) => data.status === "order",
        );
        const statusDelivered = res.data.data.filter(
          (data: any) => data.status !== "order",
        );
        setStockOrderListStatusOrdered(statusOrdered);
        setStockOrderListStatusDelivered(statusDelivered);
      }
    } catch (error) {
      setIsLoading(false);
      setIsDisable(false);
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetches all stock items for the specified supplier and location,
   * processes the response data to create a structured format,
   * and updates relevant states with the transformed data.
   */
  const geAllStockItemForSupplier = async () => {
    try {
      // Fetch all stock items for the specified supplier and location
      const res = await fetchAllStockItemForSupplierInfo(
        match.params.locationId,
        supplierId,
      );

      // Check if no stock items were found for the supplier and location
      if (res.data.status === 404) {
        // If no items were found, set the 'isEmpty' state to indicate empty results
        setSStockItemsDetailsObj({});
      } else {
        const obj: any = {};
        res.data.data.map((item: any) => {
          obj[item.id] = item.name;
        });
        setSStockItemsDetailsObj(obj);
      }
      // Update component states with the received data from the API response
      // Set loading state to false
      setIsLoading(false);
      setIsDisable(false);
      // Set stock items data
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsDisable(false);
    }
  };

  /**
   * Fetch data for both stock order items and stock items on component mount
   */
  useEffect(() => {
    geAllStockOrderItemForStockOrder();
    geAllStockItemForSupplier();
  }, []);

  const handleUpdateStatus = async (output: any) => {
    try {
      await updateStockOrderItemStatus(
        match.params.locationId,
        orderId,
        output,
      );
      setSuccess(SUCCESSFULLY_UPDATED)
      handleGetStockOrdersListSorting();
      setIsOpenEditDeliveryDetailsModal(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UPDATING_ERROR);
    }
  };

  /**
   * Handle exiting the page or action.
   * This function checks for changes in order quantity and performs necessary actions.
   */
  const handleExitPage = () => {
    // setIsOpenEditDeliveryDetailsModal(false);
    const output = {
      stockOrderitems: stockOrderListStatusDelivered.map((item: any) => ({
        stockOrderItemId: item.id,
        delivered_qty: item.deliveredQty,
        status: item.status,
        total_cost: parseFloat(item.totalCost).toFixed(2),
        unit_price: parseFloat(item.unitPrice).toFixed(2),
      })),
    };
    handleUpdateStatus(output);
    console.log("fdasfafdasdasda432", output);

    // // Iterate through each key-value pair in the 'selectedStockOrderItemObject'
    // Object.entries(selectedStockOrderItemObject).forEach(([key]: any) => {
    //   // Get the initial order quantity for the current key
    //   const initialOrderQty =
    //     selectedStockOrderItemObjectInitial[key]?.orderQty;

    //   // Get the current order quantity for the current key
    //   const currentOrderQty = selectedStockOrderItemObject[key]?.orderQty;

    //   // Check if the current order quantity has changed and is greater than 0
    //   if (initialOrderQty !== currentOrderQty && currentOrderQty > 0) {
    //     // If conditions are met, submit the form data for the current key
    //     handleSubmit(key);
    //   }
    // });
    // // Perform action to change stock groups list sorting
    // handleGetStockOrdersListSorting();

    // // Close the create stock order modal
    // setIsOpenEditDeliveryDetailsModal(false);
  };

  const handlePlaceOrder = async () => {
    const formData = {
      id: selectedStockOrderDetails.id,
      locationId: selectedStockOrderDetails.locationId,
      supplierId: selectedStockOrderDetails.supplierId.id,
      status: "delivered",
      version: selectedStockOrderDetails.version,
    };
    // const
    try {
      await updateStockOrder(match.params.locationId, formData);
      setSuccess(SUCCESSFULLY_UPDATED)
      handleGetStockOrdersListSorting();
      setIsOpenEditDeliveryDetailsModal(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UPDATING_ERROR);
    }
  };

  /**
   * Generate a set of Skeleton components for displaying loading placeholders.
   *
   * @returns {JSX.Element} - The JSX containing a set of Skeleton components.
   */
  const handleSkeleton = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginRight: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
        <Grid item xs={6}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            style={{ marginLeft: "4px" }}
            width={"100%"}
            height={40}
          />
        </Grid>
      </Grid>
    );
  };

  const handleDisable = () => {
    if (_.isEmpty(stockOrderListStatusOrdered)) {
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateDelivery = (data: any, status: string) => {
    if (isDeliver) {
      const clone = _.cloneDeep(stockOrderListStatusDelivered);
      const newData = clone.map((item: any) => {
        if (item.id === deliveryDetailsNode.id) {
          item.deliveredQty = data?.deliveredQty;
          item.totalCost = data?.totalCost;
          item.unitPrice = data?.unitPrice;
          item.status = status;
        }
        return item; // You need to return the item, whether it's updated or not.
      });

      setStockOrderListStatusDelivered(newData);
    } else {
      const cloneStockOrderListStatusOrdered = _.cloneDeep(
        stockOrderListStatusOrdered,
      );
      const filterStockOrderListStatusOrdered =
        cloneStockOrderListStatusOrdered.filter(
          (data: any) => data.id !== deliveryDetailsNode.id,
        );

      const filterStockOrderListStatusOrdereds =
        cloneStockOrderListStatusOrdered.filter(
          (data: any) => data.id === deliveryDetailsNode.id,
        );

      const datas = filterStockOrderListStatusOrdereds[0];

      const abc = _.cloneDeep(stockOrderListStatusDelivered);

      datas["deliveredQty"] = data?.deliveredQty;
      datas["totalCost"] = data?.totalCost;
      datas["unitPrice"] = data?.unitPrice;
      datas["status"] = status;
      abc.push(datas);
      setStockOrderListStatusDelivered(abc);
      setStockOrderListStatusOrdered(filterStockOrderListStatusOrdered);
    }
  };
  console.log("Asdasd435adas", selectedStockOrderDetails);

  const classes = useStyles();
  return (
    <>
      <DialogCommonDefault
        open={isOpenEditDeliveryDetailsModal}
        setOpen={setIsOpenEditDeliveryDetailsModal}
        isOpenMapProd={true}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          Ordered Details
        </DialogTitle>
        <DialogContent>
          {!isLoading ? (
            // If not loading, show the content
            <>
              <Grid container style={{ marginBottom: "12px" }}>
                <Grid item xs={6} style={{ display: "flex" }}>
                  <Typography
                    variant="caption"
                    style={{ fontWeight: "bold", marginRight: "48px" }}
                  >
                    Status
                  </Typography>
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    :
                  </Typography>
                  <Typography variant="caption" style={{ marginLeft: "4px" }}>
                    {CapitalizeFirstLetter(selectedStockOrderDetails.status)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    Created
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{
                      fontWeight: "bold",
                      marginLeft: "4px",
                      marginRight: "8px",
                    }}
                  >
                    :
                  </Typography>
                  <Typography variant="caption" style={{ marginLeft: "4px" }}>
                    {convertDateTimeFormatInventory(
                      selectedStockOrderDetails.created,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ display: "flex" }}>
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    Supplier Name
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ fontWeight: "bold", marginLeft: "4px" }}
                  >
                    :
                  </Typography>
                  <Typography variant="caption" style={{ marginLeft: "4px" }}>
                    {CapitalizeFirstLetter(
                      selectedStockOrderDetails.supplierId.name,
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography
                    variant="caption"
                    style={{ fontWeight: "bold", marginRight: "4px" }}
                  >
                    Updated
                  </Typography>
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    :
                  </Typography>
                  <Typography variant="caption" style={{ marginLeft: "4px" }}>
                    {convertDateTimeFormatInventory(
                      selectedStockOrderDetails.updated,
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Typography style={{ marginBottom: "12px" }}>
                Ordered Items
              </Typography>
              {!_.isEmpty(stockOrderListStatusOrdered) ? (
                <div style={{ maxHeight: "25vh", overflowY: "auto" }}>
                  <CardCommon backgroundColor={"table_header_background"}>
                    <Grid container>
                      <Grid item xs={3} className={classes.firstGridField}>
                        <Typography align="left"> Name</Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.gridField}>
                        <Typography> Order Qty</Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.gridField}>
                        <Typography> Delivered Qty</Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.gridField}>
                        <Typography> Unit Price</Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.lastGridField}>
                        <Typography> Total</Typography>
                      </Grid>
                    </Grid>
                  </CardCommon>
                  <Grid container>
                    {stockOrderListStatusOrdered.map((item: any) => (
                      <Grid item xs={12} style={{ marginTop: "4px" }}>
                        <CardCommon
                          backgroundColor={"entity_highlight_background"}
                        >
                          <Button
                            style={{
                              width: "100%",
                              margin: "0px",
                              padding: "0px",
                              textTransform: "none",
                            }}
                            onClick={() =>
                              handleOpenDeliveryDetailsModal(item, false)
                            }
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={3}
                                className={classes.firstGridField}
                              >
                                <Typography align="left">
                                  {stockItemsDetailsObj[item.stockItemId]}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridField}>
                                <Typography>{item.orderQty}</Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridField}>
                                <Typography>{item.deliveredQty}</Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridField}>
                                <Typography>{item.unitPrice}</Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className={classes.lastGridField}
                              >
                                <Typography>{item.totalCost}</Typography>
                              </Grid>
                            </Grid>
                          </Button>
                        </CardCommon>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">No Ordered Items</Typography>
                </div>
              )}

              <Typography style={{ marginTop: "12px", marginBottom: "12px" }}>
                Delivered Items
              </Typography>
              {!_.isEmpty(stockOrderListStatusDelivered) ? (
                <div style={{ maxHeight: "25vh", overflowY: "auto" }}>
                  <CardCommon backgroundColor={"table_header_background"}>
                    <Grid container>
                      <Grid item xs={3} className={classes.firstGridField}>
                        <Typography align="left"> Name</Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.gridField}>
                        <Typography> Order Qty</Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.gridField}>
                        <Typography> Delivered Qty</Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.gridField}>
                        <Typography> Unit Price</Typography>
                      </Grid>
                      <Grid item xs={3} className={classes.lastGridField}>
                        <Typography> Total</Typography>
                      </Grid>
                    </Grid>
                  </CardCommon>
                  <Grid container>
                    {stockOrderListStatusDelivered.map((item: any) => (
                      <Grid item xs={12} style={{ marginTop: "4px" }}>
                        <CardCommon
                          backgroundColor={"entity_highlight_background"}
                        >
                          <Button
                            style={{
                              width: "100%",
                              margin: "0px",
                              padding: "0px",
                              textTransform: "none",
                            }}
                            onClick={() =>
                              handleOpenDeliveryDetailsModal(item, true)
                            }
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={3}
                                className={classes.firstGridField}
                              >
                                <Typography align="left">
                                  {stockItemsDetailsObj[item.stockItemId]}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridField}>
                                <Typography>{item.orderQty}</Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridField}>
                                <Typography>{item.deliveredQty}</Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridField}>
                                <Typography>{item.unitPrice}</Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className={classes.lastGridField}
                              >
                                <Typography>{item.totalCost}</Typography>
                              </Grid>
                            </Grid>
                          </Button>
                        </CardCommon>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">No Delivered Items</Typography>
                </div>
              )}
            </>
          ) : (
            // If loading, show skeleton loading indicators
            <div style={{ marginTop: "40px" }}>{handleSkeleton()}</div>
          )}
        </DialogContent>

        <DialogActions>
          <div style={{ display: "block", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "18px",
                alignItems: "center",
              }}
            >
              {selectedStockOrderDetails.status === "delivered" ? (
                <Button
                  onClick={() => setIsOpenEditDeliveryDetailsModal(false)}
                  style={{ textTransform: "none" }}
                  color="secondary"
                  autoFocus
                  disabled={isDisable}
                >
                  Exit
                </Button>
              ) : (
                <Button
                  onClick={handleExitPage}
                  style={{ textTransform: "none" }}
                  color="secondary"
                  autoFocus
                  disabled={isDisable}
                >
                  Save & Exit
                </Button>
              )}
              <Button
                onClick={handlePlaceOrder}
                style={{ textTransform: "none" }}
                color="secondary"
                autoFocus
                disabled={
                  isDisable ||
                  handleDisable() ||
                  selectedStockOrderDetails.status === "delivered"
                }
              >
                Confirm Delivery
              </Button>
            </div>
          </div>
        </DialogActions>
      </DialogCommonDefault>

      {isOpenDeliveryDetailsModal && (
        <DeliveryDetailsModal
          isOpenDeliveryDetailsModal={isOpenDeliveryDetailsModal}
          setIsOpenDeliveryDetailsModal={setIsOpenDeliveryDetailsModal}
          deliveryDetailsNode={deliveryDetailsNode}
          handleUpdateDelivery={handleUpdateDelivery}
          setIsNotDelivery={setIsNotDelivery}
          selectedStockOrderDetails={selectedStockOrderDetails}
        />
      )}
    </>
  );
};

export default EditDeliveryStockOrderItem;

import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import Pagination from "../../../../components/common/Pagination";
import { fetchAllStockOrdersInfo } from "../../../../services/inventory/stockOrder";
import StockOrdersHeader from "./StockOrdersHeader";
import AddStockOrderModal from "./AddStockOrderModal";
import AddStockOrderItemModal from "./AddStockOrderItemModal";
import InventoryStockOrder from "../../../../components/common/InventoryDetails/InventoryStockOrder";
import { fetchAllSupplierInfo } from "../../../../services/inventory/supplier";
import InventoryStockOrderFilter from "../../../../components/common/InventoryDetails/InventoryStockOrederFilter";
import {
  saleListFilterObject,
  stockOrderFilterObject,
} from "../../../../utils/consts/list";
import { getLocationBasicInfo } from "../../../../services/locationApp/storeService";
import EditDeliveryStockOrderItem from "./EditDeliveryStockOrderItem";
import DeliveryDetailsModal from "./DeliveryDetailsModal";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * This component manages the display of stock order information and related UI components.
 * It fetches data from APIs, handles user interactions, and renders various UI elements.
 * The component includes features such as sorting, pagination, and filtering of stock orders.
 * Additionally, it provides the ability to create and edit stock order items through modal dialogs.
 * Access control is enforced using the 'withAuthority' higher-order component.
 */
const StockOrders: React.FunctionComponent = () => {
  const [stockOrdersNodeList, setStockOrdersNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("updated");
  const [sortingMethod, setSortingMethod] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isSort, setIsSort] = useState(false);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [isOpenCreateStockOrderModal, setIsOpenCreateStockOrderModal] =
    useState(false);
  const [supplierId, setSupplierId] = useState("");
  const [success, setSuccess] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isOpenCreateStockOrderItemModal, setIsOpenCreateStockOrderItemModal] =
    useState(false);
  const [isOpenEditDeliveryDetailsModal, setIsOpenEditDeliveryDetailsModal] =
    useState(false);
  const [supplierNode, setSupplierNode] = useState<any>([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [selectedStockOrderDetails, setSelectedStockOrderDetails] =
    useState<any>([]);
  const [locationData, setLocationData] = useState<any>([]);
  const [isOpenDeliveryDetailsModal, setIsOpenDeliveryDetailsModal] =
    useState(false);
  const [deliveryDetailsNode, setDeliveryDetailsNode] = useState<any>([]);

  const match: any = useRouteMatch();

  /**
   * Function to fetch stock orders information using API call and update component states.
   * @param {number} pageSize - Number of items to display per page.
   * @param {number} currentPage - Current page number.
   * @param {string} sortingMethod - Sorting method for the items.
   * @param {string} headerName - Name of the header to sort by.
   */
  const getStockOrderInfo = async (
    pageSize: number,
    currentPage: number,
    sortingMethod: string,
    headerName: string,
  ) => {
    // Set the pageSize and currentPage states for pagination
    setPageSize(pageSize);
    setCurrentPage(currentPage);

    try {
      // Attempt to fetch stock orders information using the 'fetchAllStockOrdersInfo' API
      const res = await fetchAllStockOrdersInfo(
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
      );
      // Update component states with the received data from the API response
      // Set loading state to false
      setIsLoading(false);
      // Set stock orders data
      setStockOrdersNodeList(res.data.data);
      // Set total number of pages
      setTotalPages(res.data.totalPages);
      // Disable skeleton loading
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  const getAllSupplierInfo = async () => {
    try {
      const res = await fetchAllSupplierInfo(match.params.locationId);
      setSupplierNode(res.data.data);
      getAllFilterLocation("");
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Order"
    document.title = "Eat Presto - Stock Order";

    // Fetch location information with an empty searchName
    getAllSupplierInfo();

    fetchBasicInfo();
  }, []);

  /**
   * Function to fetch location information and initialize stock orders loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async (searchName: string) => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations(searchName);

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Trigger the function to fetch stock orders information with appropriate parameters
      getStockOrderInfo(pageSize, 1, "desc", "updated");

      setIsGetLocationInfo(true);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Function to handle pagination change using selector.
   * @param {any} pageSize - Number of items to display per page.
   */
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Check if a headerName is provided for sorting
    if (headerName) {
      // If headerName is provided, fetch stock orders information with sorting and filtering
      getStockOrderInfo(pageSize, 1, sortingMethod, headerName);
    } else {
      // If headerName is not provided, fetch stock orders information without sorting
      getStockOrderInfo(pageSize, 1, sortingMethod, "");
    }
  };

  /**
   * Function to handle pagination change using page number.
   * @param {any} currentPage - The selected page number.
   */
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Fetch stock orders information with the updated pagination parameters
    getStockOrderInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to handle sorting of stock orders list.
   * @param {any} headerName - The selected header name for sorting.
   * @param {any} sortingMethod - The selected sorting method.
   */
  const handleChangeStockGroupsListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    // Set the 'disableButton' state to true to disable the sorting button temporarily
    setDisableButton(true);

    setIsSort(true);

    // Set the 'headerName' state with the selected header name
    setHeaderName(headerName);

    // Set the 'sortingMethod' state with the selected sorting method
    setSortingMethod(sortingMethod);

    // Fetch stock orders information with the updated sorting parameters
    getStockOrderInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to remove sorting from the stock orders list.
   */
  const handleRemoveStockGroupsListSorting = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    setIsSort(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock orders information without sorting
    getStockOrderInfo(pageSize, currentPage, "desc", "updated");
  };

  /**
   * Function to remove sorting from the stock orders list.
   */
  const handleGetStockOrdersListSorting = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    setIsSort(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock orders information without sorting
    getStockOrderInfo(pageSize, currentPage, "desc", "updated");
  };

  /**
   * Function to handle opening the create stock orders modal.
   */
  const handleOpenCreateStockOrderModal = () => {
    // Open the 'isOpenCreateStockOrderModal'
    setIsOpenCreateStockOrderModal(true);
  };

  const handleOpenAddStockOrderItemModal = (
    orderId: string,
    supplierId: string,
    nodeData: any,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setSelectedStockOrderDetails(nodeData);
    setOrderId(orderId);
    setSupplierId(supplierId);
    setIsOpenCreateStockOrderItemModal(true);
  };

  const fetchBasicInfo = async () => {
    try {
      const res = await getLocationBasicInfo(match.params.locationId);
      setLocationData(res.data.data);
    } catch (error) {}
  };

  const handleFilterData = () => {};

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const handleOpenStockDeliveryItemModal = (
    orderId: string,
    supplierId: string,
    nodeData: any,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setSelectedStockOrderDetails(nodeData);
    setOrderId(orderId);
    setSupplierId(supplierId);
    setIsOpenEditDeliveryDetailsModal(true);
  };

  const [isDeliver, setIsDelivery] = useState(false);
  const [isNotDelivery, setIsNotDelivery] = useState(false);
  const handleOpenDeliveryDetailsModal = (item: any, isDelivery: any) => {
    setIsNotDelivery(false);
    setIsDelivery(isDelivery);
    setIsOpenDeliveryDetailsModal(true);
    setDeliveryDetailsNode(item);
  };

  return (
    <>
      {/* Main container */}
      <Box>
        {/* InventoryDetails component */}
        <InventoryStockOrderFilter
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          nodeList={stockOrdersNodeList}
          topic="Stock Orders"
          handleOpenCreateModal={handleOpenCreateStockOrderModal}
          buttonName="Create a new order"
          handleFilterData={handleFilterData}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={stockOrderFilterObject}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
        >
          {/* StockOrders table component */}
          <StockOrdersHeader
            handleChangeOrderListSorting={handleChangeStockGroupsListSorting}
            handleRemoveOrderListSorting={handleRemoveStockGroupsListSorting}
            nodeData={stockOrdersNodeList}
            disableButton={disableButton}
            isLoading={isLoading}
            locationSelectorList={locationSelectorList}
            handleOpenAddStockOrderItemModal={handleOpenAddStockOrderItemModal}
            isSort={isSort}
            headerName={headerName}
            sortingMethod={sortingMethod}
            supplierNode={supplierNode}
            openFilterCard={openFilterCard}
            handleOpenStockDeliveryItemModal={handleOpenStockDeliveryItemModal}
          />

          {isOpenCreateStockOrderItemModal && (
            <AddStockOrderItemModal
              isOpenCreateStockOrderModal={isOpenCreateStockOrderItemModal}
              setIsOpenCreateStockOrderModal={
                setIsOpenCreateStockOrderItemModal
              }
              setSuccess={setSuccess}
              setError={setError}
              handleGetStockOrdersListSorting={handleGetStockOrdersListSorting}
              supplierId={supplierId}
              orderId={orderId}
              selectedStockOrderDetails={selectedStockOrderDetails}
              locationData={locationData}
            />
          )}

          {isOpenEditDeliveryDetailsModal && (
            <EditDeliveryStockOrderItem
              isOpenEditDeliveryDetailsModal={isOpenEditDeliveryDetailsModal}
              setIsOpenEditDeliveryDetailsModal={
                setIsOpenEditDeliveryDetailsModal
              }
              setSuccess={setSuccess}
              setError={setError}
              handleGetStockOrdersListSorting={handleGetStockOrdersListSorting}
              supplierId={supplierId}
              orderId={orderId}
              selectedStockOrderDetails={selectedStockOrderDetails}
              locationData={locationData}
              handleOpenDeliveryDetailsModal={handleOpenDeliveryDetailsModal}
              isOpenDeliveryDetailsModal={isOpenDeliveryDetailsModal}
              setIsOpenDeliveryDetailsModal={setIsOpenDeliveryDetailsModal}
              deliveryDetailsNode={deliveryDetailsNode}
              isDeliver={isDeliver}
              setIsNotDelivery={setIsNotDelivery}
              isNotDelivery={isNotDelivery}
            />
          )}

          {/* Render Pagination if stockOrdersNodeList is not empty */}
          {!_.isEmpty(stockOrdersNodeList) && (
            <Pagination
              handleChangePaginationUsingSelector={
                handleChangePaginationUsingSelector
              }
              handleChangePaginationUsingPageNumber={
                handleChangePaginationUsingPageNumber
              }
              totalPages={totalPages}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          )}
        </InventoryStockOrderFilter>
        {isOpenCreateStockOrderModal && (
          <AddStockOrderModal
            isOpenCreateStockOrderModal={isOpenCreateStockOrderModal}
            setIsOpenCreateStockOrderModal={setIsOpenCreateStockOrderModal}
            handleOpenAddStockOrderItemModal={handleOpenAddStockOrderItemModal}
            setSuccess={setSuccess}
            setError={setError}
            handleGetStockOrdersListSorting={handleGetStockOrdersListSorting}
          />
        )}
        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(StockOrders, Authorities.ORDERS_READ);

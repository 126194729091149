import React, { useCallback, useEffect, useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";

import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";

export interface OrdersDetailsTabProps {
  nodeData: any;
  locationName: any;
  updateTime: any;
  createdTime: any;
  handleOpenStockLevelModal: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    gridStyle: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "20px",
    },
  }),
);

/**
 * This React component displays detailed information about a stock item.
 * It includes various details such as location, name, barcode, thresholds, and timestamps.
 */
const StockItemDetailsNode: React.FunctionComponent<OrdersDetailsTabProps> = ({
  nodeData,
  locationName,
  updateTime,
  createdTime,
  handleOpenStockLevelModal,
}) => {
  const [deliveryType, setDeliveryType] = useState("");
  const [criticalThreshold, setCriticalThreshold] = useState("");
  const [inputMultiplier, setInputMultiplier] = useState("");
  const [inputSuffix, setInputSuffix] = useState("");
  const [sku, setSku] = useState("");
  const [warningThreshold, setWarningThreshold] = useState("");
  const [name, setName] = useState("");
  const [barcode, setBarcode] = useState("");
  const [displaySuffix, setDisplaySuffix] = useState("");

  /**
   * Update Component State with Node Data
   *
   * This function is used to update the component's state with data from the provided nodeData object.
   * It extracts specific properties from the nodeData object and sets them in the component's state.
   *
   * @param {Object} nodeData - The data object containing stock item details.
   */
  const setNodeDataToState = useCallback((nodeData) => {
    // Destructure properties from the nodeData object
    const {
      barcode,
      criticalThreshold,
      inputMultiplier,
      inputSuffix,
      sku,
      warningThreshold,
      name,
      displaySuffix,
    } = nodeData;

    // Update component state with extracted properties
    setDisplaySuffix(displaySuffix);
    setBarcode(barcode);
    setWarningThreshold(warningThreshold);
    setName(name);
    setSku(sku);
    setInputSuffix(inputSuffix);
    setInputMultiplier(inputMultiplier);
    setCriticalThreshold(criticalThreshold);
    setDeliveryType(deliveryType);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /**
   * This array contains a collection of label-value pairs representing various pieces of information
   * related to a stock item. It's used to render these details dynamically in the component.
   */
  const informationItems = [
    { label: "Location", value: locationName },
    { label: "Name", value: name },
    { label: "Barcode", value: barcode },
    { label: "Critical Threshold", value: criticalThreshold },
    { label: "Input Multiplier", value: inputMultiplier },
    { label: "Input Suffix", value: inputSuffix },
    { label: "Sku", value: sku },
    { label: "Warning Threshold", value: warningThreshold },
    { label: "Display Suffix", value: displaySuffix },
    { label: "Created Time", value: createdTime },
    { label: "Update Time", value: updateTime },
  ];

  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <CardCommon backgroundColor={"entity_background"}>
      <Grid container style={{ margin: "10px", marginRight: "50px" }}>
        <Grid item xs={12} md={6}>
          <CardCommon backgroundColor={"entity_highlight_background"}>
            <Grid container style={{ marginTop: "12px" }}>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <ButtonCommon
                  style={{
                    fontSize: 11,
                    marginRight: 4,
                    width: "160px",
                    marginLeft: "20px",
                  }}
                  variant="contained"
                  color={"green"}
                  onClick={() => handleOpenStockLevelModal(nodeData)}
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "inherit",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    Stock Level
                  </Typography>
                </ButtonCommon>
              </Grid>
              {informationItems.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={5} style={{ marginBottom: "8px" }}>
                    <Typography
                      style={{
                        color: theme.palette.custom.orange.contrastText,
                        fontSize: "12px",
                        paddingLeft: "20px",
                        marginTop: "4px",
                      }}
                      align="left"
                    >
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    className={classes.gridStyle}
                    style={{ marginTop: "4px", marginBottom: "8px" }}
                  >
                    <Typography
                      style={{
                        color: theme.palette.custom.orange.contrastText,
                        fontSize: "12px",
                      }}
                      align="left"
                    >
                      {item.value}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>{" "}
          </CardCommon>
        </Grid>
      </Grid>
    </CardCommon>
  );
};

export default StockItemDetailsNode;

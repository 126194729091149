import React from "react";
import withAuthority from "../../Auth/withAuthority";
import Authorities from "../../../auth/authorities";
import { Skeleton } from "@material-ui/lab";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { CustomTheme } from "../../../types/customTheme";
import CardCommon from "../../card/CardCommon";

export interface SkeleTonLoadingProps {
  isReport: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    buttonStyle: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    rowOdd: {
      backgroundColor: theme.palette.background.paper,
    },
    rowEven: {
      backgroundColor: theme.palette.background.entity_background,
    },
  }),
);

const SkeleTonLoading: React.FunctionComponent<SkeleTonLoadingProps> = ({
  isReport,
}) => {
  const classes = useStyles();
  return (
    <Grid container style={{ marginTop: "32px" }}>
      <Grid item xs={12}>
        <Skeleton
          variant="text"
          className={classes.buttonStyle}
          width={280}
          height={32}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="text"
          className={classes.buttonStyle}
          width={280}
          height={32}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Skeleton
          variant="rect"
          className={classes.buttonStyle}
          width={280}
          height={40}
        />
        {isReport && (
          <Skeleton
            variant="rect"
            className={classes.buttonStyle}
            width={130}
            height={40}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <CardCommon>
          <Skeleton
            variant="rect"
            width={"100%"}
            className={classes.rowOdd}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
        </CardCommon>
      </Grid>
    </Grid>
  );
};

export default withAuthority(SkeleTonLoading, Authorities.DASHBOARD_READ);

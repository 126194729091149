import React, { useState } from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      width: "160px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface PaginationUsingSelectorProps {
  handleChangePaginationUsingSelector: any;
  pageSize: any;
}

/* Show the page size selection dropdown. */
const PaginationUsingSelector: React.FunctionComponent<
  PaginationUsingSelectorProps
> = ({ handleChangePaginationUsingSelector, pageSize }) => {
  // Set how many orders are showing on page and after api call
  const handleChangePageSize = (e: any) => {
    const { value } = e.target;
    handleChangePaginationUsingSelector(value);
  };{console.log("sdfdsfadsasdadsdasd", pageSize)}
  const classes = useStyles();
  return (
    <>
      <Grid
        item
        sm={6}
        md={5}
        xs={12}
        style={{ display: "flex", justifyContent: "start" }}
      >
        <TextField
          fullWidth
          id="pagination"
          select
          label="Select page size"
          value={pageSize}
          className={classes.textField}
          onChange={handleChangePageSize}
          variant="outlined"
          size="small"
        >
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="20">20</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
        </TextField>
      </Grid>
    </>
  );
};

export default PaginationUsingSelector;

import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import { CustomTheme } from "../../types/customTheme";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogPaperMap: {
    minWidth: "70vw", // Set your desired minimum width here
    minHeight: "90vh",
    [theme.breakpoints.down("md")]: {
      minWidth: "90vw",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
  dialogPaper: {
    minWidth: "440px", // Set your desired minimum width here
    // [theme.breakpoints.down("md")]: {
    //   minWidth: "90vw",
    // },
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
}));

export interface DialogCommonDefaultProps {
  open: boolean;
  setOpen: any;
  children: any;
  isOpenMapProd: any;
}

/* Common modal UI.. */
const DialogCommonDefault: React.FunctionComponent<
  DialogCommonDefaultProps
> = ({ open, setOpen, children, isOpenMapProd }) => {
  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 10,
          border: `1px solid ${theme.palette.background.entity_border}`,
          backgroundColor: theme.palette.background.entity_background,
        },
      }}
      classes={
        isOpenMapProd
          ? { paper: classes.dialogPaperMap }
          : { paper: classes.dialogPaper }
      }
      // maxWidth={maxWidth}
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick
    >
      {children}
    </Dialog>
  );
};

export default DialogCommonDefault;

import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import _ from "lodash";

export interface DepartmentSelectDesktopViewProps {
  handleButtonClick: any;
  activeGroupId: any;
  selectedStockOrderItemsNode: any;
  selectedDepartmentId: any;
  isDisable: any;
}

/**
 * This component represents the DepartmentSelect view for the Desktop version.
 * It displays the list of departments and their items in a button format.
 */
const DepartmentSelectDesktopView: React.FunctionComponent<
  DepartmentSelectDesktopViewProps
> = ({
  handleButtonClick,
  activeGroupId,
  selectedStockOrderItemsNode,
  selectedDepartmentId,
  isDisable,
}) => {
  return (
    <>
      {!_.isEmpty(selectedStockOrderItemsNode)
        ? selectedStockOrderItemsNode.map(
            (department: any, index: number) =>
              activeGroupId === index &&
              department.stockDepartmentId.map((data: any) => (
                <div>
                  <Button
                    onClick={handleButtonClick(`department ${data.id}`)}
                    fullWidth
                    style={{
                      textTransform: "none",
                      display: "flex",
                      justifyContent: "start",
                    }}
                    disabled={isDisable}
                  >
                    <Typography
                      style={
                        selectedDepartmentId === data.id
                          ? { fontWeight: "bold" }
                          : { fontWeight: "normal" }
                      }
                    >
                      {data.name}
                    </Typography>
                  </Button>
                </div>
              )),
          )
        : activeGroupId === 0 && (
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "4px",
                  display: "flex",
                  justifyContent: "Center",
                }}
              >
                <Typography variant="h4">No Selected items</Typography>
              </Grid>
            </Grid>
          )}
    </>
  );
};

export default DepartmentSelectDesktopView;

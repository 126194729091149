import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Theme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import StockItemDetails from "./StockItemDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridField: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "11px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldSelected: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "11px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      color: theme.palette.custom.green.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9px",
      marginTop: "2px",
      marginBottom: "8px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldLastColumnSelected: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9px",
      marginTop: "2px",
      marginBottom: "8px",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      color: theme.palette.custom.green.contrastText,
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldLastColumnDisplayView: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      paddingLeft: "16px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    gridFieldFirstColumnSelected: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      color: theme.palette.custom.green.contrastText,
      fontSize: "13px",
      paddingLeft: "16px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    detailsDropDownIcon: {
      color: theme.palette.custom.orange.contrastText,
    },
    detailsDropDownIconSelected: {
      color: theme.palette.custom.green.contrastText,
    },
  }),
);

export interface DiscountInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  handleOpenStockLevelModal: any;
  departmentName: any;
  stockLevelObj: any;
}

/* This component creates the data for the table using the data received from the API call.  */
const StockItemInfoNode: React.FunctionComponent<DiscountInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  handleOpenStockLevelModal,
  departmentName,
  stockLevelObj,
}) => {
  const [updateTime, setUpdateTime] = useState("");
  const [inputMultiplier, setInputMultiplier] = useState("");
  const [inputSuffix, setInputSuffix] = useState("");
  const [name, setName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [displaySuffix, setDisplaySuffix] = useState("");
  const [createdTime, setCreatedTime] = useState("");
  const [openOrderDetailsCard, setOpenOrderDetailsCard] = useState(false);
  const [stockId, setStockId] = useState("");
  const [version, setVersion] = useState(0);

  /* Update states after getting the stock item information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const {
      id,
      created,
      inputMultiplier,
      inputSuffix,
      updated,
      primaryLocationId,
      name,
      displaySuffix,
      version,
    } = nodeValues;

    setVersion(version);
    setStockId(id);
    setDisplaySuffix(displaySuffix);
    setName(name);
    setInputSuffix(inputSuffix);
    setInputMultiplier(inputMultiplier);
    setCreatedTime(moment(created).format("MMM D, YYYY HH:mm"));
    setUpdateTime(moment(updated).format("MMM D, YYYY HH:mm"));

    // Get the location name using locationId
    const locationDetails = getFilterListFromArrayObject(
      locationSelectorList,
      primaryLocationId,
    );

    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      setLocationName(locationDetails[0].label);
    } else {
      // If there is no description of the location, the state will be entered as 'Unknown location'.
      setLocationName("Unknown location");
    }
  }, []);

  /**
   * This function determines the background color of a row based on the value of 'openOrderDetailsCard'.
   * If 'openOrderDetailsCard' is truthy, it returns "green" as the row color.
   * If 'openOrderDetailsCard' is falsy, it doesn't return a color, which means the default row color will be used.
   */
  const handleChangeRowColor = () => {
    // Check if 'openOrderDetailsCard' is truthy
    if (openOrderDetailsCard) {
      // If 'openOrderDetailsCard' is truthy, return "green" as the row color
      return "green";
    }
  };

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /**
   * This function opens the stock level modal with the appropriate data.
   */
  const handleOpenStockLevel = () => {
    // Retrieve the stock level and version from stockLevelObj using stockId, or default to 0 if not found.
    const stockLevel = stockLevelObj[stockId]?.data.stockLevel || 0;
    const version = stockLevelObj[stockId]?.data.version || 0;

    // Call the handleOpenStockLevelModal function with the retrieved data.
    handleOpenStockLevelModal(stockId, stockLevel, version);
  };

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();

  return (
    <>
      <CardCommon
        color={handleChangeRowColor()}
        backgroundColor={"entity_background"}
      >
        <Button
          style={{ width: "100%", margin: "0px", padding: "0px" }}
          onClick={() => setOpenOrderDetailsCard(!openOrderDetailsCard)}
        >
          <Grid container>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={
                openOrderDetailsCard
                  ? classes.gridFieldFirstColumnSelected
                  : classes.gridFieldFirstColumn
              }
            >
              {departmentName.name}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={
                openOrderDetailsCard
                  ? classes.gridFieldSelected
                  : classes.gridField
              }
            >
              {locationName}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={
                openOrderDetailsCard
                  ? classes.gridFieldSelected
                  : classes.gridField
              }
            >
              {name}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={
                openOrderDetailsCard
                  ? classes.gridFieldSelected
                  : classes.gridField
              }
            >
              {displaySuffix}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={1}
              className={
                openOrderDetailsCard
                  ? classes.gridFieldSelected
                  : classes.gridField
              }
            >
              {stockLevelObj[stockId]
                ? stockLevelObj[stockId]["data"]["stockLevel"]
                : 0}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={1}
              className={
                openOrderDetailsCard
                  ? classes.gridFieldSelected
                  : classes.gridField
              }
            >
              {inputSuffix}
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              className={
                openOrderDetailsCard
                  ? classes.gridFieldLastColumnSelected
                  : classes.gridFieldLastColumn
              }
            >
              <div className={classes.gridFieldLastColumnDisplayView}>
                {inputMultiplier}
                <div>
                  <Tooltip title="Stock Level" style={{ marginLeft: "4px" }}>
                    <IconButton
                      onClick={(event: any) =>
                        handleOpenStockLevelModal(
                          stockId,
                          stockLevelObj[stockId]
                            ? stockLevelObj[stockId]["data"]["stockLevel"]
                            : 0,
                          stockLevelObj[stockId]
                            ? stockLevelObj[stockId]["data"]["version"]
                            : 0,
                          event,
                        )
                      }
                    >
                      <EditIcon
                        style={
                          openOrderDetailsCard
                            ? { fontSize: "16px", color: "white" }
                            : { fontSize: "16px" }
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                {/* <div>
                  <Tooltip
                    title="Add Map Stock"
                    style={{ marginLeft: "4px", marginRight: "4px" }}
                  >
                    <IconButton
                      onClick={(event: any) =>
                        handleOpenEditMapProductsItemsModal(nodeData.id, event)
                      }
                    >
                      <BorderColorIcon style={
                          openOrderDetailsCard
                            ? { fontSize: "16px", color: "white" }
                            : { fontSize: "16px" }
                        } />
                    </IconButton>
                  </Tooltip>
                </div> */}
                {!maxWidth &&
                  (openOrderDetailsCard ? (
                    <ArrowDropUpIcon
                      className={
                        openOrderDetailsCard
                          ? classes.detailsDropDownIconSelected
                          : classes.detailsDropDownIcon
                      }
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className={
                        openOrderDetailsCard
                          ? classes.detailsDropDownIconSelected
                          : classes.detailsDropDownIcon
                      }
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
      {openOrderDetailsCard && (
        <StockItemDetails
          nodeData={nodeData}
          locationName={locationName}
          updateTime={updateTime}
          createdTime={createdTime}
          handleOpenStockLevelModal={handleOpenStockLevel}
        />
      )}
    </>
  );
};

export default withAuthority(StockItemInfoNode, Authorities.ORDERS_READ);

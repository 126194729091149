import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete, Skeleton } from "@material-ui/lab";

import {
  createStockDepartments,
  fetchAllStockGroups,
  updateStockDepartments,
} from "../../../../services/inventory/stockItems";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../utils/consts";
import { CustomTheme } from "../../../../types/customTheme";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";

const useStyles = makeStyles((theme: CustomTheme) => ({
  autoComplete: {
    marginTop: "4px",
    "& .MuiAutocomplete-input": {
      color: theme.palette.custom.orange.contrastText,
    },
    "& .MuiInputBase-root": {
      color: "inherit",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: 10,
    },
    [`& fieldset`]: {
      borderRadius: 10,
      border: `1px solid ${theme.palette.background.entity_border}`,
      color: theme.palette.custom.orange.contrastText,
    },
  },
  titleColor: {
    color: theme.palette.custom.orange.contrastText,
  },
}));

export interface stockDepartmentModalProps {
  isOpenStockDepartmentModal: any;
  setIsOpenStockDepartmentModal: any;
  handleGetStockDepartmentList: any;
  setSuccess: any;
  setError: any;
  isOpenStockDepartmentEditModal: any;
  setDepartmentName: any;
  departmentName: any;
  version: any;
  departmentId: any;
  groupId: any;
  departmentNameInitial: any;
}

/**
 * This component represents a modal dialog for adding or updating stock departments.
 * It provides functionality for selecting a stock group, entering a department name,
 * and performing the respective actions (create or update) via API calls.
 * The modal can be used for both adding new departments and updating existing ones.
 */
const AddStockDepartmentModal: React.FunctionComponent<
  stockDepartmentModalProps
> = ({
  isOpenStockDepartmentModal,
  setIsOpenStockDepartmentModal,
  handleGetStockDepartmentList,
  setSuccess,
  setError,
  isOpenStockDepartmentEditModal,
  setDepartmentName,
  departmentName,
  version,
  departmentId,
  groupId,
  departmentNameInitial,
}) => {
  const [open, setOpen] = useState(false);
  const [stockGroupSelectedNode, setStockGroupSelectedNode] = useState<any>([]);
  const [selectedGroupObj, setSelectedGroupObj] = useState<any>({});
  const [selectedGroupObjInitial, setSelectedGroupObjInitial] = useState<any>(
    {},
  );
  const [isLoading, setIsLoading] = useState(true);
  const [initialDepartmentName, setInitialDepartmentName] = useState("");

  const match: any = useRouteMatch();

  useEffect(() => {
    setInitialDepartmentName(departmentName);
    if (isOpenStockDepartmentModal) {
      getAllStockGroups();
    }
  }, [isOpenStockDepartmentModal]);

  /**
   * Handler for changing the department name input.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleChangeDepartmentName = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Update the department name state with the new value from the input
    setDepartmentName(e.target.value);
  };

  /**
   * Handler for creating a new department.
   * This function submits the new department details to the API,
   * handles success and error cases, and updates relevant state.
   */
  const handleCreateNewDepartment = async () => {
    // Prepare form data for creating a new department
    const formData = {
      name: departmentName,
      locationId: match.params.locationId,
      stockGroupId: selectedGroupObj.id,
    };
    try {
      // Call the API to create a new stock department
      await createStockDepartments(match.params.locationId, formData);

      // Get the stock department list
      handleGetStockDepartmentList();

      // Reset the selected group object and set success message
      setSelectedGroupObj({});
      setSuccess(SUCCESSFULLY_CREATED);

      // Close the modal
      setIsOpenStockDepartmentModal(false);
    } catch (err) {
      // If an error occurs during API call, set error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetches all available stock groups for the current location.
   * This function populates the stock group selection options and handles initial values
   * when editing a department.
   */
  const getAllStockGroups = async () => {
    // Set loading state to true before starting API call
    setIsLoading(true);

    try {
      // Fetch all stock groups for the current location from the API
      const res = await fetchAllStockGroups(match.params.locationId);

      // Check if the response data is available
      if (res.data.data) {
        const stockGroups = res.data.data;
        // If in edit mode, filter the stock groups to select the one corresponding to the department's group
        if (isOpenStockDepartmentEditModal) {
          const selectedGroup = stockGroups.find(
            (group: any) => group.id === groupId,
          );

          // Set the selected group object and its initial value for comparison
          setSelectedGroupObj(selectedGroup);
          setSelectedGroupObjInitial(selectedGroup);
        } else {
          // Clear the selected group object when adding a new department
          setSelectedGroupObj({});
        }

        // Update the list of stock group options
        setStockGroupSelectedNode(res.data.data);
      }

      // Set loading state to false after API call is complete
      setIsLoading(false);
    } catch (err) {
      // If an error occurs during the API call, handle error state
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handler for updating a department.
   * This function submits the updated department details to the API,
   * handles success and error cases, and updates relevant state.
   */
  const handleUpdateDepartment = async () => {
    // Prepare form data for updating the department
    const formData = {
      id: departmentId,
      name: departmentName,
      locationId: match.params.locationId,
      version: version,
      stockGroupId: selectedGroupObj.id,
    };

    try {
      // Call the API to update the stock department
      await updateStockDepartments(match.params.locationId, formData);

      // Update the stock department list
      handleGetStockDepartmentList();

      // Reset the selected group object, set success message, and close the modal
      setSelectedGroupObj({});
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenStockDepartmentModal(false);
    } catch (err) {
      // If an error occurs during API call, set error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handler for submitting the department form.
   * This function determines whether to create a new department or update an existing one,
   * and then invokes the appropriate handler function accordingly.
   */
  const handleSubmit = () => {
    if (isOpenStockDepartmentEditModal) {
      // If in edit mode, call the function to update the department
      handleUpdateDepartment();
    } else {
      // If not in edit mode, call the function to create a new department
      handleCreateNewDepartment();
    }
  };

  /**
   * Optimized version using arrow function.
   * This version achieves the same functionality in a concise and clear manner.
   * @param {any} e - The event object.
   * @param {any} groupData - The selected stock group data.
   */
  const handleGroupChange = (e: any, groupData: any) =>
    setSelectedGroupObj(groupData);

  const classes = useStyles();

  return (
    <>
      <DialogCommonDefault
        open={isOpenStockDepartmentModal}
        setOpen={setIsOpenStockDepartmentModal}
        isOpenMapProd={false}
      >
        <DialogTitle>
          {isOpenStockDepartmentEditModal
            ? `Update ${initialDepartmentName} Department`
            : "Add New Department"}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: "12px" }}>
              {!isLoading ? (
                <Autocomplete
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  size={"small"}
                  id="locationSelectGlobal"
                  color="inherit"
                  options={stockGroupSelectedNode}
                  value={selectedGroupObj}
                  getOptionLabel={(option: any) => option.name || ""}
                  fullWidth
                  disableClearable
                  onChange={handleGroupChange}
                  classes={{ root: classes.autoComplete }}
                  popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                  renderOption={(props: any) => {
                    return (
                      <Grid
                        container
                        {...props}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          zIndex: 0,
                          height: "20px",
                          marginBottom: "4px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            className={classes.titleColor}
                          >
                            {props.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      color="inherit"
                      {...params}
                      label=""
                      placeholder="Select Group"
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <Skeleton
                  style={{ borderRadius: "10px" }}
                  variant="rect"
                  width={"100%"}
                  height={40}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {!isLoading ? (
                <TextfieldCommon
                  id="departmentName"
                  name="departmentName"
                  type="text"
                  label="Department Name"
                  disabled={false}
                  value={departmentName}
                  onChange={handleChangeDepartmentName}
                />
              ) : (
                <Skeleton
                  style={{ borderRadius: "10px" }}
                  variant="rect"
                  width={"100%"}
                  height={40}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ textTransform: "none" }}
            onClick={() => {
              setIsOpenStockDepartmentModal(false);
              setSelectedGroupObj({});
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            style={{ textTransform: "none" }}
            color="secondary"
            autoFocus
            disabled={
              !departmentName ||
              _.isEmpty(selectedGroupObj) ||
              (departmentNameInitial === departmentName &&
                selectedGroupObj.id === selectedGroupObjInitial.id)
            }
            onClick={handleSubmit}
          >
            {isOpenStockDepartmentEditModal ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default AddStockDepartmentModal;

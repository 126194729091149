import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import {
  fetchAllStockGroups,
  fetchAllStockItemsDepartmentsInfo,
  fetchAllStockItemsGroupsInfo,
  fetchAllStockItemsProductInfo,
  fetchMapProductInfo,
  fetchStockItemInfoGetAll,
} from "../../../../services/inventory/stockItems";
import StockItemsHeader from "./StockItemsHeader";
import InventoryDetails from "../../../../components/common/InventoryDetails";
import AddStockItemsModal from "../../../../components/AddStockItemsModal";
import {
  fetchAllSelectedSuppliersInfo,
  fetchAllSuppliersInfo,
} from "../../../../services/inventory/supplier";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * StockItems Component
 *
 * This React component is responsible for managing and displaying stock items within a specific location.
 * It provides features for creating, and editing stock items, as well as managing suppliers and product mappings.
 * The component is integrated with various APIs to fetch and update stock item information.
 *
 * Key Features:
 * - Fetching and displaying stock item groups, departments, and products.
 * - Sorting and filtering stock items.
 * - Creating and editing stock items.
 * - Managing suppliers and product mappings.
 * - Displaying success and error messages.
 */
const StockItems: React.FunctionComponent = (props: any) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [sortingMethod, setSortingMethod] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [mapProductSkeletonLoading, setMapProductSkeletonLoading] =
    useState(false);
  const [createdId, setCreatedId] = useState([]);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [stockMappingDetailsNode, setStockMappingDetailsNode] = useState([]);
  const [supplierDetailsNode, setSupplierDetailsNode] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [numberOfLocations, setNumberOfLocations] = useState(0);
  const [isOpenCreateStockItemsModal, setIsOpenCreateStockItemsModal] =
    useState(false);
  const [isOpenEditStockItemsModal, setIsOpenEditStockItemsModal] =
    useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [success, setSuccess] = useState("");
  const [menuGroups, setMenuGroups] = useState([]);
  const [menuDepartments, setMenuDepartments] = useState([]);
  const [menuProducts, setMenuProducts] = useState([]);
  const [selectedNodeData, setSelectedNodeData] = useState<any>([]);
  const [isOpenSupplierAddModal, setIsOpenSupplierAddModal] = useState(false);
  const [isOpenSupplierEditModal, setIsOpenSupplierEditModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [
    stockItemGroupDepartmentNodeInSelector,
    setStockItemGroupDepartmentNodeInSelector,
  ] = useState<any>([]);
  const [
    stockItemGroupDepartmentNodeInSelectorInitial,
    setStockItemGroupDepartmentNodeInSelectorInitial,
  ] = useState<any>([]);
  const [activeGroupId, setActiveGroupId] = useState(0);
  const [isOpenMapProductsEditModal, setIsOpenMapProductsEditModal] =
    useState(false);
  const [initialSelectedSupplierList, setInitialSelectedSupplierList] =
    useState<any>([]);
  const [id, setId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [selectedGroupObj, setSelectedGroupObj] = useState<any>({});
  const [stockGroupSelectedNode, setStockGroupSelectedNode] = useState<any>([]);

  const match: any = useRouteMatch();
  // Map to store elements by title
  const elementsByTitle: any = {};

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Item"
    document.title = "Eat Presto - Stock Item";

    // Fetch location information with an empty searchName
    getAllFilterLocation("");
  }, []);

  /**
   * Function to fetch stock items groups information and update the 'menuGroups' state.
   */
  const handleGetStockItemsGroupsInfo = async (
    headerName: any,
    shortingMethod: any,
  ) => {
    try {
      // Attempt to fetch stock items groups information using the 'fetchAllStockItemsGroupsInfo' API
      const res = await fetchAllStockItemsGroupsInfo(
        match.params.locationId,
        headerName,
        shortingMethod,
      );

      // Set the 'menuGroups' state with the retrieved data from the response
      setMenuGroups(res.data.data);
    } catch (err) {
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock items departments information and update the 'menuDepartments' state.
   */
  const handleGetStockItemsDepartmentsInfo = async () => {
    try {
      // Attempt to fetch stock items departments information using the 'fetchAllStockItemsDepartmentsInfo' API
      const res = await fetchAllStockItemsDepartmentsInfo(
        match.params.locationId,
      );

      // Set the 'menuDepartments' state with the retrieved data from the response
      setMenuDepartments(res.data.data);
    } catch (err) {
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock items products information and update the 'menuProducts' state.
   */
  const handleGetStockItemsProductsInfo = async () => {
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await fetchAllStockItemsProductInfo(match.params.locationId);

      // Set the 'menuProducts' state with the retrieved data from the response
      setMenuProducts(res.data.data);
    } catch (err) {
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock items groups, departments, and products information collectively.
   * Calls individual functions to retrieve and update corresponding states.
   */
  const handleGetProductDepartmentGroup = (
    headerName: any,
    shortingMethod: any,
  ) => {
    // Call function to fetch stock items groups information and update 'menuGroups' state
    handleGetStockItemsGroupsInfo(headerName, shortingMethod);

    // Call function to fetch stock items departments information and update 'menuDepartments' state
    handleGetStockItemsDepartmentsInfo();

    // Call function to fetch stock items products information and update 'menuProducts' state
    handleGetStockItemsProductsInfo();

    // Call function to fetch supplier information and update 'supplierDetailsNode' state
    handleGetSupplierInfo();

    // Call function to fetch all stock departments for a specific group and update relevant states
    getAllStockDepartmentForGroupNotLoad(groupId);
  };

  /**
   * Transforms the response data into a structured stock order item format.
   * @param {Array} response - The response data containing stock order items. format (item->department->group)
   * @returns {Array} - Transformed stock order item structure. format (group->department->item)
   */
  const handleStockOrderItemStructure = (response: any) => {
    // Initialize an empty array to store the transformed structure
    const transformedStructure = response.reduce(
      (accumulator: any, item: any) => {
        if (item?.stockDepartmentId?.stockGroupId?.id) {
          const stockGroupId = item.stockDepartmentId.stockGroupId.id;
          const existingGroup = accumulator?.find(
            (group: any) => group.id === stockGroupId,
          );

          if (existingGroup) {
            const existingDept = existingGroup.stockDepartmentId.find(
              (dept: any) => dept.id === item.stockDepartmentId.id,
            );

            if (existingDept) {
              existingDept.item.push({
                id: item.id,
                name: item.name,
                barcode: item.barcode,
                active: item.active,
                created: item.created,
                criticalThreshold: item.criticalThreshold,
                displaySuffix: item.displaySuffix,
                inputMultiplier: item.inputMultiplier,
                inputSuffix: item.inputSuffix,
                primaryLocationId: item.primaryLocationId,
                sku: item.sku,
                updated: item.updated,
                version: item.version,
                warningThreshold: item.warningThreshold,
                stockDepartmentId: item.stockDepartmentId.id,
              });
            } else {
              existingGroup.stockDepartmentId.push({
                id: item.stockDepartmentId.id,
                name: item.stockDepartmentId.name,
                locationId: item.stockDepartmentId.locationId,
                created: item.stockDepartmentId.created,
                updated: item.stockDepartmentId.updated,
                version: item.stockDepartmentId.version,
                item: [
                  {
                    id: item.id,
                    name: item.name,
                    barcode: item.barcode,
                    active: item.active,
                    created: item.created,
                    criticalThreshold: item.criticalThreshold,
                    displaySuffix: item.displaySuffix,
                    inputMultiplier: item.inputMultiplier,
                    inputSuffix: item.inputSuffix,
                    primaryLocationId: item.primaryLocationId,
                    sku: item.sku,
                    updated: item.updated,
                    version: item.version,
                    warningThreshold: item.warningThreshold,
                    stockDepartmentId: item.stockDepartmentId.id,
                  },
                ],
              });
            }
          } else {
            accumulator.push({
              id: item.stockDepartmentId.stockGroupId.id,
              name: item.stockDepartmentId.stockGroupId.name,
              version: item.stockDepartmentId.stockGroupId.version,
              updated: item.stockDepartmentId.stockGroupId.updated,
              created: item.stockDepartmentId.stockGroupId.created,
              locationId: item.stockDepartmentId.stockGroupId.locationId,
              stockDepartmentId: [
                {
                  id: item.stockDepartmentId.id,
                  name: item.stockDepartmentId.name,
                  locationId: item.stockDepartmentId.locationId,
                  created: item.stockDepartmentId.created,
                  updated: item.stockDepartmentId.updated,
                  version: item.stockDepartmentId.version,
                  item: [
                    {
                      id: item.id,
                      name: item.name,
                      barcode: item.barcode,
                      active: item.active,
                      created: item.created,
                      criticalThreshold: item.criticalThreshold,
                      displaySuffix: item.displaySuffix,
                      inputMultiplier: item.inputMultiplier,
                      inputSuffix: item.inputSuffix,
                      primaryLocationId: item.primaryLocationId,
                      sku: item.sku,
                      updated: item.updated,
                      version: item.version,
                      warningThreshold: item.warningThreshold,
                      stockDepartmentId: item.stockDepartmentId.id,
                    },
                  ],
                },
              ],
            });
          }
        }

        return accumulator;
      },
      [],
    );

    // Return the transformed structure
    return transformedStructure;
  };

  /**
   * Fetch All Stock Departments for a Group (Without Loading Data)
   *
   * This function is responsible for fetching all stock departments for a specific group without loading data into
   * various states. It performs API requests to obtain the necessary information.
   *
   * @param {any} groupId - The identifier of the group for which departments are fetched.
   */
  const getAllStockDepartmentForGroupNotLoad = async (groupId: any) => {
    // Clear and initialize relevant states
    setStockItemGroupDepartmentNodeInSelector([]);
    setStockItemGroupDepartmentNodeInSelectorInitial([]);
    setSelectedDepartmentId("");
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await fetchStockItemInfoGetAll(match.params.locationId);

      // Check if the API response contains data
      if (res.data.data) {
        // Set the group identifier in state
        setGroupId(groupId);
        // Transform the stock item data into a structured format
        const transformedStructure = handleStockOrderItemStructure(
          res.data.data,
        );

        // Set the transformed data into relevant states
        setStockItemGroupDepartmentNodeInSelector(transformedStructure);
        setStockItemGroupDepartmentNodeInSelectorInitial(transformedStructure);
      }

      // Set loading states to false
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    } catch (err) {
      // Set loading states to false in case of an error
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);

      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * This function is responsible for fetching all stock groups, typically used for populating a menu or selector with
   * available groups. It performs an API request to obtain the necessary information.
   *
   * @param {any} newGroupId - Optional: The identifier of a specific group to select.
   * @param {any} isLoad - Optional: Indicates whether to load data or not.
   */
  const getAllStockGroups = async (newGroupId?: any, isLoad?: any) => {
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await fetchAllStockGroups(match.params.locationId);

      // Set the 'menuProducts' state with the retrieved data from the response
      if (res.data.data) {
        if (newGroupId) {
          // If a new group identifier is provided, find and set the selected group object
          const dept = res.data.data.filter(
            (data: any) => data.id === newGroupId,
          );
          setSelectedGroupObj(dept[0]);
        } else {
          // If no new group identifier is provided, set the selected group object as the first group in the response
          setSelectedGroupObj(res.data.data[0]);
        }

        // Set the 'stockGroupSelectedNode' state with the retrieved data
        setStockGroupSelectedNode(res.data.data);
      } else {
        // Set loading states to false in case of an error
        setIsLoading(false);
        setIsOpenSkeletonLoading(false);
        // If an error occurs during the API call, set the 'error' state with an unexpected error message
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      }
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch location information and initialize stock items loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async (searchName: string) => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations(searchName);

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Set the 'isGetLocationInfo' state to true to indicate location data has been fetched
      setIsGetLocationInfo(true);

      // Trigger the function to fetch stock items information with appropriate parameters
      handleGetProductDepartmentGroup("", "");
      getAllStockGroups();
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle sorting of stock items list.
   * @param {any} headerName - The selected header name for sorting.
   * @param {any} sortingMethod - The selected sorting method.
   */
  const handleChangeStockItemsListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    // Set the 'disableButton' state to true to disable the sorting button temporarily
    setDisableButton(true);

    // Set the 'headerName' state with the selected header name

    // Set the 'sortingMethod' state with the selected sorting method
    setSortingMethod(sortingMethod);

    // Fetch stock items information with the updated sorting parameters
    getAllStockDepartmentForGroupNotLoad(groupId);
  };

  /**
   * Function to remove sorting from the stock items list.
   */
  const handleGetStockItemDetails = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    // Clear the 'headerName' state to remove sorting by header

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock items information without sorting
    getAllStockDepartmentForGroupNotLoad(groupId);
  };

  /**
   * Function to handle opening the create stock items modal.
   */
  const handleOpenCreateStockItemsModal = () => {
    // Reset the 'id' state to empty string
    setId("");
    setCreatedId([]);
    setSelectedGroupObj({});
    // Reset the 'selectedNodeData' state to an empty array
    setSelectedNodeData([]);

    // Close the 'isOpenMapProductsEditModal' if it was open
    setIsOpenMapProductsEditModal(false);

    // Close the 'isOpenEditStockItemsModal' if it was open
    setIsOpenEditStockItemsModal(false);

    // Open the 'isOpenCreateStockItemsModal'
    setIsOpenCreateStockItemsModal(true);
  };

  /**
   * This function is responsible for fetching details of stock mapping for a given stock item.
   *
   * @param {string} stockId - The identifier of the stock item for which mapping details are to be fetched.
   */
  const handleGetStockMappingDetails = async (stockId: string) => {
    try {
      // Attempt to fetch stock mapping details using the 'fetchMapProductInfo' API
      const res = await fetchMapProductInfo(match.params.locationId, stockId);

      // Check if the response status is 404 (Not Found)
      if (res.data.status === 404) {
        // If the stock mapping is not found, set an empty array for mapping details
        setStockMappingDetailsNode([]);
      } else {
        // If mapping details are found, set them in the 'stockMappingDetailsNode' state
        setStockMappingDetailsNode(res.data.data);
      }

      // Disable the loading indicator for mapping details
      setMapProductSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Set an empty array for mapping details
      setStockMappingDetailsNode([]);

      // Disable the loading indicator for mapping details
      setMapProductSkeletonLoading(false);
    }
  };

  /**
   * This function is responsible for fetching information about suppliers.
   */
  const handleGetSupplierInfo = async () => {
    try {
      // Attempt to fetch supplier information using the 'fetchAllSuppliersInfo' API
      const res = await fetchAllSuppliersInfo(
        match.params.locationId,
        5000,
        1,
        "",
        "",
      );

      // Check if the response status is 404 (Not Found)
      if (res.data.status === 404) {
        // If no supplier information is found, set an empty array for supplier details
        setSupplierDetailsNode([]);
      } else {
        // If supplier information is found, set it in the 'supplierDetailsNode' state
        setSupplierDetailsNode(res.data.data);
      }

      // Disable the loading indicator for supplier information
      setMapProductSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message

      // Set an empty array for supplier details
      setSupplierDetailsNode([]);

      // Disable the loading indicator for supplier information
      setMapProductSkeletonLoading(false);

      // Set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle opening the edit stock items modal.
   * @param {string} stockId - The ID of the selected stock item.
   * @param {React.MouseEvent<HTMLLIElement>} event - The event object for the click (optional).
   */
  const handleOpenEditStockItemsModal = (
    nodeData: any,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // Check if an event object is provided and stop its propagation to prevent unintended side effects.
    if (event) {
      event.stopPropagation();
    }
    setCreatedId([]);
    handleGetSelectedSupplier();
    // Reset the 'id' state to an empty string.
    setId(nodeData.id);
    // Set the 'selectedNodeData' state to the provided 'stockId'.
    setSelectedNodeData(nodeData);

    // Close the 'isOpenMapProductsEditModal' if it was open.
    setIsOpenMapProductsEditModal(false);

    // Close the 'isOpenCreateStockItemsModal' if it was open.
    setIsOpenCreateStockItemsModal(true);

    // Open the 'isOpenEditStockItemsModal'.
    setIsOpenEditStockItemsModal(true);
  };

  /**
   * Function to handle opening the edit map products items modal.
   * @param {string} stockId - The ID of the selected stock item.
   * @param {React.MouseEvent<HTMLLIElement>} event - The event object for the click (optional).
   */
  const handleOpenEditMapProductsItemsModal = (
    stockId: string,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // Check if an event object is provided and stop its propagation to prevent unintended side effects.
    if (event) {
      event.stopPropagation();
    }
    setCreatedId([]);
    setMapProductSkeletonLoading(true);
    handleGetStockMappingDetails(stockId);

    // Reset the 'selectedNodeData' state to an empty array.
    setSelectedNodeData({});

    // Close the 'isOpenEditStockItemsModal' if it was open.
    setIsOpenEditStockItemsModal(false);

    // Set the 'id' state to the provided 'stockId'.
    setId(stockId);

    // Close the 'isOpenCreateStockItemsModal' if it was open.
    setIsOpenCreateStockItemsModal(true);

    // Open the 'isOpenMapProductsEditModal'.
    setIsOpenMapProductsEditModal(true);
  };

  /**
   * This function is responsible for fetching information about selected suppliers.
   */
  const handleGetSelectedSupplier = async () => {
    try {
      // Attempt to fetch selected supplier information using the 'fetchAllSelectedSuppliersInfo' API
      const res = await fetchAllSelectedSuppliersInfo(match.params.locationId);

      // Check if the response contains data
      if (res.data.data) {
        // If selected supplier information is found, set it in the 'initialSelectedSupplierList' state
        setInitialSelectedSupplierList(res.data.data);
      } else {
        // If no selected supplier information is found, set an empty array for 'initialSelectedSupplierList'
        setInitialSelectedSupplierList([]);
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message

      // Set an empty array for supplier details
      setSupplierDetailsNode([]);

      // Disable the loading indicator for supplier information
      setMapProductSkeletonLoading(false);

      // Set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * This function is responsible for handling the opening of the supplier edit modal
   * for a specific stock item.
   *
   * @param {string} stockId - The ID of the stock item.
   * @param {React.MouseEvent<HTMLLIElement>} event - The mouse click event (optional).
   */
  const handleOpenEdtSupplierToTheStockItems = (
    stockId: string,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // Check if an event object is provided and stop its propagation to prevent unintended side effects.
    if (event) {
      event.stopPropagation();
    }

    // Fetch information about selected suppliers
    handleGetSelectedSupplier();

    // Set the 'isCreated' state to 'true'
    setIsCreated(true);

    // Close various modals if they are open
    setIsOpenEditStockItemsModal(false);
    setIsOpenMapProductsEditModal(false);
    setIsOpenSupplierAddModal(true);
    setIsOpenSupplierEditModal(true);
    setIsOpenCreateStockItemsModal(true);

    // Set the 'id' state to the provided 'stockId'.
    setId(stockId);
  };

  /**
   * This function is responsible for handling the opening of the supplier addition modal
   * for a specific stock item.
   *
   * @param {string} stockId - The ID of the stock item.
   * @param {React.MouseEvent<HTMLLIElement>} event - The mouse click event (optional).
   */
  const handleOpenAddSupplierToTheStockItems = (
    stockId: string,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // Check if an event object is provided and stop its propagation to prevent unintended side effects.
    if (event) {
      event.stopPropagation();
    }

    // Set the 'mapProductSkeletonLoading' state to 'true' to indicate loading.
    setMapProductSkeletonLoading(true);

    // Reset the 'selectedNodeData' state to an empty object.
    setSelectedNodeData({});

    // Close various modals if they are open
    setIsOpenEditStockItemsModal(false);
    setIsOpenMapProductsEditModal(false);
    setIsOpenSupplierAddModal(true);
    setIsOpenCreateStockItemsModal(true);

    // Set the 'id' state to the provided 'stockId'.
    setId(stockId);
  };

  /**
   * Handles the search functionality for filtering stock item groups, departments, and items.
   *
   * @param {string} value - The search query entered by the user.
   */
  const handleChangeSearch = (value: string) => {
    // Create a deep clone of the initial data to work with
    const cloneStockItemGroupDepartmentNodeInSelector = _.cloneDeep(
      stockItemGroupDepartmentNodeInSelectorInitial,
    );

    // Update the search input value in the state
    setSearchName(value);

    // Check if the search value is not empty
    if (!_.isEmpty(value)) {
      // Filter and update the data based on the search value
      const filteredOutput = cloneStockItemGroupDepartmentNodeInSelector
        .map((group: any) => ({
          ...group,
          stockDepartmentId: group.stockDepartmentId
            .map((dept: any) => ({
              ...dept,
              // Filter items within each department by name
              item: dept.item.filter((item: any) => item.name.includes(value)),
            }))
            // Remove departments with no matching items
            .filter((dept: any) => dept.item.length > 0),
        }))
        // Remove groups with no matching departments
        .filter((group: any) => group.stockDepartmentId.length > 0);

      // Update the state with the filtered data
      setStockItemGroupDepartmentNodeInSelector(filteredOutput);
    } else {
      // If the search value is empty, reset the data to the initial state
      setStockItemGroupDepartmentNodeInSelector(
        stockItemGroupDepartmentNodeInSelectorInitial,
      );
    }
  };

  /**
   * This function registers an element with a specific title in a dictionary.
   *
   * @param {any} title - The title to associate with the element.
   * @param {any} elementRef - The reference to the element being registered.
   */
  const registerElementWithTitle = (title: any, elementRef: any) => {
    // Check if both 'title' and 'elementRef' are not empty.
    if (!_.isEmpty(title) && !_.isEmpty(elementRef)) {
      // Store the element reference in the 'elementsByTitle' dictionary with the provided 'title' as the key.
      elementsByTitle[title] = elementRef;
    }
  };

  return (
    <>
      {/* Main container */}
      {/* InventoryDetails component */}
      {isOpenCreateStockItemsModal && (
        <AddStockItemsModal
          isOpenCreateStockItemsModal={isOpenCreateStockItemsModal}
          setIsOpenCreateStockItemsModal={setIsOpenCreateStockItemsModal}
          setError={setError}
          setIsCreated={setIsCreated}
          setSuccess={setSuccess}
          isCreated={isCreated}
          menuGroups={menuGroups}
          menuDepartments={menuDepartments}
          menuProducts={menuProducts}
          handleGetStockItemDetails={handleGetStockItemDetails}
          isOpenEditStockItemsModal={isOpenEditStockItemsModal}
          selectedNodeData={selectedNodeData}
          isOpenMapProductsEditModal={isOpenMapProductsEditModal}
          id={id}
          stockMappingDetailsNode={stockMappingDetailsNode}
          mapProductSkeletonLoading={mapProductSkeletonLoading}
          setStockMappingDetailsNode={setStockMappingDetailsNode}
          handleOpenEditMapProductsItemsModal={
            handleOpenEditMapProductsItemsModal
          }
          setSelectedNodeData={setSelectedNodeData}
          handleOpenAddSupplierToTheStockItems={
            handleOpenAddSupplierToTheStockItems
          }
          supplierDetailsNode={supplierDetailsNode}
          isOpenSupplierAddModal={isOpenSupplierAddModal}
          setIsOpenSupplierAddModal={setIsOpenSupplierAddModal}
          initialSelectedSupplierList={initialSelectedSupplierList}
          isOpenSupplierEditModal={isOpenSupplierEditModal}
          groupId={groupId}
          getAllStockDepartmentForGroupNotLoad={
            getAllStockDepartmentForGroupNotLoad
          }
          setGroupId={setGroupId}
          getAllStockGroup={getAllStockGroups}
          selectedGroupObj={selectedGroupObj}
          setSelectedGroupObj={setSelectedGroupObj}
          stockGroupSelectedNode={stockGroupSelectedNode}
          setStockGroupSelectedNode={setStockGroupSelectedNode}
          selectedDepartmentIdInMenu={selectedDepartmentId}
          setActiveGroupIds={setActiveGroupId}
          handleGetSupplierInfo={handleGetSupplierInfo}
          handleGetSelectedSupplier={handleGetSelectedSupplier}
          setCreatedId={setCreatedId}
        />
      )}
      <InventoryDetails
        locationSelectorList={locationSelectorList}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        isGetLocationInfo={isGetLocationInfo}
        topic="Stock Items"
        handleOpenCreateModal={handleOpenCreateStockItemsModal}
        buttonName="Add Stock Item"
        handleChangeSearch={handleChangeSearch}
        searchName={searchName}
        setSearchName={setSearchName}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeGroupId={activeGroupId}
        setActiveGroupId={setActiveGroupId}
        selectedDepartmentId={selectedDepartmentId}
        stockItemGroupDepartmentNodeInSelector={
          stockItemGroupDepartmentNodeInSelector
        }
        isOpenDesktopModal={props.additionalProp}
        setSelectedDepartmentId={setSelectedDepartmentId}
        stockItemGroupDepartmentNodeInSelectorInitial={
          stockItemGroupDepartmentNodeInSelectorInitial
        }
        createdId={createdId}
        setCreatedId={setCreatedId}
      >
        {/* Render AddStockItemsModal if isOpenCreateStockItemsModal is true */}

        {/* StockItems table component */}
        <Grid container>
          <Grid item xs={12} style={{ display: "flex", marginTop: "16px" }}>
            <div style={{ display: "block", width: "100%" }}>
              <StockItemsHeader
                handleChangeStockItemsListSorting={
                  handleChangeStockItemsListSorting
                }
                disableButton={disableButton}
                isLoading={isLoading}
                locationSelectorList={locationSelectorList}
                numberOfLocations={numberOfLocations}
                handleOpenEditStockItemsModal={handleOpenEditStockItemsModal}
                handleOpenEditMapProductsItemsModal={
                  handleOpenEditMapProductsItemsModal
                }
                handleOpenEdtSupplierToTheStockItems={
                  handleOpenEdtSupplierToTheStockItems
                }
                stockItemGroupDepartmentNodeInSelector={
                  stockItemGroupDepartmentNodeInSelector
                }
                selectedDepartmentId={selectedDepartmentId}
                searchName={searchName}
                registerElementWithTitle={registerElementWithTitle}
                stockItemGroupDepartmentNodeInSelectorInitial={
                  stockItemGroupDepartmentNodeInSelectorInitial
                }
                activeGroupId={activeGroupId}
              />
            </div>
          </Grid>
        </Grid>
      </InventoryDetails>

      {/* DefaultAlert components for success and error messages */}
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(StockItems, Authorities.ORDERS_READ);

import { axiosClient } from "../client";

const API_LOCATIONS_GET_ALL = `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}?page=1&size=20000`;
const LOCATIONS_INFO_CREATE = `${process.env.REACT_APP_API_PRESTO_EXPRESS_LOCATIONS}`;

export const fetchAllLocations = (token) =>
  axiosClient({
    url: API_LOCATIONS_GET_ALL,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const createLocationsInfo = (data) =>
  axiosClient({
    url: LOCATIONS_INFO_CREATE,
    method: "POST",
    data,
  });

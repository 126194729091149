import { createStyles, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      borderRadius: "10px",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      height: "40px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface DeliveryAreaProps {
  area: any;
  setArea: any;
}

/* Select a delivery area using radius. It can only input number. */
const DeliveryArea: React.FunctionComponent<DeliveryAreaProps> = ({
  area,
  setArea,
}) => {
  /* Change the delivery title. The title should be more than 3 letters. */
  const handleRadiusChange = (e: any) => {
    const { value } = e.target;
    if (value.length > 3) return;
    const areaUpdated = { ...area };
    areaUpdated.data = { distance: value };
    setArea(areaUpdated);
  };

  const classes = useStyles();

  return (
    <TextField
      label="Radius"
      variant="outlined"
      className={classes.textField}
      type="number"
      value={area.data.distance}
      size="small"
      onChange={handleRadiusChange}
      inputProps={{ min: 0 }}
    />
  );
};

export default DeliveryArea;

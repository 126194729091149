import React, { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { Skeleton } from "@material-ui/lab";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import { CustomTheme } from "../../../../../types/customTheme";
import { createStockOrder } from "../../../../../services/inventory/stockOrder";
import {
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../../utils/consts";
import { fetchAllSuppliersInfo } from "../../../../../services/inventory/supplier";
import CardCommon from "../../../../../components/card/CardCommon";
import { CapitalizeFirstLetter } from "../../../../../utils/ReplaceIcon";

const useStyles = makeStyles((theme: CustomTheme) => ({
  rowOdd: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
  },
}));

export interface paymentReportProps {
  isOpenCreateStockOrderModal: any;
  setIsOpenCreateStockOrderModal: any;
  setSuccess: any;
  setError: any;
  handleGetStockOrdersListSorting: any;
  handleOpenAddStockOrderItemModal: any;
}

/**
 * This component, `AddStockOrderModal`, is responsible for rendering a dialog
 * that allows users to create a new stock order by selecting a supplier. It fetches
 * a list of suppliers from the API, displays their information in cards, and lets
 * users select a supplier. Once a supplier is selected, users can proceed to create
 * the stock order. The component utilizes Material-UI components, custom styles,
 * and API calls to achieve its functionality.
 */
const AddStockOrderModal: React.FunctionComponent<paymentReportProps> = ({
  isOpenCreateStockOrderModal,
  setIsOpenCreateStockOrderModal,
  setSuccess,
  setError,
  handleGetStockOrdersListSorting,
  handleOpenAddStockOrderItemModal,
}) => {
  const [supplierList, setSupplierList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [supplierId, setSupplierId] = useState("");

  const match: any = useRouteMatch();

  /**
   * This function handles the creation of a new stock order. It constructs the necessary
   * form data with the selected supplier's ID and other relevant information, then makes
   * an API call to create the stock order. If the creation is successful, it triggers
   * additional actions like opening the modal for adding stock items, removing sorting
   * of stock items list, updating state variables, and displaying success/error messages.
   */
  const handleCreateOrder = async () => {
    try {
      // Construct the form data for creating a stock order
      const formData = {
        locationId: match.params.locationId,
        supplierId: supplierId,
        status: "draft",
        orderedOn: null,
        deliveredOn: null,
      };

      // Make an API call to create the stock order
      const res = await createStockOrder(match.params.locationId, formData);

      // Trigger actions for successful stock order creation
      // Open modal for adding stock items
      handleOpenAddStockOrderItemModal(
        res.data.data.id, // Pass the created stock order ID
        res.data.data.supplierId.id, // Pass the ID of the supplier associated with the stock order
        res.data.data,
        "",
      );

      // Get stock order list
      handleGetStockOrdersListSorting();
      // Close the create stock order modal
      setIsOpenCreateStockOrderModal(false);
      // Set a success message
      setSuccess(SUCCESSFULLY_CREATED);
    } catch (error) {
      // If an error occurs during stock order creation, set an error message

      setError(ERROR_MESSAGE_CREATING_ERROR);
    }
  };

  /**
   * This function fetches information about all suppliers for a given location.
   * It makes an API call to retrieve the list of suppliers and updates the component
   * states accordingly. If the API call is successful, the loading state is set to
   * false and the supplier data is updated. If there is an error, the loading state
   * is still set to false, but an error message is displayed.
   */
  const geAllSuppliers = async () => {
    try {
      // Make an API call to fetch information about all suppliers
      const res = await fetchAllSuppliersInfo(match.params.locationId);

      // Update component states with the received data from the API response
      // Set loading state to false
      setIsLoading(false);
      // Set supplier data
      setSupplierList(res.data.data);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * This function handles the selection of a supplier by updating the 'supplierId'
   * state with the chosen supplier's ID. It takes a 'supplierId' as a parameter
   * and updates the state accordingly.
   *
   * @param {string} supplierId - The ID of the selected supplier.
   */
  const handleSelectSupplier = (supplierId: string) => {
    // Update the 'supplierId' state with the chosen supplier's ID
    setSupplierId(supplierId);
  };

  /**
   * This useEffect hook is responsible for fetching all supplier information
   */
  useEffect(() => {
    // Call the 'geAllSuppliers' function to fetch supplier information
    geAllSuppliers();
  }, []);

  const classes = useStyles();

  /**
   * This function generates a grid with placeholder Skeleton components,
   * which are used to display a loading animation when the supplier list is being fetched.
   *
   * @returns JSX elements containing Skeleton components to show loading animation.
   */
  const handleSkeleton = () => {
    return (
      <Grid container>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
        <Grid item xs={6} sm={4} style={{ width: "100%", padding: "4px" }}>
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={120}
          />
        </Grid>
      </Grid>
    );
  };

  /**
   * This function generates a grid with a single row containing a Typography component.
   * This is used to display a message when no suppliers are available for the given location.
   *
   * @returns JSX elements containing a Typography component with a message.
   */

  const handleEmptySupplier = () => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "40px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography variant="h5">
            No suppliers exists for given location
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpenCreateStockOrderModal}
        setOpen={setIsOpenCreateStockOrderModal}
        isOpenMapProd={true}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          Create a new order
        </DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          {/* Check if loading is complete */}
          {!isLoading ? (
            <Grid container>
              {supplierList
                ? // Map through supplier list and render cards
                  supplierList.map((supplier: any) => (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      style={{ width: "100%", padding: "12px" }}
                    >
                      <CardCommon
                        color={supplier.id === supplierId && "green"}
                        backgroundColor={"entity_highlight_background"}
                      >
                        <Button
                          fullWidth
                          style={{ display: "block", textTransform: "none" }}
                          onClick={() => handleSelectSupplier(supplier.id)}
                        >
                          <Typography
                            align="left"
                            variant="body2"
                            style={
                              supplier.id === supplierId
                                ? {
                                    padding: "2px 12px 2px 12px",
                                    color: "white",
                                  }
                                : { padding: "2px 12px 2px 12px" }
                            }
                          >
                            {CapitalizeFirstLetter(supplier.name)}
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            style={
                              supplier.id === supplierId
                                ? {
                                    padding: "2px 12px 2px 12px",
                                    color: "white",
                                  }
                                : { padding: "2px 12px 2px 12px" }
                            }
                          >
                            {CapitalizeFirstLetter(supplier.address)}
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            style={
                              supplier.id === supplierId
                                ? {
                                    padding: "2px 12px 2px 12px",
                                    color: "white",
                                  }
                                : { padding: "2px 12px 2px 12px" }
                            }
                          >
                            {supplier.contactNo}
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            style={
                              supplier.id === supplierId
                                ? {
                                    padding: "2px 12px 2px 12px",
                                    color: "white",
                                  }
                                : { padding: "2px 12px 2px 12px" }
                            }
                          >
                            {supplier.email}
                          </Typography>
                        </Button>
                      </CardCommon>
                    </Grid>
                  ))
                : // Render message for no suppliers
                  handleEmptySupplier()}
            </Grid>
          ) : (
            // Render skeleton loading UI
            handleSkeleton()
          )}
        </DialogContent>
        <DialogActions>
          <div style={{ display: "block", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "18px",
              }}
            >
              <Button
                onClick={() => setIsOpenCreateStockOrderModal(false)}
                style={{ textTransform: "none" }}
                color="secondary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateOrder}
                style={{ textTransform: "none" }}
                color="secondary"
                autoFocus
                disabled={_.isEmpty(supplierId)}
              >
                Next
              </Button>
            </div>
          </div>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default AddStockOrderModal;

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { ExportPdfColorInventory } from "../../../../../root/theme/exportColor";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";
import { convertDateFormat } from "../../../../../utils/ConvertDateTimeFormat";

/* Create a pdf file */
export const handleDownloadPdf = (
  scapeChange: string,
  columns: any,
  nodes: any,
  locationData: any,
  selectedStockOrderDetails: any,
) => {
  const doc: any = new jsPDF(scapeChange === "p" ? "p" : "l");
  const totalPagesExp = "{total_pages_count_string}";
  const pageWidth = doc.internal.pageSize.getWidth();
  const today = moment().format("MMM_Do_YY").toString();

  /* If there is a image, insert it into the img variable. */
  const img = new Image();
  img.src = eatprestoLogo;

  const columnLength: number = columns.length;

  // Calculate equal column width for 4 columns (in millimeters)
  const totalWidth = pageWidth - 14.11 - 14.11;
  const equalColumnWidth = totalWidth / 4;

  // Define your column widths as an array with 4 equal-width columns
  const columnWidths = [100, 50, 50, 10];

  /* Create a pdf file. */
  doc.autoTable({
    columns: columns.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    startY: 48,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColorInventory.HeaderRowColor],
      textColor: ["#000000"],
      fontSize: 10,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: { fontStyle: "bold", halign: "left", cellWidth: equalColumnWidth },
      1: { cellWidth: equalColumnWidth },
      2: { cellWidth: equalColumnWidth },
      3: { halign: "right", cellWidth: equalColumnWidth },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColorInventory.OddRowColor],
      textColor: ["#000000"],
    },
    tableLineWidth: 0.1,
    // Change footer style.

    // Add topic and sub topics.
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();
      // Change topic font size
      doc.setFontSize(12);
      // doc.text("Presto Express Solutions", data.settings.margin.left, 12);
      // Modify the marginLeft according to the scape.
      // doc.text(
      //   "Order Reports",
      //   pageWidth -
      //     data.settings.margin.right -
      //     doc.getTextWidth("Order Reports"),
      //   12,
      // );
      const addressLines = locationData.addressFormatted.split(",");

      doc.setTextColor(0, 0, 0);

      // Change main title font size
      // Insert a topic for the first page only.
      if (doc.internal.getNumberOfPages() === 1) {
        doc.setFontSize(14).setFont(undefined, 'bold');
        doc.text("Deliver : ", data.settings.margin.left, 12);
        doc.setFontSize(12).setFont(undefined, 'normal');
        // Set the font style to bold
        for (var i = 0; i < addressLines.length; i++) {
          doc.text(
            addressLines[i].trim() + ",",
            data.settings.margin.left,
            18 + i * 6,
          );
        }
        doc.setFontSize(14).setFont(undefined, 'bold');
        const textWidth = doc.getTextWidth("Supplier :");

        doc.text(
          "Supplier : ",
          pageWidth - data.settings.margin.right - textWidth,
          12,
        );
        let count = 18;
        doc.setFontSize(12).setFont(undefined, 'normal');
        if (selectedStockOrderDetails?.supplierId?.name) {
          const textWidth = doc.getTextWidth(
            selectedStockOrderDetails?.supplierId?.name,
          );

          doc.text(
            selectedStockOrderDetails?.supplierId?.name,
            pageWidth - data.settings.margin.right - textWidth,
            count,
          );
          count = count + 6;
        }

        if (selectedStockOrderDetails?.supplierId?.address) {
          const textWidth = doc.getTextWidth(
            selectedStockOrderDetails?.supplierId?.address,
          );

          doc.text(
            selectedStockOrderDetails?.supplierId?.address,
            pageWidth - data.settings.margin.right - textWidth,
            count,
          );
          count = count + 6;
        }

        if (selectedStockOrderDetails?.supplierId?.email) {
          const textWidth = doc.getTextWidth(
            selectedStockOrderDetails?.supplierId?.email,
          );

          doc.text(
            selectedStockOrderDetails?.supplierId?.email,
            pageWidth - data.settings.margin.right - textWidth,
            count,
          );
          count = count + 6;
        }

        if (selectedStockOrderDetails?.supplierId?.contactNo) {
          const textWidth = doc.getTextWidth(
            selectedStockOrderDetails?.supplierId?.contactNo,
          );

          doc.text(
            selectedStockOrderDetails?.supplierId?.contactNo,
            pageWidth - data.settings.margin.right - textWidth,
            count,
          );
        }

        doc.setFontSize(10);
      }

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);
      const textWidth = doc.getTextWidth(
        convertDateFormat(new Date().toString()),
      );
      doc.text(
        convertDateFormat(new Date().toString()),
        pageWidth - data.settings.margin.right - textWidth,
        pageHeight - 10,
      );
      // doc.addImage(
      //   img,
      //   "JPEG",
      //   data.settings.margin.left + 144,
      //   pageHeight - 24,
      //   40,
      //   16,
      // );
    },
    /* Change the backgroundcolor in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.dataKey === "barcode") {
          data.cell.styles.halign = "left";
        } else if (data.column.dataKey === "orderQuantity") {
          data.cell.styles.halign = "right";
        }
      }
      console.log("sdfsfsdfs43dsfsdf", data.column.index);
      if (data.column.index === 3) {
        data.cell.styles.halign = "right";
      }
      if (data.row.index !== 0) {
        if (data.row.index % 2 === 0) {
          data.cell.styles.fillColor = [ExportPdfColorInventory.OddRowColor];
          data.cell.styles.textColor = ["#000000"];
        } else {
          data.cell.styles.fillColor = [ExportPdfColorInventory.EvenRowColor];
          data.cell.styles.textColor = ["#000000"];
        }
      }
      // if (data.row.index + 1 === rows.length) {
      //   data.cell.styles.fontStyle = "bold";
      // }
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(
    `orders_Report_${today}_${Math.floor(100000 + Math.random() * 900000)}.pdf`,
  );
};

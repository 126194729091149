import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useLocation, useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import Pagination from "../../../../components/common/Pagination";
import InventoryStockOrder from "../../../../components/common/InventoryDetails/InventoryStockOrder";
import StockDepartmentHeader from "./StockDepartmentHeader";
import AddStockDepartmentModal from "./StockDepartmentAddModal";
import { fetchAllStockDepartmentsInfoSorting } from "../../../../services/inventory/stockItems";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * This component manages the display of stock department information and related UI components.
 * It fetches data from APIs, handles user interactions, and renders various UI elements.
 * The component includes features such as sorting, pagination, and filtering of stock department.
 * Additionally, it provides the ability to create and edit stock department through modal dialogs.
 * Access control is enforced using the 'withAuthority' higher-order component.
 */
const StockDepartment: React.FunctionComponent = () => {
  const [stockDepartmentNodeList, setStockDepartmentNodeList] = useState<any>(
    [],
  );
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("");
  const [sortingMethod, setSortingMethod] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [isSort, setIsSort] = useState(false);
  const [version, setVersion] = useState("");
  const [success, setSuccess] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [isOpenStockDepartmentModal, setIsOpenStockDepartmentModal] =
    useState(false);
  const [isOpenStockDepartmentEditModal, setIsOpenStockDepartmentEditModal] =
    useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [departmentNameInitial, setDepartmentNameInitial] = useState("");

  const match: any = useRouteMatch();

  /**
   * Function to fetch stock departments information using API call and update component states.
   * @param {number} pageSize - Number of department to display per page.
   * @param {number} currentPage - Current page number.
   * @param {string} sortingMethod - Sorting method for the department.
   * @param {string} headerName - Name of the header to sort by.
   */
  const getStockDepartmentInfo = async (
    pageSize: number,
    currentPage: number,
    sortingMethod: string,
    headerName: string,
  ) => {
    // Set the pageSize and currentPage states for pagination
    setPageSize(pageSize);
    setCurrentPage(currentPage);

    try {
      // Attempt to fetch stock department information using the 'fetchAllStockItemsInfo' API
      const res = await fetchAllStockDepartmentsInfoSorting(
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
      );
      // Update component states with the received data from the API response
      // Set loading state to false
      setIsLoading(false);
      // Set stock department data
      setStockDepartmentNodeList(res.data.data);
      // Set total number of pages
      setTotalPages(res.data.totalPages);
      // Disable skeleton loading
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Department"
    document.title = "Eat Presto - Stock Department";

    // Fetch location information with an empty searchName
    getAllFilterLocation("");
  }, []);

  /**
   * Function to fetch location information and initialize stock department loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async (searchName: string) => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations(searchName);

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Trigger the function to fetch stock departments information with appropriate parameters
      getStockDepartmentInfo(pageSize, 1, "", "");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle pagination change using selector.
   * @param {any} pageSize - Number of department to display per page.
   */
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Check if a headerName is provided for sorting
    if (headerName) {
      // If headerName is provided, fetch stock department information with sorting and filtering
      getStockDepartmentInfo(pageSize, 1, sortingMethod, headerName);
    } else {
      // If headerName is not provided, fetch stock department information without sorting
      getStockDepartmentInfo(pageSize, 1, sortingMethod, "");
    }
  };

  /**
   * Function to handle pagination change using page number.
   * @param {any} currentPage - The selected page number.
   */
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Fetch stock department information with the updated pagination parameters
    getStockDepartmentInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to handle sorting of stock department list.
   * @param {any} headerName - The selected header name for sorting.
   * @param {any} sortingMethod - The selected sorting method.
   */
  const handleChangeStockDepartmentListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {

    // Set the 'disableButton' state to true to disable the sorting button temporarily
    setDisableButton(true);

    setIsSort(true);

    // Set the 'headerName' state with the selected header name
    setHeaderName(headerName);

    // Set the 'sortingMethod' state with the selected sorting method
    setSortingMethod(sortingMethod);

    // Fetch stock department information with the updated sorting parameters
    getStockDepartmentInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to remove sorting from the stock department list.
   */
  const handleRemoveStockDepartmentListSorting = () => {

    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    setIsSort(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock department information without sorting
    getStockDepartmentInfo(pageSize, currentPage, "", "");
  };

  /**
   * Function to get stock department details.
   */
  const handleGetStockDepartmentList = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock department information without sorting
    getStockDepartmentInfo(pageSize, 1, "", "");
  };

  /**
   * Function to handle opening the create stock department modal.
   */
  const handleOpenCreateStockDepartmentModal = () => {
    // Open the 'isOpenCreateStockOrderModal'
    setIsOpenStockDepartmentModal(true);
    setDepartmentName("");
    setDepartmentNameInitial("");
    setVersion("");
    setIsOpenStockDepartmentEditModal(false);
  };

  /**
   * Function to handle opening the edit stock department modal.
   * @param {string} departmentId - ID of the department.
   * @param {string} stockGroupId - ID of the stock group.
   * @param {string} name - Name of the department.
   * @param {string} version - Version of the department.
   * @param {React.MouseEvent<HTMLLIElement>} event - The click event triggered.
   */
  const handleOpenEditStockDepartmentModal = (
    departmentId: string,
    stockGroupId: string,
    name: string,
    version: string,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // If an event is provided, stop its propagation (prevent further bubbling)
    if (event) {
      event.stopPropagation();
    }
    // Set the stock group ID in the state
    setGroupId(stockGroupId);

    // Set the department ID in the state
    setDepartmentId(departmentId);

    // Set the version in the state
    setVersion(version);

    // Open the create stock department modal
    setIsOpenStockDepartmentModal(true);

    // Set the department name in the state
    setDepartmentName(name);

    // Set the department name in the state
    setDepartmentNameInitial(name);

    // Open the edit stock department modal (edit mode)
    setIsOpenStockDepartmentEditModal(true);
  };

  return (
    <>
      {/* Main container */}
      <Box>
        {/* InventoryDetails component */}
        <InventoryStockOrder
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          nodeList={stockDepartmentNodeList}
          topic="Stock Department"
          handleOpenCreateModal={handleOpenCreateStockDepartmentModal}
          buttonName="Add Stock Department"
        >
          {/* StockDepartment table component */}
          <StockDepartmentHeader
            handleChangeStockDepartmentListSorting={
              handleChangeStockDepartmentListSorting
            }
            handleRemoveStockDepartmentListSorting={
              handleRemoveStockDepartmentListSorting
            }
            nodeData={stockDepartmentNodeList}
            disableButton={disableButton}
            isLoading={isLoading}
            locationSelectorList={locationSelectorList}
            handleOpenEditStockDepartmentModal={
              handleOpenEditStockDepartmentModal
            }
            isSort={isSort}
            headerName={headerName}
            sortingMethod={sortingMethod}
          />
          {/* Render Pagination if stockDepartmentNodeList is not empty */}
          <Pagination
            handleChangePaginationUsingSelector={
              handleChangePaginationUsingSelector
            }
            handleChangePaginationUsingPageNumber={
              handleChangePaginationUsingPageNumber
            }
            totalPages={totalPages}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        </InventoryStockOrder>

        <AddStockDepartmentModal
          isOpenStockDepartmentModal={isOpenStockDepartmentModal}
          setIsOpenStockDepartmentModal={setIsOpenStockDepartmentModal}
          handleGetStockDepartmentList={handleGetStockDepartmentList}
          setSuccess={setSuccess}
          setError={setError}
          isOpenStockDepartmentEditModal={isOpenStockDepartmentEditModal}
          setDepartmentName={setDepartmentName}
          departmentName={departmentName}
          version={version}
          departmentId={departmentId}
          groupId={groupId}
          departmentNameInitial={departmentNameInitial}
        />

        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(StockDepartment, Authorities.ORDERS_READ);
